// src/components/JobDetails.js
import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { auth } from '../../firebase'
import { db } from '../../firebase'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { getJobPosting, updateJobPosting } from '../../models/JobPosting'
import { getJobApplicationsForPosting } from '../../models/JobApplication'
import { getUserById } from '../../models/User'
import { getJobseekerProfile } from '../../models/JobSeekerProfile'
import { updateJobApplication } from '../../models/JobApplication'
import { getApplicationsWithUserAndJobData } from '../../models/JobApplication'
import { Link } from 'react-router-dom'
import { Eye } from 'lucide-react'

import DOMPurify from 'dompurify'

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc
} from 'firebase/firestore'

import EmployerNav from './EmployerNav'
import {
  Edit,
  Save,
  X,
  ArrowLeft,
  Trash2,
  Plus,
  CheckCircle,
  AlertCircle,
  Building,
  MapPin,
  Globe,
  Briefcase,
  GraduationCap,
  BanknoteIcon,
  AlignLeft,
  ClipboardCheck,
  Star,
  Code,
  Gift,
  Hash,
  Heart,
  Calendar,
  Laptop,
  Smile,
  Lock,
  Monitor,
  Users,
  Train,
  Coffee,
  Shield,
  SquareArrowUpRight,
  Share
} from 'lucide-react'
import { Bar, Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
ChartJS.register(ArcElement, Tooltip, Legend)

const benefitCategories = [
  { value: 'Healthcare', label: 'Healthcare', icon: Heart },
  { value: 'Time Off', label: 'Time Off', icon: Calendar },
  {
    value: 'Learning & Development',
    label: 'Learning & Development',
    icon: GraduationCap
  },
  { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
  { value: 'Wellness', label: 'Wellness', icon: Smile },
  { value: 'Equipment', label: 'Equipment', icon: Monitor },
  { value: 'Family', label: 'Family', icon: Users },
  { value: 'Commuter', label: 'Commuter', icon: Train },
  { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
  { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
  { value: 'Insurance', label: 'Insurance', icon: Shield }
]
const USERS_COLLECTION = 'users'

// Styled Components
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f4f8;
  color: #2d3748;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`

const TopBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e2e8f0;
  background-color: white;
  margin-bottom: 2rem;
  border-radius: 12px;
  @media (max-width: 768px) {
    padding: 0.5rem;
    flex-direction: column;
  }
`

const TopBarActions = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  background: none;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    background: #f1f5f9;
    color: #334155;
  }
`

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  @media (max-width: 768px) {
    padding: 0.25rem;
    gap: 0.1rem;
    font-size: 0.75rem;
  }

  ${(props) => {
    if (props.variant === 'delete') {
      return `
        background-color: #fee2e2;
        color: #dc2626;
        border: 1px solid #fecaca;

        &:hover {
          background-color: #fecaca;
        }
      `
    } else if (props.variant === 'hired') {
      return `
        background-color: #dcfce7;
        color: #16a34a;
        border: 1px solid #bbf7d0;

        &:hover {
          background-color: #bbf7d0;
        }
      `
    }
    return `
      background-color: #caaae1;
      color: white;
      border: none;

      &:hover {
        background-color: #b38fd1;
      }
    `
  }}
`

const Section = styled.section`
  background: white;
  border-radius: 16px;
  padding: 2.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    padding: 1rem;
  }
`

const JobTitle = styled.h1`
  font-size: 2.25rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
`

const StatusBadge = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 1rem;

  ${(props) =>
    props.status === 'hired'
      ? `
    background-color: #dcfce7;
    color: #16a34a;
  `
      : `
    background-color: #f8f4fb;
    color: #805ad5;
  `}
`

const QuickInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-top: 1rem;
`

const QuickInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #64748b;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  background-color: #f8fafc;
  border-radius: 8px;

  svg {
    color: #caaae1;
  }
`

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 2rem 0;
  padding: 2rem;
  background-color: #f8fafc;
  border-radius: 12px;
`

const ContentSection = styled.div`
  margin-bottom: 3rem;

  &:last-child {
    margin-bottom: 0;
  }
`

const SectionTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  svg {
    color: #caaae1;
  }
`

const TextContent = styled.div`
  white-space: pre-wrap;
  color: #475569;
  line-height: 1.8;
  font-size: 1rem;
`

const EditableInput = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: ${(props) => (props.large ? '1.5rem' : '1rem')};
  font-weight: ${(props) => (props.large ? '600' : '400')};
  margin-bottom: 1rem;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`
const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
`

const MainContentArea = styled.div`
  flex: 1;
`

const EditableTextarea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  min-height: 120px;
  font-family: inherit;
  resize: vertical;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225`
const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
`

const SkillTag = styled.span`
  background-color: #f8f4fb;
  color: #805ad5;
  padding: 0.75rem 1.25rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  svg {
    color: #caaae1;
  }
`

const SalaryRange = styled.div`
  background-color: #f8f4fb;
  color: #805ad5;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  font-size: 1.25rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
  margin-top: 1.5rem; /* Adjust margin-top for spacing */

  svg {
    color: #caaae1;
  }
`

const RichTextContent = styled.div`
  color: #4a5568;
  line-height: 1.8;
  font-size: 1rem;

  h1,
  h2,
  h3,
  h4 {
    margin: 1.5rem 0 1rem;
    color: #1a202c;
    font-weight: 600;
  }

  ul,
  ol {
    padding-left: 1.5rem;
    margin-bottom: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
  }

  a {
    color: #805ad5;
    text-decoration: underline;
    &:hover {
      color: #6b46c1;
    }
  }

  strong {
    font-weight: 600;
  }

  em {
    font-style: italic;
  }
`

const EditableSelect = styled.select`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
`

const BenefitCard = styled.div`
  position: relative;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

const BenefitIcon = styled.div`
  width: 48px;
  height: 48px;
  background-color: #f8f4fb;
  color: #caaae1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`

const ModalText = styled.div`
  color: #475569;
  margin-bottom: 2rem;
  font-size: 0.95rem;
  line-height: 1.6;
`

const WarningList = styled.ul`
  margin: 1rem 0;
  padding-left: 1.5rem;

  li {
    margin-bottom: 0.5rem;
    color: #64748b;

    &::marker {
      color: ${(props) => (props.variant === 'delete' ? '#dc2626' : '#16a34a')};
    }
  }
`

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`

const ShareButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #edf1f6;
  border: 1px solid #64748b;
  border-radius: 8px;
  color: #64748b;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: #e2e8f0;
  }
`

// const ApplicantAvatar = styled.div`
//   width: 48px;
//   height: 48px;
//   background-color: #f8f4fb;
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   color: #805ad5;
//   font-weight: 600;
//   font-size: 1.125rem;
// `

// const ApplicantNameSection = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
// `

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const JobInfo = styled.div`
  font-size: 0.875rem;
  color: #64748b;
  margin-top: 0.25rem;
`

// Define TabContainer and Tab components for the tabs
const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 1rem;
`

const Tab = styled.button`
  padding: 1rem 1.5rem;
  cursor: pointer;
  background: ${({ active }) => (active ? '#caaae1' : 'transparent')};
  color: ${({ active }) => (active ? 'white' : '#2d3748')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  border: none;
  border-bottom: ${({ active }) => (active ? '2px solid #805ad5' : 'none')};
  transition: background-color 0.2s;

  &:hover {
    background-color: #e9d7f8;
  }

  @media (max-width: 768px) {
    padding: 0.5rem 0.75rem;
  }
`

// const Tooltip = styled.div`
//   display: none;
//   position: absolute;
//   top: -1.5rem;
//   right: 0;
//   background-color: #2d3748;
//   color: white;
//   padding: 0.5rem;
//   border-radius: 4px;
//   font-size: 0.75rem;
//   white-space: nowrap;
//   z-index: 10;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `

const LockIconStyled = styled(Lock)`
  color: #16a34a;
`

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Slightly transparent black for overlay */
  z-index: 4999;
`

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff; /* Solid white background */
  padding: 2.5rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 5000;
  width: 90%;
  max-width: 500px;
  max-height: 80vh;
`

const ModalTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b; /* Darker text color for contrast */
  margin-bottom: 0.5rem;
`

const ModalButton = styled.button`
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:first-of-type {
    background-color: #f1f5f9; /* Light grey for cancel button */
    color: #64748b;
    border: none;
    &:hover {
      background-color: #e2e8f0;
    }
  }

  &:last-of-type {
    background-color: #dc2626; /* Strong red for confirm delete */
    color: white;
    border: none;
    &:hover {
      background-color: #b91c1c;
    }
  }
`
const ApplicantsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const ApplicantCard = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    border-color: #caaae1;
  }
`

const ApplicantInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const ApplicantAvatar = styled.div`
  width: 48px;
  height: 48px;
  background-color: #f8f4fb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #805ad5;
  font-weight: 600;
  font-size: 1.125rem;
`

const ApplicantDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`

const ApplicantNameSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ApplicantName = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #1e293b;
`

const ResumeLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: #805ad5;
  font-size: 0.875rem;
  text-decoration: none;
  margin-top: 0.125rem;
  width: fit-content;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const ApplicantActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const ApplicantStatus = styled.span`
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
  ${(props) => {
    switch (props.status) {
      case 'submitted':
        return `
          background-color: #EBF8FF; 
          color: #3182CE;
        `
      case 'reviewed':
        return `
          background-color: #EAE2F8;
          color: #805AD5;
        `
      case 'shortlisted':
        return `
          background-color: #FFEDD5;
          color: #DD6B20;
        `
      case 'interview':
        return `
          background-color: #FEFCBF;
          color: #D69E2E;
        `
      case 'offer':
        return `
          background-color: #F0FFF4;
          color: #38A169;
        `
      case 'rejected':
        return `
          background-color: #fee2e2;
          color: #dc2626;
        `
      default:
        return `
          background-color: #f1f5f9;
          color: #64748b;
        `
    }
  }}
`

const MatchBubble = styled.div`
  background-color: #dcfce7;
  color: #16a34a;
  border-radius: 9999px;
  padding: 0.5rem 1rem;
  font-size: 0.75rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`

const LoadingMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: #64748b;
`

const EmptyMessage = styled.div`
  text-align: center;
  padding: 2rem;
  color: #64748b;
`

const Pronouns = styled.span`
  color: #64748b;
  font-size: 0.75rem; /* Smaller font size */
  padding: 0.125rem 0.5rem;
  background-color: #f8fafc;
  border-radius: 4px;
  margin-left: 0.5rem;
`

const ApplicantMetadata = styled.div`
  display: flex;
  gap: 0.5rem;
  color: #64748b;
  font-size: 0.875rem;
  margin-top: 0.25rem;
`

const CoverLetterModal = styled(Modal)`
  max-width: 600px;
  display: flex;
  flex-direction: column;
`

const CoverLetterContent = styled.div`
  white-space: pre-wrap;
  line-height: 1.6;
  color: #475569;
  margin: 1rem 0;
  flex-grow: 1;
  overflow-y: scroll;
  padding: 1rem;
  border-top: 1px solid #ccc;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #718096;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.2s;

  &:hover {
    background-color: #f1f5f9;
    color: #1e293b;
  }
`
const SortingControls = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`

const SortSelect = styled.select`
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #caaae1;
  }
`
const ModalContent = styled.div`
  margin-top: 1rem;
  color: #4a5568;
`

const TinyUrl = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin: 1rem 0;
  font-size: 0.875rem;
  color: #1a202c;
  background: #f8fafc;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const JobDetails = () => {
  const { jobId } = useParams()
  const navigate = useNavigate()
  const [jobData, setJobData] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [editedData, setEditedData] = useState(null)
  const [activeTab, setActiveTab] = useState('Applicants')
  const [showCoverLetterModal, setShowCoverLetterModal] = useState(false)
  const [selectedCoverLetter, setSelectedCoverLetter] = useState('')
  const [loading, setLoading] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showHiredModal, setShowHiredModal] = useState(false)
  const [showShareModal, setShowShareModal] = useState(false)

  const [applications, setApplications] = useState([])
  const [error, setError] = useState(null)
  
  // 'asc' or 'desc'
  const [sortOrder, setSortOrder] = useState(() => {
    try {
      return sessionStorage.getItem('sortOrder') || 'desc'
    } catch (error) {
      console.error('Error loading sortOrder from sessionStorage:', error)
      return 'desc'
    }
  })

  useEffect(() => {
    try {
      sessionStorage.setItem('sortOrder', sortOrder)
    } catch (error) {
      console.error('Error saving sortOrder to sessionStorage:', error)
    }
  }, [sortOrder])
  

  const [statusFilter, setStatusFilter] = useState(() => {
    try {
      return sessionStorage.getItem('statusFilter') || ''
    } catch (error) {
      console.error('Error loading statusFilter from sessionStorage:', error)
      return ''
    }
  })

  useEffect(() => {
    try {
      sessionStorage.setItem('statusFilter', statusFilter)
    } catch (error) {
      console.error('Error saving statusFilter to sessionStorage:', error)
    }
  }, [statusFilter])

  //Options: 'match', 'status', 'date'
  const [sortBy, setSortBy] = useState(() => {
    try {
      return sessionStorage.getItem('sortBy') || 'match'
    } catch (error) {
      console.error('Error loading sortBy from sessionStorage:', error)
      return 'match'
    }
  })

  useEffect(() => {
    try {
      sessionStorage.setItem('sortBy', sortBy)
    } catch (error) {
      console.error('Error saving sortBy to sessionStorage:', error)
    }
  }, [sortBy])



  useEffect(() => {
    const fetchJobDetails = async () => {
      setLoading(true)
      try {
        if (!jobId) {
          throw new Error('No job ID provided')
        }

        const jobRef = doc(db, 'jobPostings', jobId)
        const jobSnap = await getDoc(jobRef)

        if (!jobSnap.exists()) {
          throw new Error('Job posting not found')
        }

        const data = {
          id: jobSnap.id,
          ...jobSnap.data(),
          status: jobSnap.data()?.status || 'active'
        }

        setJobData(data)

        // Fetch applications after setting job data
        await fetchApplications(jobId)

        setError(null)
      } catch (error) {
        console.error('Error fetching job details:', error)
        setError(error.message)
      } finally {
        setLoading(false)
      }
    }

    fetchJobDetails()
  }, [jobId, navigate])

  const handleShareJob = () => {
    setShowShareModal(true)
  }

  const fetchApplications = async (jobId) => {
    try {
      const applicationsQuery = query(
        collection(db, 'jobApplications'),
        where('job_posting_id', '==', jobId)
      )
      const applicationsSnapshot = await getDocs(applicationsQuery)

      const applicationsWithUser = await Promise.all(
        applicationsSnapshot.docs.map(async (applicationDoc) => {
          const applicationData = applicationDoc.data()
          const userId = applicationData.jobseeker_id

          if (!userId) {
            console.warn(
              `Missing jobseeker_id in application: ${applicationDoc.id}`
            )
            return { id: applicationDoc.id, ...applicationData, user: null }
          }

          // Fetch user data
          const userSnap = await getDoc(doc(db, USERS_COLLECTION, userId))
          const user = userSnap.exists() ? userSnap.data() : null

          if (!user) {
            console.warn(`No user found for jobseeker_id: ${userId}`)
            return { id: applicationDoc.id, ...applicationData, user: null }
          }

          // Fetch jobseeker profile data for additional info like pronouns
          const profileData = await getJobseekerProfile(userId)

          return {
            id: applicationDoc.id,
            ...applicationData,
            user: { ...user, ...profileData }
          }
        })
      )

      setApplications(applicationsWithUser)
    } catch (error) {
      console.error('Error fetching applications:', error)
    }
  }

  const sortApplications = (applications) => {
    return [...applications].sort((a, b) => {
      // handle rejected status push 'rejected' to the bottom by assigning a higher value
      const statusA = a.status === 'rejected' ? 10 : 0;
      const statusB = b.status === 'rejected' ? 10 : 0;

      // prioritize by status- rejected goes to the bottom
      if (statusA !== statusB) {
          return statusA - statusB;
      }
      switch (sortBy) {
        case 'match':
          const scoreA = parseInt(a.match_score) || 0
          const scoreB = parseInt(b.match_score) || 0
          return sortOrder === 'desc' ? scoreB - scoreA : scoreA - scoreB

        case 'status':
          const statusOrder = {
            offer: 0,
            interview: 1,
            shortlisted: 2,
            reviewed: 3, 
            submitted: 4,
            withdrawn: 5,
            rejected: 6,
            
          }
          const statusCompare = statusOrder[a.status] - statusOrder[b.status]
          return sortOrder === 'desc' ? statusCompare : -statusCompare

        case 'date':
          const dateA = a.created_at?.seconds || 0
          const dateB = b.created_at?.seconds || 0
          return sortOrder === 'desc' ? dateB - dateA : dateA - dateB

        default:
          return 0
      }
    })
  }

  const handleDelete = async () => {
    try {
      await updateJobPosting(jobId, { status: 'deleted' })
      setShowDeleteModal(false) // Close modal
      navigate('/job-postings') // Redirect after deletion
    } catch (error) {
      console.error('Error deleting job:', error)
      alert('Error deleting job posting')
    }
  }

  const handleMarkAsHired = async () => {
    try {
      await updateJobPosting(jobId, { status: 'hired' })
      setJobData((prev) => ({ ...prev, status: 'hired' })) // Update status
      setShowHiredModal(false) // Close modal
    } catch (error) {
      console.error('Error marking job as hired:', error)
      alert('Error updating job status')
    }
  }

  const handleStartEdit = () => {
    setEditedData({ ...jobData })
    setIsEditing(true)
  }

  const formatSalary = (salary) => {
    if (!salary) return 'Not specified'
    if (salary.type === 'unpaid') return 'Unpaid'
    if (salary.type === 'credit') return 'School Credit'

    const formatNumber = (num) =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(num)

    const range = `${formatNumber(salary.range_start)} - ${formatNumber(
      salary.range_end
    )}`
    return salary.type === 'hourly' ? `${range}/hr` : range + '/year'
  }

  const handleCancelEdit = () => {
    setEditedData(null)
    setIsEditing(false)
  }

  const handleSaveChanges = async () => {
    try {
      await updateJobPosting(jobId, editedData)
      setJobData(editedData)
      setIsEditing(false)
      setEditedData(null)
    } catch (error) {
      console.error('Error updating job:', error)
      alert('Error saving changes')
    }
  }

  if (loading) return <div>Loading...</div>

  const renderJobDetails = () => (
    <Section>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      ></div>
      {isEditing ? (
        <EditableInput
          large
          value={editedData.title}
          onChange={(e) =>
            setEditedData((prev) => ({
              ...prev,
              title: e.target.value
            }))
          }
          placeholder="Job Title"
        />
      ) : (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <JobTitle>{jobData.title}</JobTitle>
          {jobData.status === 'hired' && (
            <StatusBadge status="hired">
              <CheckCircle size={16} />
              Position Filled
            </StatusBadge>
          )}
        </div>
      )}

      <QuickInfo>
        <QuickInfoItem>
          <Building size={18} />
          {isEditing ? (
            <EditableInput
              value={editedData.department}
              onChange={(e) =>
                setEditedData((prev) => ({
                  ...prev,
                  department: e.target.value
                }))
              }
              placeholder="Department"
            />
          ) : (
            jobData.department
          )}
        </QuickInfoItem>
        <QuickInfoItem>
          <MapPin size={18} />
          {isEditing ? (
            <EditableInput
              value={editedData.location}
              onChange={(e) =>
                setEditedData((prev) => ({
                  ...prev,
                  location: e.target.value
                }))
              }
              placeholder="Location"
            />
          ) : (
            jobData.location
          )}
        </QuickInfoItem>
        <QuickInfoItem>
          <Globe size={18} />
          {isEditing ? (
            <EditableSelect
              value={editedData.work_policy}
              onChange={(e) =>
                setEditedData((prev) => ({
                  ...prev,
                  work_policy: e.target.value
                }))
              }
            >
              <option value="">Select Work Policy</option>
              <option value="Remote">Remote</option>
              <option value="In-Office">In-Office</option>
              <option value="Hybrid">Hybrid</option>
            </EditableSelect>
          ) : (
            jobData.work_policy
          )}
        </QuickInfoItem>

        <QuickInfoItem>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
            >
              <BanknoteIcon size={20} />
              {!isEditing && <div>{formatSalary(jobData.salary)}</div>}
            </div>
            {jobData.additional_compensation &&
              jobData.additional_compensation.length > 0 && (
                <div
                  style={{
                    backgroundColor: '#f8f4fb',
                    color: '#805ad5',
                    padding: '0.25rem 0.75rem',
                    borderRadius: '999px',
                    fontSize: '0.875rem'
                  }}
                >
                  + {jobData.additional_compensation.join(', ')}
                </div>
              )}
          </div>
        </QuickInfoItem>
      </QuickInfo>

      <ContentSection>
        <SectionTitle>
          <AlignLeft size={24} />
          Job Summary
        </SectionTitle>
        {isEditing ? (
          <EditableTextarea
            value={editedData.description}
            onChange={(e) =>
              setEditedData((prev) => ({
                ...prev,
                description: e.target.value
              }))
            }
            placeholder="Job Description"
          />
        ) : (
          <RichTextContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(jobData.description)
            }}
          />
        )}
      </ContentSection>

      <ContentSection>
        <SectionTitle>
          <ClipboardCheck size={24} />
          Responsibilities
        </SectionTitle>
        {isEditing ? (
          <EditableTextarea
            value={editedData.responsibilities}
            onChange={(e) =>
              setEditedData((prev) => ({
                ...prev,
                responsibilities: e.target.value
              }))
            }
            placeholder="Responsibilities"
          />
        ) : (
          <RichTextContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(jobData.responsibilities)
            }}
          />
        )}
      </ContentSection>

      <ContentSection>
        <SectionTitle>
          <ClipboardCheck size={24} />
          Requirements
        </SectionTitle>
        {isEditing ? (
          <EditableTextarea
            value={editedData.requirements}
            onChange={(e) =>
              setEditedData((prev) => ({
                ...prev,
                requirements: e.target.value
              }))
            }
            placeholder="Requirements"
          />
        ) : (
          <RichTextContent
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(jobData.requirements)
            }}
          />
        )}
      </ContentSection>

      <ContentSection>
        <SectionTitle>
          <ClipboardCheck size={24} />
          Preferred Qualifications
        </SectionTitle>
        {isEditing ? (
          <EditableTextarea
            value={editedData.nice_to_haves}
            onChange={(e) =>
              setEditedData((prev) => ({
                ...prev,
                nice_to_haves: e.target.value
              }))
            }
            placeholder="Preferred Qualifications"
          />
        ) : (
          jobData.nice_to_haves && (
            <RichTextContent
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(jobData.nice_to_haves)
              }}
            />
          )
        )}
      </ContentSection>

      <ContentSection>
        <SectionTitle>
          <ClipboardCheck size={24} />
          Required Skills
        </SectionTitle>
        <SkillsContainer>
          {(isEditing ? editedData : jobData).recommended_skills.map(
            (skill, index) => (
              <SkillTag key={index}>
                <Hash size={16} />
                {skill}
              </SkillTag>
            )
          )}
        </SkillsContainer>
      </ContentSection>

      <ContentSection>
        <SectionTitle>
          <ClipboardCheck size={24} />
          Technical Skills
        </SectionTitle>
        {isEditing ? (
          <SkillsContainer>
            {editedData.technical_skills.map((skill, index) => (
              <SkillTag key={index}>
                <EditableInput
                  value={skill}
                  onChange={(e) =>
                    setEditedData((prev) => {
                      const newSkills = [...prev.technical_skills]
                      newSkills[index] = e.target.value
                      return { ...prev, technical_skills: newSkills }
                    })
                  }
                />
                <X
                  size={16}
                  onClick={() =>
                    setEditedData((prev) => ({
                      ...prev,
                      technical_skills: prev.technical_skills.filter(
                        (_, i) => i !== index
                      )
                    }))
                  }
                />
              </SkillTag>
            ))}
            <ActionButton
              onClick={() =>
                setEditedData((prev) => ({
                  ...prev,
                  technical_skills: [...prev.technical_skills, '']
                }))
              }
            >
              Add Skill
            </ActionButton>
          </SkillsContainer>
        ) : (
          <SkillsContainer>
            {jobData.technical_skills.map((skill, index) => (
              <SkillTag key={index}>
                <Hash size={16} />
                {skill}
              </SkillTag>
            ))}
          </SkillsContainer>
        )}
      </ContentSection>

      <ContentSection>
        <SectionTitle>
          <Star size={24} />
          Strengths
        </SectionTitle>
        <SkillsContainer>
          {(isEditing ? editedData : jobData).strengths.map(
            (strength, index) => (
              <SkillTag key={index}>
                <Smile size={16} />
                {strength}
              </SkillTag>
            )
          )}
        </SkillsContainer>
      </ContentSection>

      {jobData.benefits && jobData.benefits.length > 0 && (
        <ContentSection>
          <SectionTitle>
            <Gift size={24} />
            Benefits
          </SectionTitle>
          <BenefitsGrid>
            {jobData.benefits.map((benefit, index) => {
              const categoryInfo = benefitCategories.find(
                (cat) => cat.value === benefit.category
              )
              const Icon = categoryInfo?.icon || Plus
              return (
                <BenefitCard key={index}>
                  <BenefitIcon>
                    <Icon size={24} />
                  </BenefitIcon>
                  <h3 style={{ marginBottom: '0.5rem' }}>
                    {categoryInfo?.label || 'Other'}
                  </h3>
                  <p style={{ fontSize: '14px', color: '#666' }}>
                    {benefit.description}
                  </p>
                </BenefitCard>
              )
            })}
          </BenefitsGrid>
        </ContentSection>
      )}

      {jobData.additional_compensation &&
        jobData.additional_compensation.length > 0 && (
          <ContentSection>
            <SectionTitle>
              <BanknoteIcon size={24} />
              Additional Compensation
            </SectionTitle>
            <SkillsContainer>
              {jobData.additional_compensation.map((compensation, index) => (
                <SkillTag key={index}>
                  <BanknoteIcon size={16} />
                  {compensation}
                </SkillTag>
              ))}
            </SkillsContainer>
          </ContentSection>
        )}
    </Section>
  )

  const renderDemographics = () => (
    <Section>
      <SectionTitle>Applicant Demographics</SectionTitle>
      <div style={{ color: '#64748b', textAlign: 'center', padding: '2rem' }}>
        {/* Demographics chart code here */}
        Only visible when there are more than 5 applicants.
      </div>
    </Section>
  )
  const handleProfileView = async (jobseekerId, applicationData) => {
    try {
      //only update to review if current status is submitted
      if (applicationData.status === 'submitted') { 
        await updateJobApplication(applicationData.id, { status: 'reviewed' });
  
        setApplications((prevApplications) =>
          prevApplications.map((app) =>
            app.id === applicationData.id ? { ...app, status: 'reviewed' } : app
          )
        )
      }
      navigate(`/jobseeker-profile-view/${jobseekerId}`, {
        state: { applicationData, referrer: `/job-details/${jobId}`, activeTab }
      })
    } catch (error) {
      console.error('Error updating application status:', error)
      alert('Failed to update application status. Please try again.')
    }
  }

  const handleResumeView = async (jobseekerId, applicationData) => {
    try {
      //only update to review if current status is submitted
      if (applicationData.status === 'submitted') {
        await updateJobApplication(applicationData.id, { status: 'reviewed' });
  
        setApplications((prevApplications) =>
          prevApplications.map((app) =>
            app.id === applicationData.id ? { ...app, status: 'reviewed' } : app
          )
        )
      }
    } catch (error) {
      console.error('Error updating application status:', error)
      alert('Failed to update application status. Please try again.')
    }
  }



  const renderApplicants = () => {
    const filteredApplications = statusFilter
      ? applications.filter((app) => app.status === statusFilter)
      : applications

    return (
      <>
        <SortingControls>
          <SortSelect
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="match">Sort by Match Score</option>
            <option value="status">Sort by Status</option>
            <option value="date">Sort by Date Applied</option>
          </SortSelect>
          <SortSelect
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">All Statuses</option>
            <option value="reviewed">Reviewed</option>
            <option value="shortlisted">Shortlisted</option>
            <option value="interview">Interview</option>
            <option value="offer">Offer</option>
            <option value="rejected">Rejected</option>
            <option value="submitted">Not Viewed Yet</option>
          </SortSelect>
          <SortSelect
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="desc">Descending</option>
            <option value="asc">Ascending</option>
          </SortSelect>
        </SortingControls>

        <ApplicantsList>
          {sortApplications(filteredApplications).map((application) => {
            const currentJob = application.user?.work_history?.find(
              (job) => job.present === true
            )
            const currentTitle = currentJob?.title || 'N/A'
            const currentCompany = currentJob?.employer || 'N/A'

            const matchScore = application.match_score
            let matchScoreColor = ''
            let matchScoreText = `${matchScore || ''}% Match`
            let tooltipText = ''

            if (matchScore) {
              const score = parseInt(matchScore, 10)
              if (score < 40) matchScoreColor = '#dc2626' // Red
              else if (score < 60) matchScoreColor = '#d97706' // Orange
              else if (score < 80) matchScoreColor = '#facc15' // Yellow
              else matchScoreColor = '#16a34a' // Green
            } else {
              matchScoreColor = '#6b7280' // Grey for missing score
              matchScoreText = `% Match`
              tooltipText = 'Match score failed to load'
            }

            return (
              <ApplicantCard key={application.id}
                onClick={() =>
                handleProfileView(application.jobseeker_id, application)
                }>
                <ApplicantInfo>
                  <div
                    style={{
                      position: 'absolute',
                      top: '8px',
                      right: '8px',
                      backgroundColor: matchScoreColor,
                      color: '#ffffff',
                      padding: '0.25rem 0.5rem',
                      borderRadius: '12px',
                      fontSize: '0.75rem',
                      fontWeight: '600',
                      cursor: 'pointer'
                    }}
                    data-tip={tooltipText}
                  >
                    {matchScoreText}
                  </div>
                  <ReactTooltip place="top" type="dark" effect="solid" />

                  <ApplicantAvatar>
                    {application.user?.profile_picture ? (
                      <img
                        src={application.user.profile_picture}
                        alt="Profile"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'cover'
                        }}
                      />
                    ) : (
                      application.user?.first_name?.[0] || '?'
                    )}
                  </ApplicantAvatar>

                  <ApplicantDetails>
                    <ApplicantNameSection>
                      <ApplicantName>
                        {application.user?.first_name}{' '}
                        {application.user?.last_name}
                      </ApplicantName>
                      {application.user?.pronouns && (
                        <Pronouns>({application.user.pronouns})</Pronouns>
                      )}
                    </ApplicantNameSection>
                    <ApplicantMetadata>
                      Applied{' '}
                      {new Date(
                        application.created_at?.seconds * 1000
                      ).toLocaleDateString()}
                    </ApplicantMetadata>
                    {application.resume_url && (
                      <ResumeLink
                        href={application.resume_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleResumeView(application.jobseeker_id, application)
                        }}
                        
                      >
                        View Resume
                      </ResumeLink>
                    )}
                    <ResumeLink
                      onClick={() =>
                        handleProfileView(application.jobseeker_id, application)
                      }
                    >
                      View Profile
                    </ResumeLink>
                    {application.cover_letter && (
                      <ResumeLink
                        onClick={(e) => {
                          e.stopPropagation()
                          setSelectedCoverLetter({
                            coverLetter: application.cover_letter,
                            userName: `${application.user?.first_name} ${application.user?.last_name}`,
                          })
                          setShowCoverLetterModal(true)
                        }}
                      >
                        View Cover Letter
                      </ResumeLink>
                    )}
                  </ApplicantDetails>
                </ApplicantInfo>

                <ApplicantActions>
                  <ApplicantStatus status={application.status}>
                  {application.status === "submitted"
                    ? "Not Viewed Yet"
                    : application.status.charAt(0).toUpperCase() + application.status.slice(1)}
                  </ApplicantStatus>
                </ApplicantActions>
              </ApplicantCard>
            )
          })}
        </ApplicantsList>

        {showCoverLetterModal && (
          <>
            <ModalOverlay onClick={() => setShowCoverLetterModal(false)} />
            <CoverLetterModal>
              <ModalHeader>
                <ModalTitle>{selectedCoverLetter?.userName}'s Cover Letter</ModalTitle>
                <CloseButton onClick={() => setShowCoverLetterModal(false)}>
                  <X size={18} />
                </CloseButton>
              </ModalHeader>
              <CoverLetterContent>{selectedCoverLetter?.coverLetter}</CoverLetterContent>
            </CoverLetterModal>
          </>
        )}
      </>
    )
  }

  return (
    <Container>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <TopBar>
          <BackButton onClick={() => navigate('/job-postings')}>
            <ArrowLeft size={16} />
            Back to Job Postings
          </BackButton>
          <TopBarActions>
            {!isEditing ? (
              <>
                <ActionButton
                  onClick={() => window.open(`/job/${jobId}`, '_blank')}
                >
                  <Eye size={16} />
                  Preview
                </ActionButton>
                <ActionButton onClick={handleShareJob}>
                  <Share size={16} />
                  Share
                </ActionButton>
                <ActionButton
                  variant="delete"
                  onClick={() => setShowDeleteModal(true)}
                >
                  <Trash2 size={16} />
                  Delete Job
                </ActionButton>
                {jobData.status !== 'hired' && (
                  <ActionButton
                    variant="hired"
                    onClick={() => setShowHiredModal(true)}
                  >
                    <CheckCircle size={16} />
                    Mark as Hired
                  </ActionButton>
                )}
                <ActionButton onClick={handleStartEdit}>
                  <Edit size={16} />
                  Edit Job
                </ActionButton>
              </>
            ) : (
              <>
                <ActionButton onClick={handleCancelEdit}>
                  <X size={16} />
                  Cancel
                </ActionButton>
                <ActionButton onClick={handleSaveChanges}>
                  <Save size={16} />
                  Save Changes
                </ActionButton>
              </>
            )}
          </TopBarActions>
        </TopBar>

        {showShareModal && (
          <>
            <ModalOverlay onClick={() => setShowShareModal(false)} />
            <Modal>
              <ModalHeader>
                <ModalTitle>Share Job Posting</ModalTitle>
                <CloseButton onClick={() => setShowShareModal(false)}>
                  <X size={18} />
                </CloseButton>
              </ModalHeader>
              <ModalContent>
                <p style={{ marginBottom: '1rem' }}>
                  Share this job posting with your network:
                </p>
                <TinyUrl
                  value={`${window.location.origin}/job/${jobId}`}
                  readOnly
                  onClick={(e) => e.target.select()}
                />
                <ModalActions>
                  <ActionButton
                    style={{ backgroundColor: '#e2e8f0', color: '#4a5568' }}
                    onClick={() => setShowShareModal(false)}
                  >
                    Close
                  </ActionButton>
                  <ActionButton
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/job/${jobId}`
                      )
                      alert('Link copied to clipboard!')
                      setShowShareModal(false)
                    }}
                  >
                    Copy Link
                  </ActionButton>
                </ModalActions>
              </ModalContent>
            </Modal>
          </>
        )}

        {showDeleteModal && (
          <>
            <ModalOverlay onClick={() => setShowDeleteModal(false)} />
            <Modal>
              <ModalHeader>
                <ModalTitle>Delete Job Posting</ModalTitle>
                <CloseButton onClick={() => setShowDeleteModal(false)}>
                  <X size={18} />
                </CloseButton>
              </ModalHeader>
              <ModalContent>
                <p>Are you sure you want to delete this job posting?</p>
                <ModalActions>
                  <ActionButton
                    style={{ backgroundColor: '#e2e8f0', color: '#4a5568' }}
                    onClick={() => setShowDeleteModal(false)}
                  >
                    Cancel
                  </ActionButton>
                  <ActionButton onClick={handleDelete}>
                    Confirm Delete
                  </ActionButton>
                </ModalActions>
              </ModalContent>
            </Modal>
          </>
        )}

        {showHiredModal && (
          <>
            <ModalOverlay onClick={() => setShowHiredModal(false)} />
            <Modal>
              <ModalHeader>
                <ModalTitle>Mark as Hired</ModalTitle>
                <CloseButton onClick={() => setShowHiredModal(false)}>
                  <X size={18} />
                </CloseButton>
              </ModalHeader>
              <ModalContent>
                <p>Do you want to mark this job posting as hired?</p>
                <ModalActions>
                  <ActionButton
                    style={{ backgroundColor: '#e2e8f0', color: '#4a5568' }}
                    onClick={() => setShowHiredModal(false)}
                  >
                    Cancel
                  </ActionButton>
                  <ActionButton onClick={handleMarkAsHired}>
                    Confirm
                  </ActionButton>
                </ModalActions>
              </ModalContent>
            </Modal>
          </>
        )}

        <TabContainer>
          <Tab
            active={activeTab === 'Applicants'}
            onClick={() => setActiveTab('Applicants')}
          >
            Applicants
          </Tab>
          <Tab
            active={activeTab === 'Demographics'}
            onClick={() => setActiveTab('Demographics')}
          >
            Demographics
          </Tab>
          <Tab
            active={activeTab === 'Job Details'}
            onClick={() => setActiveTab('Job Details')}
          >
            Job Details
          </Tab>
        </TabContainer>

        <ContentWrapper>
          <MainContentArea>
            {activeTab === 'Job Details' && renderJobDetails()}
            {activeTab === 'Demographics' && renderDemographics()}
            {activeTab === 'Applicants' && renderApplicants()}
          </MainContentArea>
        </ContentWrapper>
      </MainContent>
    </Container>
  )
}

export default JobDetails
