import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { auth, db } from '../../firebase'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { ChevronDown, View } from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'
import React, { useEffect, useState, useMemo } from 'react'
import NotificationPanel from './NotificationPanel'

import { getEmployers } from '../../models/User'
import { Bar } from 'react-chartjs-2'
import ApplicationStages from './ApplicationStages'
import { getJobseekerApplications } from '../../models/JobApplication'
import { ToastContainer, toast } from 'react-toastify'
import ProWelcomeModal from './ProWelcomeModal'
import { onSnapshot, disableNetwork, enableNetwork } from 'firebase/firestore'
import Joyride from 'react-joyride'

import 'react-toastify/dist/ReactToastify.css'

import {
  getEmployerProfile,
  getEmployerMembers,
  getJobSeekers,
  getAllEmployerProfiles // Add this import
} from '../../models/EmployerProfile'
import 'chart.js/auto' // This line is necessary to auto-register the required chart.js components

// Styled components
const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  min-height: 100vh;

  background-color: #f3f4f6;
`
const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1;
`

const Link = styled.a`
  color: white;
  background-color: ${softColors.icontext};
  font-weight: 500;
  text-decoration: none;
  font-size: 12px;
  transition: color 0.2s;
  border: 1px solid ${softColors.icontext};
  width: auto;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: -10px;

  &:hover {
    background-color: white;
    color: ${softColors.icontext};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`
const BannerLink = styled.a`
  color: white;

  &:hover {
    font-weight: 700;
  }
`
const ProgressBarContainer = styled.div`
  width: 100%;
  text-align: center;
  background-color: ${softColors.border};
  border-radius: 9999px;
  height: 0.5rem;
  margin-bottom: 0.5rem;
`

const ProgressBar = styled.div`
  height: 0.5rem;
  border-radius: 9999px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.color || softColors.primary};
`

const MainContent = styled.main`
  flex: 1;

  overflow-y: auto;
`

const ContentContainer = styled.div`
  padding: 1.5rem;
`

const WelcomeHeader = styled.h1`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.875rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
`

const Grid = styled.div`
  display: grid;
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: ${(props) =>
      `repeat(${props.columns}, minmax(0, 1fr))`};
  }
`

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
`

const CardHeader = styled.div`
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #e5e7eb;
`

const CardTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
`

const CardContent = styled.div`
  padding: 1rem 1.5rem;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const TableHeader = styled.th`
  padding: 0.75rem 1.5rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 500;
  color: #6b7280;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background-color: #f9fafb;
  @media (max-width: 768px) {
    padding: 0.25rem;
    font-size: 0.7rem;
    letter-spacing: 0em;
  }
`

const TableCell = styled.td`
  padding: 1rem 1.5rem;
  white-space: nowrap;
  @media (max-width: 768px) {
    padding: 1rem 0.25rem;
    font-size: 0.7rem;
    white-space: wrap;
  }
`

const OnboardingSection = styled(Card)`
  margin-bottom: 1.5rem;
  height: auto;
`

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
`

const SectionIcon = styled.span`
  margin-right: 0.5rem;
`

const SectionProgressBar = styled(ProgressBarContainer)`
  margin: 0.5rem 0;
  position: relative;
`

const SectionProgress = styled(ProgressBar)`
  background-color: ${(props) =>
    props.started ? softColors.success : softColors.danger};
`

const SectionContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  overflow: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  padding: ${(props) => (props.isOpen ? '1rem 0' : '0')};
  transition: max-height 0.1s ease-in-out, opacity 0.3s ease-in-out,
    padding 0.3s ease-in-out;
`

const SectionDescription = styled.p`
  font-size: 0.875rem;
  color: ${softColors.textLight};
  line-height: 1.5;
`

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: -0.5rem;
  color: ${softColors.text};
  max-width: 90%;
`
const Tooltip = styled.span`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;

  &::after {
    content: '';
    position: absolute;
    top: 100%; /* Arrow pointing down */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
`

// Wrapper for the button and tooltip
const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 10px;

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`

// Button styled component
const Button = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: not-allowed;
  opacity: 0.7;
  border: none;
  font-size: 14px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #b347e5;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: #c859ff;
  }
`

const ViewApplicationsButton = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  border: 1px solid #c859ff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;

  &:hover {
    background-color: white;
    color: #c859ff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`
const StatusCard = styled(Card)`
  padding: 1.5rem;
`

const StatusTitle = styled.h3`
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1.5rem;
`

const StageContainer = styled.div`
  margin-bottom: 1rem;
`

const StageLabel = styled.div`
  font-size: 0.875rem;
  color: #4a5568;
  margin-bottom: 0.5rem;
`

const StageProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e2e8f0;
  border-radius: 4px;
  overflow: hidden;
`

const StageProgress = styled.div`
  height: 100%;
  border-radius: 4px;
  width: ${(props) => props.width};
  background-color: ${(props) => props.color};
`

const MatchesInfo = styled.div`
  color: #666;
  font-size: 0.875rem;
  margin-top: 1.5rem;
`

const ReviewButton = styled.button`
  background-color: #c859ff;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  font-size: 0.875rem;
  cursor: pointer;
  margin-top: 0.75rem;

  &:hover {
    background-color: #b347e5;
  }
`
const PulseAnimation = styled.div`
  animation: pulse 2s infinite ease-in-out;

  @keyframes pulse {
    0% {
      background-color: #f1f5f9;
    }
    50% {
      background-color: #f7fafc;
    }
    100% {
      background-color: #f1f5f9;
    }
  }
`
const SkeletonImage = styled(PulseAnimation)`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 9999px;

  background-color: #f1f5f9;
`
const SkeletonText = styled(PulseAnimation)`
  display: inline-block;
  flex: 1;
  width: ${(props) => props.width || '8rem'};
  height: 20px;
  border-radius: 8px;
  background-color: #f1f5f9;

  @media (max-width: 425px) {
    max-width: ${(props) => props.maxWidth || 'none'};
  }
`

const TableSubCell = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`
const InfoBanner = styled.div`
  background-color: rgb(255, 0, 244);
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 1rem;
`
const Emoji = styled.img`
  height: 1rem;
  object-fit: contain;
`
const UpgradeButton = styled.a`
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: #d29856;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  z-index: 1001; // Added to ensure bubble appears above other content
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 12px;
  }
`
export default function JobSeekerDash() {
  const steps = useMemo(
    () => [
      {
        target: 'body',
        content: (
          <div>
            <strong>Welcome to Arena!</strong>
            <br /> Let's take a quick tour to help you navigate the platform.
          </div>
        ),
        placement: 'center'
      },
      {
        target: '#intake-section',
        content: (
          <div>
            Complete this quick profile setup to unlock all of Arena's features.
          </div>
        )
      },
      {
        target: '#jobs-applied-section',
        content: 'Track your applications at a glance.',
        placement: 'left'
      },
      {
        target: '#profile-button',
        content: 'View and update your profile here.',
        placement: 'right'
      },
      {
        target: '#job-search-button',
        content: 'Find jobs in sports, media & entertainment.',
        placement: 'right'
      },
      {
        target: '#company-search-button',
        content: 'Find companies hiring in sports, media & entertainment.',
        placement: 'right'
      },
      {
        target: '#application-tracking-button',
        content:
          'Never miss an update. Track your entire application journey in one convenient place.',
        placement: 'right'
      },
      {
        target: '#jobseeker-messaging-button',
        content: 'Connect directly with recruiters.',
        placement: 'right'
      },
      {
        target: '#ai-transparency-section',
        content:
          'Curious how we connect you with opportunities? Learn more below.',
        placement: 'top'
      }
    ],
    []
  )
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0) // Reset step index
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [loadingEmployers, setLoadingEmployers] = useState(true)
  const [error, setError] = useState(null)
  const location = useLocation()
  const refreshParam = new URLSearchParams(location.search).get('refresh')

  const [applications, setApplications] = useState([])
  const [showProWelcomeModal, setShowProWelcomeModal] = useState(false)
  const [employers, setEmployers] = useState([])
  const [userName, setUserName] = useState('')
  const [openSections, setOpenSections] = useState({
    profile: false,
    postJob: false,
    survey: false
  })
  const navigate = useNavigate()

  const barData = {
    labels: [
      'Submitted',
      'Reviewed',
      'Shortlisted',
      'Interview',
      'Offer',
      'Rejected',
      'Hired'
    ],
    datasets: [
      {
        label: 'Application Status',
        data: [], // Sample data
        backgroundColor: '#C859FF',
        borderColor: '#C859FF',
        borderWidth: 1
      }
    ]
  }

  const barOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true)
        const user = auth.currentUser
        console.log('Current user:', user)

        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid))
          console.log('User doc exists:', userDoc.exists())

          if (userDoc.exists()) {
            const mainUserData = userDoc.data()
            console.log('Full mainUserData:', mainUserData)
            console.log('plan_selected value:', mainUserData.plan_selected)
            setUserName(mainUserData.first_name)

            const shouldRedirect =
              !mainUserData.plan_selected && mainUserData.plan_selected !== true
            console.log('Should redirect?', shouldRedirect)

            if (shouldRedirect) {
              console.log('Redirecting to plan selection...')
              navigate('/jobseeker-select-plan')
              setLoading(false)
              return
            }

            const profileRef = doc(
              db,
              'users',
              user.uid,
              'profiles',
              'jobseeker'
            )
            const profileSnap = await getDoc(profileRef)

            if (profileSnap.exists()) {
              const profileData = profileSnap.data()
              // Include tutorial_completed in userData
              setUserData({
                ...profileData,
                plan_selected: mainUserData.plan_selected,
                tutorial_completed: profileData.tutorial_completed || false
              })

              // Show Pro welcome modal first if applicable
              if (profileData.plan === 'paid' && !profileData.plan_welcome) {
                setShowProWelcomeModal(true)
              } else {
                // Start tour if tutorial not completed
                setRun(!profileData.tutorial_completed)
              }
            } else {
              setUserData({
                intake_completed: false,
                plan_selected: mainUserData.plan_selected,
                tutorial_completed: false
              })
              // Start tour for new users
              setRun(true)
            }

            const applicationsData = await getJobseekerApplications(user.uid)
            setApplications(applicationsData)
          } else {
            console.log('No user document found')
          }
        } else {
          console.log('No user signed in')
        }
      } catch (err) {
        console.error('Error fetching user data:', err)
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    fetchUserData()
  }, [navigate, location.pathname, refreshParam])

  useEffect(() => {
    if (!showProWelcomeModal && userData && !userData.tutorial_completed) {
      setRun(true)
    }
  }, [showProWelcomeModal, userData])

  useEffect(() => {
    const fetchEmployers = async () => {
      try {
        setLoadingEmployers(true)
        const employerData = await getEmployers()
        setEmployers(employerData)
      } catch (error) {
        setError(error.message)
      } finally {
        setLoadingEmployers(false)
      }
    }

    fetchEmployers()
  }, [])

  const toggleSection = (section) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section]
    }))
  }

  const handleEmployerClick = (employerId) => {
    if (userData?.intake_completed) {
      navigate(`/employer-profile-view/${employerId}`)
    } else {
      toast.warning(
        'Please complete your profile before accessing employer details.',
        {
          position: 'top-center',
          autoClose: 3000 // Closes after 3 seconds
        }
      )
    }
  }

  // Handlers for tour dashboard button
  const handleStartTour = (e) => {
    e.preventDefault()
    setRun(true)
  }
  const handleJoyrideCallback = async (data) => {
    const { status, index } = data

    if ((status === 'finished' || status === 'skipped') && auth.currentUser) {
      setRun(false)
      setStepIndex(index)

      try {
        // Update tutorial_completed in Firestore
        const profileRef = doc(
          db,
          'users',
          auth.currentUser.uid,
          'profiles',
          'jobseeker'
        )
        await updateDoc(profileRef, {
          tutorial_completed: true
        })

        // Update local state
        setUserData((prev) => ({
          ...prev,
          tutorial_completed: true
        }))
      } catch (error) {
        console.error('Error updating tutorial completion:', error)
      }
    }
  }

  const ApplicationStatusSection = () => (
    <StatusCard>
      <StatusTitle>Applicant Stages</StatusTitle>

      <StageContainer>
        <StageLabel>Reviewed</StageLabel>
        <StageProgressBar>
          <StageProgress width="75%" color="#4ade80" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Interview</StageLabel>
        <StageProgressBar>
          <StageProgress width="45%" color="#60a5fa" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Rejected</StageLabel>
        <StageProgressBar>
          <StageProgress width="25%" color="#f87171" />
        </StageProgressBar>
      </StageContainer>

      <StageContainer>
        <StageLabel>Unreviewed</StageLabel>
        <StageProgressBar>
          <StageProgress width="55%" color="#fbbf24" />
        </StageProgressBar>
      </StageContainer>
    </StatusCard>
  )

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error}</div>

  return (
    <Container>
      <ToastContainer />

      <NavWrapper>
        <JobSeekerNav intakeCompleted={userData?.intake_completed} />
      </NavWrapper>
      <MainContent>
        {userData?.plan !== 'paid' && (
          <UpgradeButton
            href="https://buy.stripe.com/28o03yc5z1DcdpK4gu"
            target="_blank"
            rel="noopener noreferrer"
          >
            Upgrade to Arena Pro✨
          </UpgradeButton>
        )}
        <ContentContainer>
          <WelcomeHeader>
            {' '}
            Welcome to Arena, {userName}!{' '}
            <ViewApplicationsButton onClick={handleStartTour}>
              Tour Arena
            </ViewApplicationsButton>
          </WelcomeHeader>
          {userData?.plan === 'paid' ? (
            <Card style={{ marginBottom: '1.5rem' }}>
              <SectionTitle>Welcome to Arena Pro✨!</SectionTitle>
              <h4 style={{ fontWeight: '300' }}>
                Maximize your job search in sports, media & entertainment: 🚀
              </h4>
              <h4 style={{ fontWeight: '300' }}>
                ⭐️ View AI-powered job matches for every role
              </h4>
              <h4 style={{ fontWeight: '300' }}>
                🗓️ Monthly Office Hours to get personalized career advice:
                <br></br>
                RSVP for next one on March 5th 3pm - 4pm ET:
                <a
                  href="https://lu.ma/p60ak3yj"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register here.
                </a>{' '}
              </h4>
              💡{' '}
              <strong>
                Access the Resource Hub with 1000s of career resources
                including:
              </strong>
              <div style={{ listStyle: 'none', paddingLeft: '0' }}>
                <div>
                  🏆 Elite industry career coaches directory and booking
                </div>
                <div>🗓️ Curated industry events calendar updated daily</div>
                <div>💬 Professional networking templates and strategies</div>
                <div>📝 Proven resume templates that get results</div>
                <div>🏆 Winning cover letter templates and examples</div>
                <div>📜 In-demand certifications to boost your career</div>
                <div>💻 Hand-picked courses for career advancement</div>
                <div>
                  🗞️ Premium media sources for industry news and insights
                </div>
                <div>
                  📜 Identified top schools and programs for industry education
                </div>
                <div>📚 Industry leader-recommended books for success</div>
                <div>🎙️ Career-focused podcasts from industry experts</div>
              </div>
            </Card>
          ) : (
            <Card style={{ marginBottom: '1.5rem' }}>
              <SectionTitle>Welcome to Arena! 🎯</SectionTitle>
              <h4 style={{ fontWeight: '300' }}>
                Get started on your journey in sports, media & entertainment:
              </h4>
              <ul style={{ paddingLeft: '20px' }}>
                <li>
                  ⭐️ <strong>Complete Your Profile</strong>
                  <ul>
                    <li>Browse open positions across the industry</li>
                    <li>Set up job alerts for roles you're interested in</li>
                    <li>Track your applications automatically</li>
                  </ul>
                </li>
                <li>
                  💡 <strong>Upgrade to Arena Pro✨ to unlock:</strong>
                  <ul>
                    <li>
                      Smart Job Matching to find your perfect roles faster
                    </li>
                    <li>
                      Resource Hub with curated industry events, books,
                      certifications, courses, newsletters, and podcasts
                    </li>
                    <li>
                      Early access to our AI Resume Builder (coming 2025!)
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                <em>
                  Note: We're constantly adding new opportunities in sports,
                  media & entertainment. Complete your profile to stay updated
                  when relevant positions go live!
                </em>
              </p>
              <div style={{ textAlign: 'left', marginTop: '1.5rem' }}>
                <a
                  href="https://buy.stripe.com/28o03yc5z1DcdpK4gu"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    backgroundColor: softColors.icontext,
                    color: 'white',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    textDecoration: 'none',
                    fontWeight: 'bold'
                  }}
                >
                  Upgrade to Arena Pro✨
                </a>
              </div>
            </Card>
          )}
          {!userData?.intake_completed && (
            <OnboardingSection
              id="intake-section"
              onClick={() => toggleSection('profile')}
            >
              <SectionHeader>
                <SectionTitle>
                  <SectionIcon>🔥</SectionIcon>
                  Complete your profile to get matched to relevant jobs and
                  opportunities for you.
                </SectionTitle>
                <ChevronDown
                  size={20}
                  style={{
                    transform: openSections.profile ? 'rotate(180deg)' : 'none',
                    transition: 'transform 0.3s',
                    cursor: 'pointer'
                  }}
                />
              </SectionHeader>
              <SectionProgressBar>
                <SectionProgress width="30%" started={true} />
              </SectionProgressBar>
              <SectionContent isOpen={openSections.profile}>
                <Link href="/jobseeker-intake">Edit Profile</Link>
              </SectionContent>
            </OnboardingSection>
          )}
          {/* Jobs Applied Section */}
          <Grid columns={2} id="jobs-applied-section">
            {/* Jobs Applied Section */}
            <Card>
              <CardHeader>
                <CardTitle>Jobs Applied</CardTitle>
              </CardHeader>
              <CardContent>
                <p
                  style={{
                    fontSize: '2.25rem',
                    fontWeight: 'bold',
                    color: '#C859FF',
                    marginBottom: '0.5rem'
                  }}
                >
                  {userData?.jobs_applied?.length || 0}
                </p>
                <ViewApplicationsButton
                  onClick={() => navigate('/application-tracking')}
                >
                  View Applications
                </ViewApplicationsButton>
              </CardContent>
            </Card>
            {/* Application Status Section */}
            <Card id="applicant-stages-section">
              <ApplicationStages applications={applications} />
            </Card>
          </Grid>
          {/* Other Sections */}
          {/* MSBC Employers Section */}
          <Card>
            <CardHeader>
              <CardTitle>Employers</CardTitle>
            </CardHeader>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Company</TableHeader>
                  <TableHeader>Open Roles</TableHeader>
                </tr>
              </thead>
              {loadingEmployers ? (
                <tbody>
                  {[...Array(3)].map((_, index) => (
                    <tr key={index}>
                      <TableCell>
                        <TableSubCell>
                          <SkeletonImage />
                          <SkeletonText maxWidth="4rem" />
                        </TableSubCell>
                      </TableCell>
                      <TableCell>
                        <TableSubCell>
                          <SkeletonText width="3rem" maxWidth="2rem" />
                        </TableSubCell>
                      </TableCell>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  {employers.map((employer) => (
                    <tr
                      key={employer.id}
                      onClick={() => handleEmployerClick(employer.id)} // Make the entire row clickable
                      style={{
                        cursor: 'pointer',
                        transition: 'background-color 0.2s'
                      }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor = '#f0f4f8')
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor = '#ffffff')
                      }
                    >
                      <TableCell>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem'
                          }}
                        >
                          {employer.logo ? (
                            <img
                              src={employer.logo}
                              alt={`${employer.name} logo`}
                              style={{
                                width: '2.5rem',
                                height: '2.5rem',
                                borderRadius: '9999px',
                                objectFit: 'cover'
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: '2.5rem',
                                height: '2.5rem',
                                borderRadius: '9999px',
                                backgroundColor: '#f3f4f6',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontSize: '1rem',
                                fontWeight: 'bold',
                                color: '#6b7280'
                              }}
                            >
                              {employer.name.charAt(0)}
                            </div>
                          )}
                          <span style={{ fontWeight: '500' }}>
                            {employer.name}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell>
                        {employer.openRoles > 0 ? (
                          <span style={{ color: softColors.success }}>
                            {employer.openRoles} open roles
                          </span>
                        ) : (
                          'No open roles'
                        )}
                      </TableCell>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
          </Card>
          <Card id="ai-transparency-section" style={{ marginTop: '2rem' }}>
            <CardTitle>How Our AI Works</CardTitle>
            <p
              style={{
                fontSize: '0.875rem',
                color: softColors.textLight,
                lineHeight: '1.5'
              }}
            >
              At Arena, we believe in transparency and equity throughout the
              hiring process. Our scoring system is designed to benefit both
              employers and job seekers. Here's a comprehensive look at how we
              evaluate candidates.{' '}
              <a
                href="https://arenatalent.notion.site/arena-methodology-overview"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: softColors.linkColor,
                  textDecoration: 'underline'
                }}
              >
                Click here to view Arena's AI Methodology
              </a>
            </p>
          </Card>
        </ContentContainer>
      </MainContent>
      <NotificationPanel isPaidUser={userData?.plan === 'paid'} />

      <Joyride
        steps={steps}
        run={run}
        callback={handleJoyrideCallback}
        continuous={true}
        disableOverlayClose={true}
        scrollToFirstStep
        showSkipButton
        locale={{
          last: 'Finish'
        }}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: '#fff',
            backgroundColor: '#fff',
            primaryColor: '#C471ED'
          },
          buttonClose: {
            display: 'none'
          },
          tooltip: {
            borderRadius: 8,
            maxWidth: '100%',
            padding: 15
          },
          spotlight: {
            borderRadius: 12
          },
          buttonNext: {
            borderColor: '#C471ED',
            borderStyle: 'solid',
            borderWidth: '2px',
            boxShadow: 'none',
            backgroundColor: '#C471ED',
            color: '#fff'
          }
        }}
      />
      <ProWelcomeModal
        isOpen={showProWelcomeModal}
        onClose={() => setShowProWelcomeModal(false)}
        userId={auth.currentUser?.uid}
      />
    </Container>
  )
}
