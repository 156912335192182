import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { db, auth } from '../../firebase'
import { doc, getDoc } from 'firebase/firestore'
import { getUserByFirebaseUid } from '../../models/User'
import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'
import {
  Search,
  Share2,
  Star,
  MoreVertical,
  Paperclip,
  Send
} from 'lucide-react'
import JobSeekerNav from './JobSeekerNav'

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#4a90e2',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0'
}

const Container = styled.div`
  display: flex;
  height: 100vh;
  background-color: ${softColors.background};
`

const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  background-color: #ef4444;
  border-radius: 50%;
  position: absolute;
  right: 8px;
  top: 1;
`
const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${softColors.border};
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const MainContent = styled.main`
  flex: 1;
  display: flex;
  overflow: hidden;
`

const Sidebar = styled.div`
  width: 320px;
  border-right: 1px solid ${softColors.border};
  background-color: ${softColors.card};
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${softColors.border};
`

const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
  color: ${softColors.text};
`

const SearchContainer = styled.div`
  position: relative;
`

const SearchInput = styled.input`
  width: 80%;
  padding: 8px 8px 8px 32px;
  border: 1px solid ${softColors.border};
  border-radius: 4px;
  font-size: 14px;
  color: ${softColors.text};
  background-color: ${softColors.background};

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const SearchIcon = styled(Search)`
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: ${softColors.textLight};
`

const MainChat = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ChatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid ${softColors.border};
  background-color: ${softColors.card};
`

const RecipientInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const ProfilePhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: ${softColors.border};
`

const RecipientName = styled.h2`
  font-weight: 600;
  color: ${softColors.text};
`

const RecipientStatus = styled.p`
  font-size: 14px;
  color: ${softColors.textLight};
`

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
`

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: ${softColors.textLight};
  padding: 4px;

  &:hover {
    color: ${softColors.primary};
  }
`

const MessagesContainer = styled.div`
  flex: 1;
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  background-color: white;
  height: calc(100vh - 200px);
  overflow-y: auto;
`

const MessageBubble = styled.div`
  display: flex;
  gap: 1rem;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 80%;
`

const ArenaLogo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
  align-self: center;
`

const BubbleContent = styled.div`
  background-color: #edf2f7;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  border-bottom-left-radius: 0;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
`

const SenderName = styled.div`
  font-size: 0.875rem;
  font-weight: 600;
  color: ${softColors.text};
  margin-bottom: 0.5rem;
`

const MessageText = styled.p`
  font-size: 1rem;
  color: #4a5568;
  line-height: 1.5;
  margin: 0;
`

const Timestamp = styled.div`
  font-size: 0.75rem;
  color: ${softColors.textLight};
  margin-top: 0.5rem;
`

const ChatArea = styled.div`
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${softColors.textLight};
  background-color: ${softColors.background};
`

const InputArea = styled.div`
  display: flex;
  gap: 8px;
  padding: 16px;
  border-top: 1px solid ${softColors.border};
  background-color: ${softColors.card};
`

const MessageInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid ${softColors.border};
  border-radius: 4px;
  font-size: 14px;
  color: ${softColors.text};

  &::placeholder {
    color: ${softColors.textLight};
  }
`

const SendButton = styled.button`
  background-color: ${softColors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: ${softColors.border};
    cursor: not-allowed;
  }
`

const MessageWrapper = styled.div`
  position: relative;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid ${softColors.border};
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${softColors.background};
  }
`

const ProBanner = styled.div`
  background-color: #d29856;
  padding: 6px 16px;
  position: fixed;
  margin-top: 10px;
  top: 0;
  right: 0;
  z-index: 1001;
  border-bottom-left-radius: 8px;
  font-family: 'Roboto', sans-serif;
`

const ProText = styled.span`
  color: white;
  font-size: 14px;
`

const UpgradeButton = styled.a`
  position: fixed;
  top: 16px;
  right: 16px;
  background-color: #d29856;
  color: white;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  z-index: 1001; // Added to ensure bubble appears above other content
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 768px) {
    padding: 4px 8px;
    font-size: 12px;
  }
`

export default function JobSeekerMessaging() {
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [profilePhoto, setProfilePhoto] = useState(null)
  const [userProfile, setUserProfile] = useState(null)

  const [hasSeenWelcome, setHasSeenWelcome] = useState(false)

  useEffect(() => {
    const fetchProfileData = async () => {
      const currentUser = auth.currentUser
      if (currentUser) {
        try {
          const userData = await getUserByFirebaseUid(currentUser.uid)
          const jobseekerProfileData = await getJobseekerProfile(
            currentUser.uid
          )

          if (jobseekerProfileData?.profile_picture) {
            setProfilePhoto(jobseekerProfileData.profile_picture)
          }

          // Check if user has seen welcome message
          setHasSeenWelcome(jobseekerProfileData?.has_seen_welcome || false)
        } catch (error) {
          console.error('Error fetching user profile data:', error.message)
        }
      }
    }
    fetchProfileData()
  }, [])

  useEffect(() => {
    const fetchProfileData = async () => {
      const currentUser = auth.currentUser
      if (currentUser) {
        try {
          const userData = await getUserByFirebaseUid(currentUser.uid)
          const jobseekerProfileData = await getJobseekerProfile(
            currentUser.uid
          )

          if (jobseekerProfileData?.profile_picture) {
            setProfilePhoto(jobseekerProfileData.profile_picture)
          }

          // Set user profile for Pro banner
          setUserProfile(jobseekerProfileData)

          // Check if user has seen welcome message
          setHasSeenWelcome(jobseekerProfileData?.has_seen_welcome || false)
        } catch (error) {
          console.error('Error fetching user profile data:', error.message)
        }
      }
    }
    fetchProfileData()
  }, [])

  const handleSelectMessage = async () => {
    setSelectedMessage({
      sender: 'Arena',
      text: 'Welcome to Arena! This is your personal messaging hub where you can connect with potential employers. Once employers show interest in your profile, they will reach out to you here first. You can then view their messages and respond directly through this portal to start the conversation..'
    })

    // Update profile when user clicks the message
    if (!hasSeenWelcome) {
      const currentUser = auth.currentUser
      if (currentUser) {
        try {
          await updateJobseekerProfile(currentUser.uid, {
            has_seen_welcome: true
          })
          setHasSeenWelcome(true)
        } catch (error) {
          console.error('Error updating profile:', error)
        }
      }
    }
  }

  return (
    <Container>
      <NavWrapper>
        <JobSeekerNav />
      </NavWrapper>
      <MainContent>
        {userProfile?.plan === 'paid' && (
          <ProBanner>
            <ProText>Arena Pro✨</ProText>
          </ProBanner>
        )}
        {userProfile?.plan !== 'paid' && (
          <UpgradeButton
            href="https://buy.stripe.com/28o03yc5z1DcdpK4gu"
            target="_blank"
            rel="noopener noreferrer"
            >Upgrade to Arena Pro✨
          </UpgradeButton>
        )}
        <Sidebar>
          <Header>
            <Title>Messages</Title>
            <SearchContainer>
              <SearchIcon size={16} />
              <SearchInput placeholder="Search messages" />
            </SearchContainer>
          </Header>
          <MessageWrapper onClick={handleSelectMessage}>
            <ArenaLogo src="/images/logo-black.png" alt="Arena Logo" />
            <div style={{ marginLeft: '1rem' }}>
              <SenderName>Arena</SenderName>
              <MessageText>Welcome!</MessageText>
            </div>
            {!hasSeenWelcome && <NotificationDot />}
          </MessageWrapper>
        </Sidebar>
        <MainChat>
          <ChatHeader>
            <RecipientInfo>
              {profilePhoto ? (
                <ProfilePhoto src={profilePhoto} alt="User Photo" />
              ) : (
                <Avatar />
              )}
              <div>
                <RecipientName>
                  {selectedMessage
                    ? selectedMessage.sender
                    : 'Select a conversation'}
                </RecipientName>
                <RecipientStatus>
                  {selectedMessage
                    ? selectedMessage.timestamp
                    : 'No message selected'}
                </RecipientStatus>
              </div>
            </RecipientInfo>
            <ActionButtons>
              <IconButton>
                <Share2 size={16} />
              </IconButton>
              <IconButton>
                <Star size={16} />
              </IconButton>
              <IconButton>
                <MoreVertical size={16} />
              </IconButton>
            </ActionButtons>
          </ChatHeader>
          <MessagesContainer>
            {selectedMessage ? (
              <MessageBubble>
                <ArenaLogo src="/images/logo-black.png" alt="Arena Logo" />
                <BubbleContent>
                  <SenderName>{selectedMessage.sender}</SenderName>
                  <MessageText>{selectedMessage.text}</MessageText>
                  <Timestamp>{selectedMessage.timestamp}</Timestamp>
                </BubbleContent>
              </MessageBubble>
            ) : (
              <ChatArea>Select a message to view details</ChatArea>
            )}
          </MessagesContainer>
          <InputArea>
            <IconButton>
              <Paperclip size={16} />
            </IconButton>
            <MessageInput placeholder="Type a message" />
            <SendButton disabled>
              <Send size={16} />
            </SendButton>
          </InputArea>
        </MainChat>
      </MainContent>
    </Container>
  )
}
