import React, { useState } from 'react'
import styled from 'styled-components'
import {
  X,
  Plus,
  ArrowLeft,
  Heart,
  Calendar,
  GraduationCap,
  Laptop,
  Smile,
  Monitor,
  Users,
  Train,
  Coffee,
  BanknoteIcon,
  Shield
} from 'lucide-react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { createJobPosting } from '../models/JobPosting'
import CreatableSelect from 'react-select/creatable'

// Styled components from your original form
const Container = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f0f4f8;
  color: #2d3748;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const FormCard = styled.form`
  padding: 2rem;
  @media (max-width: 768px) {
    padding: 0rem;
  }
`

const FormTitle = styled.h1`
  font-size: 1.875rem;
  font-weight: 600;
  color: #1e293b;
  margin-bottom: 3rem;
`

const FormSection = styled.div`
  margin-bottom: 2.5rem;
  position: relative;
`

const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.75rem;
  color: #475569;

  ${(props) =>
    props.required &&
    `
    &::after {
      content: '*';
      color: #e53e3e;
      margin-left: 4px;
    }
  `}
`

const Input = styled.input`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }

  &::placeholder {
    color: #94a3b8;
  }
`

const Select = styled.select`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
`

const CheckboxGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
`

const RadioGroup = styled.div`
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  transition: all 0.2s;
  background: ${(props) => (props.checked ? '#f8f4fb' : 'white')};
  border: 1px solid ${(props) => (props.checked ? '#caaae1' : '#e2e8f0')};

  &:hover {
    background: #f8f4fb;
  }

  input {
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
  }
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 6px;
  transition: all 0.2s;

  &:hover {
    background: #f8fafc;
  }

  input {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 4px;
    cursor: pointer;
  }
`

const SkillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-top: 1rem;
`

const SkillTag = styled.span`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f8f4fb;
  color: #805ad5;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
`

const SalaryInputGroup = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`

const SalaryInput = styled(Input)`
  width: 200px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #caaae1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #b38fd1;
  }
`

const SubmitButton = styled(Button)`
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  margin-top: 3rem;
`

const RichTextWrapper = styled.div`
  .quill {
    background: white;
    border-radius: 8px;
    border: 1px solid #e2e8f0;

    .ql-toolbar {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: none;
      border-bottom: 1px solid #e2e8f0;
      background: #f8fafc;
    }

    .ql-container {
      border: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      font-family: 'Inter', sans-serif;
      font-size: 0.875rem;
    }

    .ql-editor {
      min-height: 120px;

      &:focus {
        outline: none;
      }
    }
  }
`

// Benefits Grid and Card Styles
export const BenefitsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-top: 1rem;
`

export const BenefitCard = styled.div`
  position: relative;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
  text-align: center;
  transition: all 0.2s;

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`

export const BenefitIcon = styled.div`
  width: 48px;
  height: 48px;
  background-color: #f8f4fb;
  color: #caaae1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
`

// Modal and Overlay Styles
export const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1001;
  width: 90%;
  max-width: 500px;
`

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  backdrop-filter: blur(2px);
`

// Form Section and Labels

export const Textarea = styled.textarea`
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.875rem;
  min-height: 120px;
  font-family: 'Inter', sans-serif;
  resize: vertical;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }

  &::placeholder {
    color: #94a3b8;
  }
`

const RemoveButton = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  color: #805ad5;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;

  &:hover {
    color: #553c9a;
  }
`

const quillModules = {
  toolbar: [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean']
  ]
}

const AdminJobPostForm = () => {
  const [showOtherTechnicalSkills, setShowOtherTechnicalSkills] =
    useState(false)
  const [otherTechnicalSkills, setOtherTechnicalSkills] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const [formData, setFormData] = useState({
    title: '',
    type: [],
    location: '',
    work_policy: '',
    department: '',
    level: '',
    required_skills: [],
    skillInput: '',
    description: '',
    responsibilities: '',
    requirements: '',
    nice_to_haves: '',
    benefits: [],
    salary_type: 'yearly',
    salary_range_start: '40000',
    salary_range_end: '150000',
    willing_to_sponsor: false,
    strengths: [],
    technical_skills: [],
    additional_compensation: [],
    employer_name: '',
    application_type: 'external',
    external_application_url: '',
    date_posted: new Date().toISOString().split('T')[0]
  })

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [companyBenefits, setCompanyBenefits] = useState([])
  const [showCompanyBenefits, setShowCompanyBenefits] = useState(true)
  const [showBenefitModal, setShowBenefitModal] = useState(false)
  const [newBenefit, setNewBenefit] = useState({
    category: '',
    description: ''
  })

  const benefitCategories = [
    { value: 'Healthcare', label: 'Healthcare', icon: Heart },
    { value: 'Time Off', label: 'Time Off', icon: Calendar },
    {
      value: 'Learning & Development',
      label: 'Learning & Development',
      icon: GraduationCap
    },
    { value: 'Remote Work', label: 'Remote Work', icon: Laptop },
    { value: 'Wellness', label: 'Wellness', icon: Smile },
    { value: 'Equipment', label: 'Equipment', icon: Monitor },
    { value: 'Family', label: 'Family', icon: Users },
    { value: 'Commuter', label: 'Commuter', icon: Train },
    { value: 'Food & Drinks', label: 'Food & Drinks', icon: Coffee },
    { value: 'Retirement', label: 'Retirement', icon: BanknoteIcon },
    { value: 'Insurance', label: 'Insurance', icon: Shield }
  ]

  // Constants from original form
  const employmentTypes = [
    'Full-Time',
    'Part-time',
    'Internship',
    'Contract',
    'Hourly'
  ]
  const workPolicyOptions = ['Remote', 'In-Office', 'Hybrid']
  const levelOptions = [
    'Internship',
    'Entry Level',
    'Manager',
    'Director',
    'Head / Lead',
    'Vice President',
    'Senior Vice President',
    'C-Suite Executive'
  ]
  const strengths = [
    'Communication',
    'Leadership',
    'Problem Solving',
    'Teamwork',
    'Adaptability',
    'Creativity',
    'Time Management',
    'Critical Thinking',
    'Attention to Detail',
    'Emotional Intelligence',
    'Strategic Planning',
    'Networking',
    'Decision Making',
    'Conflict Resolution',
    'Analytical Skills',
    'Project Management',
    'Negotiation',
    'Public Speaking',
    'Customer Service',
    'Technical Proficiency',
    'Data Analysis',
    'Research',
    'Writing',
    'Multitasking',
    'Sales',
    'Marketing',
    'Budgeting',
    'Coaching',
    'Innovation',
    'Interpersonal Skills'
  ].sort()

  const technicalSkills = [
    'Adobe Creative Suite',
    'Adobe XD',
    'Airtable',
    'Amplitude',
    'Asana',
    'AutoCAD',
    'AWS',
    'Azure',
    'C++',
    'Calendly',
    'CI/CD',
    'ClickUp',
    'Confluence',
    'Docker',
    'Figma',
    'G Suite',
    'Git',
    'GitHub/GitLab',
    'Google Analytics',
    'HubSpot',
    'Java',
    'JavaScript',
    'Jenkins',
    'Jira',
    'Kubernetes',
    'Linear',
    'Looker',
    'Microsoft Dynamics',
    'Microsoft Excel',
    'Microsoft PowerPoint',
    'Microsoft SharePoint',
    'Microsoft Teams',
    'Microsoft Word',
    'Miro',
    'Mixpanel',
    'Monday.com',
    'MongoDB',
    'MySQL',
    'NetSuite',
    'Node.js',
    'Notion',
    'Oracle',
    'Oracle ERP',
    'PostgreSQL',
    'Postman',
    'Python',
    'QuickBooks',
    'React.js',
    'Redis',
    'REST APIs',
    'Revit',
    'SAP',
    'Salesforce',
    'ServiceNow',
    'Sketch',
    'Slack',
    'SQL',
    'Tableau',
    'Trello',
    'TypeScript',
    'Unity',
    'Workday',
    'Zendesk',
    'Zoom',
    'Other'
  ].sort((a, b) => {
    // Keep "Other" at the end
    if (a === 'Other') return 1
    if (b === 'Other') return -1
    return a.localeCompare(b)
  })

  const additionalCompensationOptions = [
    'Annual Bonus',
    'Commission',
    'Equity',
    'Performance Incentive Bonus',
    'Profit Sharing',
    'School Credit',
    'Signing Bonus',
    'Stock Options'
  ]

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }))
  }
  const handleToggleCompanyBenefits = () => {
    setShowCompanyBenefits(!showCompanyBenefits)
  }

  const handleSalaryTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      salary_type: type,
      salary_range_start:
        type === 'yearly'
          ? '40000'
          : type === 'hourly'
          ? '10'
          : type === 'stipend'
          ? '500'
          : '0',
      salary_range_end:
        type === 'yearly'
          ? '150000'
          : type === 'hourly'
          ? '100'
          : type === 'stipend'
          ? '5000'
          : '0'
    }))
  }

  const handleTypeChange = (type) => {
    setFormData((prev) => ({
      ...prev,
      type: prev.type.includes(type)
        ? prev.type.filter((t) => t !== type)
        : [...prev.type, type]
    }))
  }

  const handleAddBenefit = () => {
    if (!newBenefit.category || !newBenefit.description) {
      alert('Please fill in all benefit fields')
      return
    }

    setFormData((prev) => ({
      ...prev,
      benefits: [...prev.benefits, newBenefit]
    }))
    setNewBenefit({ category: '', description: '' })
    setShowBenefitModal(false)
  }

  const handleRemoveBenefit = (index) => {
    setFormData((prev) => ({
      ...prev,
      benefits: prev.benefits.filter((_, i) => i !== index)
    }))
  }

  const handleStrengthSelect = (strength) => {
    setFormData((prev) => {
      const selected = prev.strengths.includes(strength)
        ? prev.strengths.filter((s) => s !== strength)
        : [...prev.strengths, strength]
      return { ...prev, strengths: selected.slice(0, 3) }
    })
  }

  const handleTechnicalSkillSelect = (skill) => {
    if (skill === 'Other') {
      setShowOtherTechnicalSkills((prev) => !prev)
      return
    }

    setFormData((prev) => ({
      ...prev,
      technical_skills: prev.technical_skills.includes(skill)
        ? prev.technical_skills.filter((s) => s !== skill)
        : [...prev.technical_skills, skill]
    }))
  }

  const handleRemoveCustomSkill = (skillToRemove) => {
    setFormData((prev) => ({
      ...prev,
      technical_skills: prev.technical_skills.filter(
        (skill) => skill !== skillToRemove
      )
    }))
  }

  const handleOtherTechnicalSkillsSubmit = () => {
    if (!otherTechnicalSkills.trim()) return

    const newSkills = otherTechnicalSkills
      .split(',')
      .map((skill) => skill.trim())
      .filter((skill) => skill && !formData.technical_skills.includes(skill)) // Prevent duplicates

    setFormData((prev) => ({
      ...prev,
      technical_skills: [...prev.technical_skills, ...newSkills]
    }))

    setOtherTechnicalSkills('') // Clear the input after adding
  }

  const handleAdditionalCompensationChange = (compensation) => {
    setFormData((prev) => ({
      ...prev,
      additional_compensation: prev.additional_compensation.includes(
        compensation
      )
        ? prev.additional_compensation.filter((item) => item !== compensation)
        : [...prev.additional_compensation, compensation]
    }))
  }

  const handleSkillAdd = () => {
    if (formData.skillInput.trim()) {
      setFormData((prev) => ({
        ...prev,
        required_skills: [...prev.required_skills, prev.skillInput.trim()],
        skillInput: ''
      }))
    }
  }

  const handleSkillRemove = (skillToRemove) => {
    setFormData((prev) => ({
      ...prev,
      required_skills: prev.required_skills.filter(
        (skill) => skill !== skillToRemove
      )
    }))
  }

  const handleRichTextChange = (value, field) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value
    }))
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      // Frontend validation for required fields
      if (!formData.employer_name.trim()) {
        alert('Employer name is required.')
        setIsSubmitting(false)
        return
      }

      if (!formData.title.trim()) {
        alert('Job title is required.')
        setIsSubmitting(false)
        return
      }

      if (formData.type.length === 0) {
        alert('Please select at least one type of employment.')
        setIsSubmitting(false)
        return
      }

      if (!formData.location.trim()) {
        alert('Location is required.')
        setIsSubmitting(false)
        return
      }

      if (!formData.department.trim()) {
        alert('Department is required.')
        setIsSubmitting(false)
        return
      }

      if (!formData.level) {
        alert('Level is required.')
        setIsSubmitting(false)
        return
      }

      // Date validation
      if (formData.date_posted) {
        const selectedDate = new Date(formData.date_posted)
        const today = new Date()
        const minDate = new Date('2024-01-01')
        const maxDate = new Date('2025-12-31')

        // Reset hours to compare just the dates
        selectedDate.setHours(0, 0, 0, 0)
        today.setHours(0, 0, 0, 0)

        if (selectedDate < minDate || selectedDate > maxDate) {
          alert(
            'Please select a date between January 1, 2024 and December 31, 2025.'
          )
          setIsSubmitting(false)
          return
        }
      }

      // Simple salary validation - just check that a type is selected
      if (!formData.salary_type) {
        alert(
          'Please select a salary type (yearly, hourly, or prefer not to disclose).'
        )
        setIsSubmitting(false)
        return
      }

      // Rich text editor content validation
      if (
        !formData.description.trim() ||
        formData.description === '<p><br></p>'
      ) {
        alert('Job Summary is required.')
        setIsSubmitting(false)
        return
      }

      if (
        !formData.responsibilities.trim() ||
        formData.responsibilities === '<p><br></p>'
      ) {
        alert('Responsibilities are required.')
        setIsSubmitting(false)
        return
      }

      if (
        !formData.requirements.trim() ||
        formData.requirements === '<p><br></p>'
      ) {
        alert('Requirements are required.')
        setIsSubmitting(false)
        return
      }

      // Validate external application URL
      if (!formData.external_application_url.trim()) {
        alert('External application URL is required.')
        setIsSubmitting(false)
        return
      }

      // Ensure external_application_url starts with "https://"
      let externalApplicationUrl = formData.external_application_url.trim()
      if (!/^https?:\/\//i.test(externalApplicationUrl)) {
        externalApplicationUrl = `https://${externalApplicationUrl}`
      }

      // URL validation
      try {
        new URL(externalApplicationUrl)
      } catch (error) {
        alert('Please enter a valid URL for the external application.')
        setIsSubmitting(false)
        return
      }

      const postingData = {
        title: formData.title.trim(),
        type: formData.type,
        location: formData.location.trim(),
        work_policy: formData.work_policy || null,
        department: formData.department.trim(),
        level: formData.level,
        description: formData.description.trim(),
        responsibilities: formData.responsibilities.trim(),
        requirements: formData.requirements.trim(),
        strengths: formData.strengths || [],
        technical_skills: Array.isArray(formData.technical_skills)
          ? formData.technical_skills.filter((skill) => skill.trim() !== '')
          : [],
        nice_to_haves: formData.nice_to_haves?.trim() || null,
        recommended_skills: formData.required_skills || [],
        benefits: formData.benefits || [],
        willing_to_sponsor: formData.willing_to_sponsor || false,
        application_type: 'external',
        external_application_url: externalApplicationUrl,
        salary:
          formData.salary_type === 'hidden'
            ? { type: 'hidden' }
            : {
                type: formData.salary_type,
                range_start: parseInt(formData.salary_range_start) || 0,
                range_end: parseInt(formData.salary_range_end) || 0
              },
        additional_compensation: formData.additional_compensation || [],
        employer_name: formData.employer_name.trim(),
        sourced: true,
        date_posted:
          formData.date_posted || new Date().toISOString().split('T')[0]
      }

      // Call the createJobPosting function
      await createJobPosting(postingData)

      // Show success message
      setSuccessMessage('Job posting created successfully!')
      setTimeout(() => setSuccessMessage(''), 5000) // Clear message after 5 seconds

      // Reset form data
      setFormData({
        title: '',
        type: [],
        location: '',
        work_policy: '',
        department: '',
        level: '',
        required_skills: [],
        skillInput: '',
        description: '',
        responsibilities: '',
        requirements: '',
        nice_to_haves: '',
        benefits: [],
        salary_type: 'yearly',
        salary_range_start: '40000',
        salary_range_end: '150000',
        willing_to_sponsor: false,
        strengths: [],
        technical_skills: [],
        additional_compensation: [],
        employer_name: '',
        application_type: 'external',
        external_application_url: '',
        date_posted: new Date().toISOString().split('T')[0] // Reset to today's date
      })
    } catch (error) {
      console.error('Error creating job posting:', error)
      alert('Error creating job posting. Please try again.')
    } finally {
      setIsSubmitting(false)
    }
  }
  // Createable takes objects
  const technicalSkillsOptions = technicalSkills.map((skill) => ({
    value: skill,
    label: skill
  }))

  const [allOptions, setAllOptions] = useState(technicalSkillsOptions)
  const [selectedSkills, setSelectedSkills] = useState([])

  const TechnicalSkillsDropdown = () => {
    const handleTechnicalSkillsChange = (selectedOptions) => {
      if (!selectedOptions) {
        setSelectedSkills([])
        setFormData((prev) => ({ ...prev, technical_skills: [] }))
        return
      }
      setSelectedSkills(selectedOptions)

      // set form data
      setFormData((prev) => ({
        ...prev,
        technical_skills: selectedOptions.map((option) => option.value) // Convert back to array of strings
      }))
    }

    const handleCreateCustomSkill = (skill) => {
      const newOption = { value: skill, label: skill }

      setAllOptions((prevOptions) => [...prevOptions, newOption])

      const updatedSelectedSkills = [...selectedSkills, newOption]
      setSelectedSkills(updatedSelectedSkills)

      // set form data
      setFormData((prev) => ({
        ...prev,
        technical_skills: updatedSelectedSkills.map((option) => option.value)
      }))
    }
    return (
      <FormSection>
        <Label>Technical Skills</Label>
        <CreatableSelect
          isMulti
          options={allOptions}
          value={selectedSkills}
          onChange={handleTechnicalSkillsChange}
          onCreateOption={handleCreateCustomSkill}
          placeholder="Enter technical skills"
          // custom styles to match input fields
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              border: '1px solid #e2e8f0',
              '&:hover': { borderColor: '1px solid #e2e8f0' },
              borderRadius: '8px',
              boxShadow: 'none',
              padding: '0.2rem'
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              fontSize: '0.875rem',
              color: '#94a3b8'
            })
          }}
        />
      </FormSection>
    )
  }
  return (
    <Container>
      <MainContent>
        {successMessage && (
          <div
            style={{
              padding: '1rem',
              backgroundColor: '#38A169', // Green background
              color: 'white',
              borderRadius: '8px',
              marginBottom: '1rem',
              textAlign: 'center',
              fontSize: '1rem',
              fontWeight: 'bold'
            }}
          >
            {successMessage}
          </div>
        )}

        <FormCard onSubmit={handleSubmit}>
          <FormTitle>Create Job Posting (Admin)</FormTitle>

          <FormSection>
            <Label required>Employer Name</Label>
            <Input
              type="text"
              name="employer_name"
              value={formData.employer_name}
              onChange={handleInputChange}
              required
              placeholder="Enter employer name"
            />
          </FormSection>

          <FormSection>
            <Label required>Job Title</Label>
            <Input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              required
              placeholder="e.g., Senior Software Engineer"
            />
          </FormSection>

          <FormSection>
            <Label required>Type of Employment</Label>
            <CheckboxGroup>
              {employmentTypes.map((type) => (
                <CheckboxLabel key={type}>
                  <input
                    type="checkbox"
                    checked={formData.type.includes(type)}
                    onChange={() => handleTypeChange(type)}
                  />
                  <span>{type}</span>
                </CheckboxLabel>
              ))}
            </CheckboxGroup>
          </FormSection>

          <GridContainer>
            <FormSection>
              <Label required>Location</Label>
              <Input
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                placeholder="e.g., New York, NY"
              />
            </FormSection>

            <FormSection style={{ marginLeft: '20px' }}>
              <Label>Work Policy</Label>
              <Select
                name="work_policy"
                value={formData.work_policy}
                onChange={handleInputChange}
              >
                <option value="">Select Work Policy</option>
                {workPolicyOptions.map((policy) => (
                  <option key={policy} value={policy}>
                    {policy}
                  </option>
                ))}
              </Select>
            </FormSection>
          </GridContainer>

          <GridContainer>
            <FormSection>
              <Label required>Department</Label>
              <Input
                type="text"
                name="department"
                value={formData.department}
                onChange={handleInputChange}
                placeholder="e.g., Engineering"
              />
            </FormSection>

            <FormSection style={{ marginLeft: '20px' }}>
              <Label required>Level</Label>
              <Select
                name="level"
                value={formData.level}
                onChange={handleInputChange}
              >
                <option value="">Select Level</option>
                {levelOptions.map((level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
              </Select>
            </FormSection>
          </GridContainer>

          <FormSection>
            <Label required>Salary</Label>
            <RadioGroup>
              <RadioLabel checked={formData.salary_type === 'yearly'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'yearly'}
                  onChange={() => handleSalaryTypeChange('yearly')}
                />
                Yearly Salary
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'hourly'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'hourly'}
                  onChange={() => handleSalaryTypeChange('hourly')}
                />
                Hourly Rate
              </RadioLabel>
              <RadioLabel checked={formData.salary_type === 'hidden'}>
                <input
                  type="radio"
                  name="salary_type"
                  checked={formData.salary_type === 'hidden'}
                  onChange={() => handleSalaryTypeChange('hidden')}
                />
                Prefer not to disclose
              </RadioLabel>
            </RadioGroup>

            {formData.salary_type !== 'hidden' && (
              <SalaryInputGroup>
                <SalaryInput
                  type="number"
                  name="salary_range_start"
                  value={formData.salary_range_start}
                  onChange={handleInputChange}
                  placeholder="Starting range"
                />
                <span>to</span>
                <SalaryInput
                  type="number"
                  name="salary_range_end"
                  value={formData.salary_range_end}
                  onChange={handleInputChange}
                  placeholder="Ending range"
                />
                {formData.salary_type === 'hourly' && <span>/hour</span>}
              </SalaryInputGroup>
            )}
          </FormSection>

          <FormSection>
            <Label>Additional Compensation</Label>
            <CheckboxGroup>
              {additionalCompensationOptions.map((option) => (
                <CheckboxLabel key={option}>
                  <input
                    type="checkbox"
                    checked={formData.additional_compensation.includes(option)}
                    onChange={() => handleAdditionalCompensationChange(option)}
                  />
                  <span>{option}</span>
                </CheckboxLabel>
              ))}
            </CheckboxGroup>
          </FormSection>

          <FormSection>
            <Label required>Job Summary</Label>
            <RichTextWrapper>
              <ReactQuill
                value={formData.description}
                onChange={(value) => handleRichTextChange(value, 'description')}
                modules={quillModules}
                placeholder="Provide a detailed description of the role"
              />
            </RichTextWrapper>
          </FormSection>

          <FormSection>
            <Label required>Responsibilities</Label>
            <RichTextWrapper>
              <ReactQuill
                value={formData.responsibilities}
                onChange={(value) =>
                  handleRichTextChange(value, 'responsibilities')
                }
                modules={quillModules}
                placeholder="List the key responsibilities of the role"
              />
            </RichTextWrapper>
          </FormSection>

          <FormSection>
            <Label required>Requirements</Label>
            <RichTextWrapper>
              <ReactQuill
                value={formData.requirements}
                onChange={(value) =>
                  handleRichTextChange(value, 'requirements')
                }
                modules={quillModules}
                placeholder="List the requirements for this position"
              />
            </RichTextWrapper>
          </FormSection>

          <FormSection>
            <Label>Preferred Qualifications </Label>
            <RichTextWrapper>
              <ReactQuill
                value={formData.nice_to_haves}
                onChange={(value) =>
                  handleRichTextChange(value, 'nice_to_haves')
                }
                modules={quillModules}
                placeholder="List any preferred qualifications or bonus skills"
              />
            </RichTextWrapper>
          </FormSection>

          <FormSection>
            <Label>
              What qualities do you look for in this candidate? (Select up to 3)
            </Label>
            <SkillContainer>
              {strengths.map((strength) => (
                <SkillTag
                  key={strength}
                  onClick={() => handleStrengthSelect(strength)}
                  style={{
                    backgroundColor: formData.strengths.includes(strength)
                      ? '#caaae1'
                      : '#f8f4fb',
                    color: formData.strengths.includes(strength)
                      ? 'white'
                      : '#805ad5'
                  }}
                >
                  {strength}
                </SkillTag>
              ))}
            </SkillContainer>
          </FormSection>

          <FormSection>
            <TechnicalSkillsDropdown />
          </FormSection>

          <FormSection>
            <Label required>External Application URL</Label>
            <Input
              type="url"
              name="external_application_url"
              value={formData.external_application_url}
              onChange={handleInputChange}
              required
              placeholder="https://careers.company.com/job/123"
            />
            <small style={{ color: '#475569' }}>
              Please include the full URL (e.g., https://example.com).
            </small>
          </FormSection>

          <FormSection>
            <Label>Visa Sponsorship</Label>
            <RadioGroup>
              <RadioLabel checked={formData.willing_to_sponsor}>
                <input
                  type="radio"
                  name="willing_to_sponsor"
                  checked={formData.willing_to_sponsor}
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: 'willing_to_sponsor', value: true }
                    })
                  }
                />
                Yes
              </RadioLabel>
              <RadioLabel checked={!formData.willing_to_sponsor}>
                <input
                  type="radio"
                  name="willing_to_sponsor"
                  checked={!formData.willing_to_sponsor}
                  onChange={(e) =>
                    handleInputChange({
                      target: { name: 'willing_to_sponsor', value: false }
                    })
                  }
                />
                No
              </RadioLabel>
            </RadioGroup>
          </FormSection>

          <FormSection>
            <Label>Posting Date</Label>
            <Input
              type="date"
              name="date_posted"
              value={formData.date_posted}
              onChange={handleInputChange}
              min="2024-01-01"
              max="2025-12-31"
            />
            <small style={{ color: '#475569' }}>
              If not specified, the current date will be used.
            </small>
          </FormSection>

          <FormSection>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '1rem'
              }}
            >
              <div>
                <Label>Benefits</Label>
                {companyBenefits.length > 0 && (
                  <CheckboxLabel style={{ marginLeft: '1rem' }}>
                    <input
                      type="checkbox"
                      checked={showCompanyBenefits}
                      onChange={handleToggleCompanyBenefits}
                    />
                    <span>Include Company Benefits</span>
                  </CheckboxLabel>
                )}
              </div>
              <Button type="button" onClick={() => setShowBenefitModal(true)}>
                <Plus size={16} /> Add Job-Specific Benefit
              </Button>
            </div>

            <input
              type="hidden"
              required
              value={formData.benefits.length > 0 ? 'valid' : ''}
            />

            <BenefitsGrid>
              {formData.benefits.map((benefit, index) => {
                const categoryInfo = benefitCategories.find(
                  (cat) => cat.value === benefit.category
                )
                const Icon = categoryInfo?.icon || Plus
                const isCompanyBenefit = companyBenefits.some(
                  (cb) =>
                    cb.category === benefit.category &&
                    cb.description === benefit.description
                )

                return (
                  <BenefitCard
                    key={index}
                    style={{
                      border: isCompanyBenefit
                        ? '2px solid #caaae1'
                        : '1px solid #e2e8f0'
                    }}
                  >
                    {!isCompanyBenefit || !showCompanyBenefits ? (
                      <RemoveButton
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem'
                        }}
                        onClick={() => handleRemoveBenefit(index)}
                      >
                        <X size={16} />
                      </RemoveButton>
                    ) : (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0.5rem',
                          right: '0.5rem',
                          fontSize: '0.75rem',
                          color: '#805ad5',
                          padding: '0.25rem 0.5rem',
                          borderRadius: '4px',
                          background: '#f8f4fb'
                        }}
                      >
                        Company Benefit
                      </div>
                    )}
                    <BenefitIcon>
                      <Icon size={24} />
                    </BenefitIcon>
                    <h3 style={{ marginBottom: '0.5rem' }}>
                      {categoryInfo?.label}
                    </h3>
                    <p style={{ fontSize: '14px', color: '#666' }}>
                      {benefit.description}
                    </p>
                  </BenefitCard>
                )
              })}
            </BenefitsGrid>
          </FormSection>

          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Posting...' : 'Post Job'}
          </SubmitButton>
        </FormCard>
        {showBenefitModal && (
          <>
            <Modal>
              <h2
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  marginBottom: '1rem'
                }}
              >
                Add Benefit
              </h2>

              <FormSection>
                <Label>Benefit Category</Label>
                <Select
                  value={newBenefit.category}
                  onChange={(e) =>
                    setNewBenefit((prev) => ({
                      ...prev,
                      category: e.target.value
                    }))
                  }
                >
                  <option value="">Select a category</option>
                  {benefitCategories.map((category) => (
                    <option key={category.value} value={category.value}>
                      {category.label}
                    </option>
                  ))}
                </Select>
              </FormSection>

              <FormSection>
                <Label>Description</Label>
                <Textarea
                  value={newBenefit.description}
                  onChange={(e) =>
                    setNewBenefit((prev) => ({
                      ...prev,
                      description: e.target.value
                    }))
                  }
                  placeholder="Enter benefit description"
                />
              </FormSection>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '1rem'
                }}
              >
                <Button
                  type="button"
                  onClick={() => setShowBenefitModal(false)}
                  style={{ backgroundColor: '#e2e8f0', color: '#4a5568' }}
                >
                  Cancel
                </Button>
                <Button type="button" onClick={handleAddBenefit}>
                  Add Benefit
                </Button>
              </div>
            </Modal>
            <ModalOverlay onClick={() => setShowBenefitModal(false)} />
          </>
        )}
      </MainContent>
    </Container>
  )
}

export default AdminJobPostForm
