const MatchAdzuna = (job, jobSeekerProfile) => {
  if (!jobSeekerProfile || !job.description) {
    console.warn('Missing job seeker profile or job description:', {
      job,
      jobSeekerProfile
    })
    return { totalScore: 0, breakdown: {}, details: [] }
  }

  // 🔍 Normalize Job Seeker Profile fields
  const seekerSkills = new Set(
    (jobSeekerProfile.technical_skills || []).map((skill) =>
      skill.toLowerCase().trim()
    )
  )
  const seekerExperience = new Set(
    (jobSeekerProfile.work_history || []).map((job) =>
      (job.title + ' ' + job.employer).toLowerCase().trim()
    )
  )
  const seekerEducation = jobSeekerProfile.highest_education
    ? jobSeekerProfile.highest_education.toLowerCase().trim()
    : ''
  const seekerIndustry = new Set(
    (jobSeekerProfile.industries || []).map((industry) =>
      industry.toLowerCase().trim()
    )
  )

  // 🔍 Extract job title words to match against experience
  const jobTitle = job.title.toLowerCase()
  const jobTitleWords = new Set(
    jobTitle.split(/\W+/).filter((word) => word.length > 2)
  )

  // 🔎 Extract sections from job description
  const jobDescriptionText = job.description.toLowerCase()
  const responsibilities = extractSection(
    jobDescriptionText,
    'responsibilities'
  )
  const skills = extractSection(jobDescriptionText, 'skills')
  const experience = extractSection(jobDescriptionText, 'experience')
  const industry = job.category?.label
    ? job.category.label.toLowerCase().trim()
    : ''

  // 🔍 Tokenize job description & remove common words
  const jobWords = new Set(filterCommonWords(jobDescriptionText.split(/\W+/)))
  const responsibilitiesWords = new Set(
    filterCommonWords(responsibilities.split(/\W+/))
  )
  const skillsWords = new Set(filterCommonWords(skills.split(/\W+/)))
  const experienceWords = new Set(filterCommonWords(experience.split(/\W+/)))

  // ✅ **Skill Matching** (35%)
  const matchedSkills = [...seekerSkills].filter(
    (skill) => skillsWords.has(skill) || jobWords.has(skill)
  )
  const skillMatchScore =
    matchedSkills.length > 0
      ? (matchedSkills.length / seekerSkills.size) * 100
      : 0

  // ✅ **Experience Matching** (25%)
  const matchedExperience = [...seekerExperience].filter(
    (exp) => experienceWords.has(exp) || responsibilitiesWords.has(exp)
  )
  const experienceMatchScore =
    matchedExperience.length > 0
      ? (matchedExperience.length / seekerExperience.size) * 100
      : 0

  // ✅ **Industry Matching** (10%)
  const industryMatch = seekerIndustry.has(industry) ? 100 : 0

  // ✅ **Education Matching** (5%)
  const educationMatch =
    seekerEducation && jobWords.has(seekerEducation) ? 100 : 0

  // ✅ **General Keyword Matching** (10%)
  const intersection = [...seekerSkills, ...seekerExperience].filter(
    (word) => jobWords.has(word) || responsibilitiesWords.has(word)
  )
  const descriptionMatchScore =
    intersection.length > 0 ? (intersection.length / jobWords.size) * 100 : 0

  // ✅ **Title Match Bonus for Senior Roles** (15%)
  let titleMatchBonus = 0
  const seniorTitles = [
    'cto',
    'vp',
    'chief',
    'head',
    'principal',
    'director',
    'lead'
  ]
  if (seniorTitles.some((title) => jobTitle.includes(title))) {
    titleMatchBonus = 15
  }

  // ✅ **Current Level Bonus if you're at a senior level** (10%)
  let levelBonus = 0
  if (
    ['cto', 'vp', 'chief', 'director', 'lead'].includes(
      jobSeekerProfile.current_level?.toLowerCase()
    )
  ) {
    levelBonus = 10
  }

  // 🔥 **Raw Match Score Calculation**
  let rawScore =
    skillMatchScore * 0.35 +
    experienceMatchScore * 0.25 +
    industryMatch * 0.1 +
    educationMatch * 0.05 +
    descriptionMatchScore * 0.1 +
    titleMatchBonus +
    levelBonus

  // 📌 **Force Whole Numbers & Range Between 35-90% for Senior Roles**
  let scaledScore = Math.round(scaleScore(rawScore, 35, 90))

  // 🎲 **Randomly Add 5-15% Boost to Some Jobs**
  if (Math.random() > 0.4) {
    // 40% chance to apply boost
    const boost = Math.floor(Math.random() * (15 - 5 + 1)) + 5 // Random between 5-15
    scaledScore = Math.min(scaledScore + boost, 90) // Ensure max is 90
  }

  console.log('🔍 Adzuna Match Debug:', {
    jobTitle: job.title,
    rawScore,
    scaledScore,
    matchedSkills,
    matchedExperience,
    industryMatch,
    educationMatch,
    titleMatchBonus,
    levelBonus,
    descriptionMatchScore
  })

  return {
    totalScore: scaledScore,
    breakdown: {
      skillMatch: Math.round(skillMatchScore),
      experienceMatch: Math.round(experienceMatchScore),
      industryMatch: industryMatch,
      educationMatch: educationMatch,
      descriptionMatch: Math.round(descriptionMatchScore),
      titleMatchBonus: titleMatchBonus,
      levelBonus: levelBonus
    },
    details: [
      `Skills match: ${matchedSkills.length} matches (${Math.round(
        skillMatchScore
      )}%)`,
      `Experience match: ${matchedExperience.length} matches (${Math.round(
        experienceMatchScore
      )}%)`,
      `Industry match: ${industryMatch}%`,
      `Education match: ${educationMatch}%`,
      `Title match bonus: ${titleMatchBonus}%`,
      `Current level bonus: ${levelBonus}%`,
      `Overall keyword similarity: ${Math.round(descriptionMatchScore)}%`
    ]
  }
}

/**
 * 🛠 Extracts key sections from job descriptions like "Responsibilities" and "Skills"
 */
const extractSection = (text, keyword) => {
  const regex = new RegExp(`(?:${keyword})[:]?\\s*(.*?)\\s*(?:\\n|$)`, 'i')
  const match = text.match(regex)
  return match ? match[1] : ''
}

/**
 * 🚫 Filters out common filler words (e.g., "and", "the", "with") to improve matching accuracy
 */
const filterCommonWords = (words) => {
  const commonWords = new Set([
    'the',
    'and',
    'with',
    'for',
    'from',
    'you',
    'your',
    'this',
    'that',
    'have',
    'has',
    'to',
    'in',
    'on',
    'of',
    'a',
    'an',
    'or',
    'by'
  ])
  return words.filter((word) => word.length > 2 && !commonWords.has(word))
}

/**
 * 🎯 **Scale scores dynamically within a fixed range**
 */
const scaleScore = (score, min, max) => {
  score = Math.round(score)
  if (score < min) return min + Math.floor(Math.random() * 10)
  if (score > max) return max - Math.floor(Math.random() * 10)
  return score
}

export default MatchAdzuna
