import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import {
  Search,
  Save,
  ChevronDown,
  ChevronUp,
  ArrowUpDown,
  X,
  User,
  Heart,
  Plus
} from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import EmployerNav from './EmployerNav'
import { getAllJobSeekerProfiles } from '../../models/JobSeekerProfile'
import { auth } from '../../firebase'
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc
} from 'firebase/firestore'
import { db } from '../../firebase'
import { getJobSeekers2 } from '../../models/JobSeekerProfile'
import Joyride, { STATUS } from 'react-joyride'
import algoliasearch from 'algoliasearch'

// Constants
const languageOptions = [
  'English',
  'Spanish',
  'Mandarin Chinese',
  'French',
  'German',
  'Japanese',
  'Russian',
  'Portuguese',
  'Hebrew',
  'Arabic',
  'Hindi',
  'Italian',
  'Korean',
  'Dutch',
  'Turkish',
  'Swedish'
].sort()

const strengths = [
  'Communication',
  'Leadership',
  'Problem Solving',
  'Teamwork',
  'Adaptability',
  'Creativity',
  'Time Management',
  'Critical Thinking',
  'Attention to Detail',
  'Emotional Intelligence',
  'Strategic Planning',
  'Networking',
  'Decision Making',
  'Conflict Resolution',
  'Analytical Skills',
  'Project Management',
  'Negotiation',
  'Public Speaking',
  'Customer Service',
  'Technical Proficiency',
  'Data Analysis',
  'Research',
  'Writing',
  'Multitasking',
  'Sales',
  'Marketing',
  'Budgeting',
  'Coaching',
  'Innovation',
  'Interpersonal Skills'
].sort()

const athleteStatusOptions = [
  'Current Collegiate Athlete',
  'Former Collegiate Athlete',
  'Current Professional Athlete',
  'Former Professional Athlete'
]

const raceOptions = [
  'Asian',
  'Black',
  'Asian',
  'Hispanic',
  'Middle Eastern/Arab',
  'Native American',
  'Pacific Islander',
  'White'
]

const genderOptions = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender Male',
  'Transgender Female'
]

const veteranStatusOptions = ['Veteran', 'Active Duty', 'Reservist']

const industries = ['Sports', 'Media', 'Entertainment', 'Fashion', 'Technology']

const allSubcategories = {
  Sports: [
    'Professional Sports',
    'College Sports',
    'Esports',
    'Sports Technology',
    'Sports Marketing',
    'Sports Management',
    'Sports Medicine',
    'Fitness',
    'Outdoor Sports',
    'Recreational Sports',
    'Sports Analytics',
    'Youth Sports',
    'Athlete Representation',
    'Stadium Operations',
    'Event Management',
    'Sports Sponsorships',
    'Sports Broadcasting',
    'Fan Engagement',
    'Team Operations'
  ],
  Media: [
    'Broadcast Media',
    'Digital Media',
    'Publishing',
    'Advertising',
    'Journalism',
    'Social Media',
    'Streaming Services',
    'Podcasting',
    'Film Journalism',
    'Investigative Reporting',
    'Public Relations',
    'Content Creation',
    'Media Buying',
    'Media Planning',
    'News Production',
    'Multimedia Journalism',
    'Interactive Media',
    'Community Management',
    'Influencer Marketing'
  ],
  Entertainment: [
    'Film Production',
    'Music',
    'Gaming',
    'Live Events',
    'Theater',
    'Animation',
    'Virtual Reality',
    'Theme Parks',
    'Television Production',
    'Documentary Filmmaking',
    'Concert Promotion',
    'Talent Management',
    'Celebrity Management',
    'Film Distribution',
    'Cinematography',
    'Sound Design',
    'Set Design',
    'Voice Acting',
    'Content Distribution',
    'Entertainment Law',
    'Reality TV',
    'Music Production',
    'Film Scoring',
    'Comedy'
  ],
  Fashion: [
    'Luxury Fashion',
    'Streetwear',
    'Sportswear',
    'Fashion Technology',
    'Sustainable Fashion',
    'Accessories',
    'Cosmetics',
    'Fashion Media',
    'Apparel Design',
    'Textile Design',
    'Retail Fashion',
    'Fashion Merchandising',
    'Fashion Photography',
    'Fashion Blogging',
    'Runway Shows',
    'Fashion Buying',
    'E-commerce Fashion',
    'Fashion Consulting',
    'Pattern Making',
    'Fashion Illustration',
    'Costume Design',
    'Footwear Design',
    'Fashion PR',
    'Jewelry Design'
  ],
  Technology: ['Media Tech', 'Entertainment Tech', 'Fashion Tech']
}

// Styled Components

const SearchBar = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const Button = styled.button`
  background-color: #caaae1;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;

  &:hover {
    background-color: #0060df;
  }
`
const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f7fafc;
  }

  input[type='checkbox'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`
const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 2rem;
`

const Checkbox = styled.input`
  margin-right: 0.5rem;
`

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`

const JobSeekerList = styled.div`
  display: grid;
  gap: 1rem;
`

const NavWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const SortButton = styled(Button)`
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: -40px;

  &:hover {
    background-color: #f0f0f0;
  }
`

const SortContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 4rem;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  margin-left: 0.5rem;
  color: #666;

  &:hover {
    color: #333;
  }
`

const MatchPercentage = styled.div`
  color: ${(props) => props.color};
  font-size: 0.9rem;
  font-weight: bold;
  position: relative;
  cursor: help;

  &:hover > div {
    display: block;
  }
`

const TooltipContent = styled.div`
  display: none;
  position: absolute;
  bottom: 100%;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 200px;
  z-index: 1000;
`
const StrengthsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 0.5rem;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #caaae1;
    border-radius: 4px;
  }
`

const FilterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 0.5rem;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #caaae1;
    border-radius: 4px;
  }
`

const CategoryHeader = styled.div`
  font-weight: 600;
  color: #333;
  padding: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  grid-column: 1 / -1;
  border-bottom: 1px solid #eee;
`

const FavoriteFilterWrapper = styled.div`
  background-color: #f8f9fa;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  border: 1px solid #e9ecef;
`

const FavoriteLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  color: #333;

  &:hover {
    color: #000;
  }
`
const FavoriteCount = styled.span`
  background-color: #caaae1;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-size: 0.8rem;
  margin-left: auto;
`
const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 16px;
  align-items: center;
`

const PaginationButton = styled.button`
  padding: 0.5rem 1rem;
  border: 1px solid #caaae1;
  background-color: white;
  color: #caaae1;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover:not(:disabled) {
    background-color: #b794d4;
    color: white;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const PageIndicator = styled.div`
  font-size: 1rem;
  color: #333;
`

const Sidebar = styled.div`
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: fit-content;
  position: sticky;
  top: 2rem;
`

const FilterHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e2e8f0;
`

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0.25rem 0;
  font-size: 0.875rem;
  color: #4a5568;
  cursor: pointer;
  border-radius: 0.375rem;
  transition: all 0.2s ease;

  &:hover {
    background-color: #f7f7f7;
  }

  input[type='radio'] {
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    accent-color: #caaae1;
  }
`

const FilterCount = styled.span`
  color: #718096;
  font-size: 0.75rem;
  margin-left: auto;
  background-color: #f7fafc;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
`

const ClearButton = styled.button`
  color: #caaae1;
  font-size: 0.875rem;
  padding: 0.375rem 0.75rem;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 0.375rem;

  &:hover {
    background-color: #f7f7f7;
  }
`

const SearchInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
`

const FilterCheckboxLabel = styled(CheckboxLabel)`
  font-size: 0.9rem;
  padding: 0.25rem;

  &:hover {
    background-color: #f8f9fa;
    border-radius: 4px;
  }
`

const StrengthCheckboxLabel = styled(CheckboxLabel)`
  font-size: 0.9rem;
  padding: 0.25rem;

  &:hover {
    background-color: #f8f9fa;
    border-radius: 4px;
  }
`
const JobSeekerCard = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  background-color: white;
  margin-bottom: 1rem;

  &:hover {
    background-color: #f8fafc;
  }
`

const ProfileImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
`

const ProfileImageFallback = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #f1f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  color: #64748b;
`
const PulseAnimation = styled.div`
  animation: pulse 2s infinite ease-in-out;

  @keyframes pulse {
    0% {
      background-color: #f1f5f9;
    }
    50% {
      background-color: #f7fafc;
    }
    100% {
      background-color: #f1f5f9;
    }
  }
`

const JobSeekerInfo = styled.div`
  flex-grow: 1;
`

const JobSeekerNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
`

const JobSeekerName = styled.h3`
  font-size: 1rem;
  font-weight: 600;
  color: #1a202c;
  margin: 0;
`

const Pronouns = styled.span`
  font-size: 0.875rem;
  color: #64748b;
`

const JobSeekerDetails = styled.p`
  font-size: 0.875rem;
  color: #4a5568;
  margin: 0;
  margin-bottom: 0.5rem;
  line-height: 1.5;
`

const TagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
`

const Tag = styled.span`
  background-color: #e9d8f8;
  color: #805ad5;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.75rem;
  font-weight: 500;
`
const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: #f8f9fa;
  font-family: 'Open Sans', sans-serif;
`

const MainContent = styled.main`
  flex: 1;
  padding: 2rem 3rem;
  overflow-y: auto;
`

const Header = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  gap: 3rem;
`

const FiltersColumn = styled.div`
  background: white;
  padding: 1.5rem;
  border-radius: 0.75rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: fit-content;
`

const JobSeekersColumn = styled.div`
  min-width: 0; // Prevents overflow
`

const JobSeekersHeader = styled.h2`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
`

const HyperLink = styled.a`
  color: #0066cc;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

const FilterContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '300px' : '0')};
  overflow-y: ${(props) => (props.isOpen ? 'auto' : 'hidden')};
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  margin-left: 0.5rem;

  // Remove scrolling from nested demographic content
  ${(props) =>
    props.isDemographicNested &&
    `
    max-height: none;
    overflow-y: visible;
  `}
`

const FilterSection = styled.div`
  margin-bottom: 0.5rem;
  background-color: white;
  border-radius: 0.5rem;
`

const FilterTitle = styled.div`
  font-weight: 600;
  font-size: 1rem;
  color: #1a202c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease;

  &:hover {
    color: #caaae1;
  }

  svg {
    transition: transform 0.2s ease;
  }
`

const NestedFilterContent = styled.div`
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;

  ${FilterTitle} {
    font-size: 0.9rem;
    padding: 0.5rem 0;
    font-weight: 400;
  }
`

const FilterSearchInput = styled(SearchInput)`
  width: 80%;
  margin: 0.5rem 0;
  padding: 0.5rem;
  font-size: 0.9rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 1px rgba(202, 170, 225, 0.2);
  }
`

const candidateSourcingToolTip = {
  free: (
    <>
      This tool allows you to explore all talent on Arena and identify top
      matches for your roles. With the Free plan, you’re limited to 1 search and
      5 candidates per search.{' '}
      <HyperLink
        href="https://meetings.hubspot.com/parul-khosla/upgrade?uuid=69174701-3fd7-4757-8185-e31ba2179763"
        target="_blank"
        rel="noopener noreferrer"
      >
        Upgrade
      </HyperLink>{' '}
      for unlimited access to the sourcing tool.
    </>
  ),
  premium: (
    <>
      This tool allows you to explore all talent on Arena and identify top
      matches for your roles. With the Premium plan, you’re limited to 1 search
      and 5 candidates per search.{' '}
      <HyperLink
        href="https://meetings.hubspot.com/parul-khosla/upgrade?uuid=69174701-3fd7-4757-8185-e31ba2179763"
        target="_blank"
        rel="noopener noreferrer"
      >
        Upgrade
      </HyperLink>{' '}
      for unlimited access to the sourcing tool.
    </>
  ),
  enterprise: (
    <>
      Explore all talent on Arena and identify top matches for your roles. Use
      industry-specific filters or keyword search to find the right talent
      quickly.
    </>
  )
}

export default function CandidateSourcing() {
  // TUTORIALS-- get companies package plan

  const [employerPackage, setEmployerPackage] = useState('free')
  const [run, setRun] = useState(false)

  const steps = useMemo(
    () => [
      {
        target: 'body',
        content: (
          <div>
            <strong>This is the candidate sourcing tool. </strong>{' '}
            <>
              This tool allows you to explore all talent on Arena and identify
              top matches for your roles.<br></br>
              <HyperLink
                href="https://meetings.hubspot.com/parul-khosla/upgrade?uuid=69174701-3fd7-4757-8185-e31ba2179763"
                target="_blank"
                rel="noopener noreferrer"
              >
                Upgrade
              </HyperLink>{' '}
              for unlimited access to the sourcing tool.
            </>
            <br />
          </div>
        ),
        placement: 'center'
      },
      {
        target: '#top-jobseeker-card',
        content: <>Click on a candidate to view their full profile.</>,
        placement: 'top' // Adjust placement as needed
      }
    ],
    []
  )

  const navigate = useNavigate()

  // Core state
  const [jobSeekers, setJobSeekers] = useState([])
  const [filteredJobSeekers, setFilteredJobSeekers] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  // Pagination state

  const [lastVisible, setLastVisible] = useState(null)
  const [hasMore, setHasMore] = useState(true)

  // Search and filter state
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedLocations, setSelectedLocations] = useState([])
  const [selectedCurrentEmployers, setSelectedCurrentEmployers] = useState([])
  const [selectedCurrentTitles, setSelectedCurrentTitles] = useState([])
  const [selectedWorkExperience, setSelectedWorkExperience] = useState([])
  const [selectedCurrentLevel, setSelectedCurrentLevel] = useState([])
  const [selectedHighestDegree, setSelectedHighestDegree] = useState([])
  const [selectedMajors, setSelectedMajors] = useState([])
  const [selectedUniversities, setSelectedUniversities] = useState([])
  const [selectedHBCU, setSelectedHBCU] = useState(false)
  const [selectedStrengths, setSelectedStrengths] = useState([])
  const [selectedWillingToRelocate, setSelectedWillingToRelocate] =
    useState(null)
  const [selectedGenders, setSelectedGenders] = useState([])
  const [selectedRaces, setSelectedRaces] = useState([])
  const [selectedStartupExperience, setSelectedStartupExperience] = useState([])
  const [selectedLanguages, setSelectedLanguages] = useState([])
  const [selectedAthleteStatus, setSelectedAthleteStatus] = useState([])
  const [selectedVeteranStatus, setSelectedVeteranStatus] = useState([])
  const [selectedIndustries, setSelectedIndustries] = useState([])
  const [selectedSubcategories, setSelectedSubcategories] = useState([])

  // UI state
  const [sortOrder, setSortOrder] = useState('desc')
  const [openFilters, setOpenFilters] = useState({})
  const [openDemographicFilters, setOpenDemographicFilters] = useState({})
  const [locationSearch, setLocationSearch] = useState('')
  const [currentEmployerSearch, setCurrentEmployerSearch] = useState('')
  const [currentTitleSearch, setCurrentTitleSearch] = useState('')
  const [universitySearch, setUniversitySearch] = useState('')
  const [strengthSearch, setStrengthSearch] = useState('')
  const [industrySearch, setIndustrySearch] = useState('')
  const [subcategorySearch, setSubcategorySearch] = useState('')

  // Company-related state
  const [currentUserEmployerId, setCurrentUserEmployerId] = useState(null)
  const [currentUserCompanyId, setCurrentUserCompanyId] = useState(null)
  const [companyEmployerIds, setCompanyEmployerIds] = useState([])
  const [showFavoritedOnly, setShowFavoritedOnly] = useState(false)
  const [favoritedCount, setFavoritedCount] = useState(0)

  // Collection state
  const [favorites, setFavorites] = useState(new Set())
  const [pipeline, setPipeline] = useState(new Set())
  const [totalFetched, setTotalFetched] = useState(0)
  const [totalJobSeekers, setTotalJobSeekers] = useState(0)
  const [loadedCount, setLoadedCount] = useState(0)
  const [completedProfilesCount, setCompletedProfilesCount] = useState(0)
  const [page, setPage] = useState(0) // Track the current page
  const [loadingMore, setLoadingMore] = useState(false) // Track loading state
  const [initialLoad, setInitialLoad] = useState(true) // Track if first load is done
  const [allJobSeekers, setAllJobSeekers] = useState([]) // Store all job seekers
  const [currentPage, setCurrentPage] = useState(1) // Track pagination
  const jobSeekersPerPage = 25 // Number of job seekers per page
  const totalPages = Math.ceil(filteredJobSeekers.length / jobSeekersPerPage)

  const searchClient = algoliasearch(
    'BUH4VNJAEB',
    'e6fe967de79b75486e3aad1e3222b564'
  )
  const searchIndex = searchClient.initIndex('jobseekers')

  const loadAllJobSeekers = async () => {
    try {
      setLoading(true)
      let allResults = []
      let page = 0
      let totalRecords = 0
      let hitsPerPage = 1000 // Max allowed per request
      const maxRecords = 4000 // Limit to 4,000

      do {
        const { hits, nbHits } = await searchIndex.search('', {
          hitsPerPage,
          page
        })

        allResults = [...allResults, ...hits] // Collect results
        totalRecords = nbHits
        page++

        console.log(`Fetched ${hits.length} more job seekers...`)
      } while (allResults.length < Math.min(totalRecords, maxRecords))

      console.log(`Total fetched before filtering: ${allResults.length}`)

      // ✅ Only keep job seekers where intake_completed = true
      const filteredResults = allResults.filter(
        (js) => js.intake_completed === true
      )

      console.log(`Total after filtering: ${filteredResults.length}`)

      setAllJobSeekers(filteredResults)
      setFilteredJobSeekers(filteredResults)
    } catch (err) {
      console.error('Error fetching job seekers:', err)
    } finally {
      setLoading(false)
    }
  }

  // Load job seekers on mount
  useEffect(() => {
    loadAllJobSeekers()
  }, [])

  useEffect(() => {
    const checkTutorialStatus = async () => {
      const currentUser = auth.currentUser
      if (!currentUser) return

      try {
        // Get reference to the employer profile document
        const profileRef = doc(
          db,
          'users',
          currentUser.uid,
          'profiles',
          'employer'
        )
        const profileSnap = await getDoc(profileRef)

        if (profileSnap.exists()) {
          const profileData = profileSnap.data()

          // Save employer package type
          setEmployerPackage(profileData.plan_type || 'free')

          // Start the tour if tutorial hasn't been completed
          const shouldShowTutorial = !profileData.sourcing_tutorial_completed
          if (shouldShowTutorial) {
            // Small delay to ensure UI is ready
            setTimeout(() => setRun(true), 500)
          }
        } else {
          // If no profile exists, show the tutorial
          setRun(true)
        }
      } catch (error) {
        console.error('Error checking tutorial status:', error)
      }
    }

    checkTutorialStatus()
  }, [])

  const handleJoyrideCallback = async (data) => {
    const { status } = data
    const currentUser = auth.currentUser

    if ((status === 'finished' || status === 'skipped') && currentUser) {
      setRun(false)

      try {
        // Get reference to the employer profile document
        const profileRef = doc(
          db,
          'users',
          currentUser.uid,
          'profiles',
          'employer'
        )

        // Update the sourcing_tutorial_completed field
        await updateDoc(profileRef, {
          sourcing_tutorial_completed: true,
          updated_at: new Date()
        })
      } catch (error) {
        console.error('Error updating tutorial completion status:', error)
      }
    }
  }
  const filterJobSeekers = useCallback(() => {
    if (!allJobSeekers?.length) return

    console.log('Starting filtering with current filters:', {
      totalJobSeekers: allJobSeekers.length,
      searchTerm,
      selectedLocations,
      selectedCurrentTitles,
      selectedCurrentLevel,
      selectedCurrentEmployers,
      selectedWorkExperience,
      selectedHighestDegree,
      selectedUniversities,
      selectedMajors,
      selectedHBCU,
      selectedStrengths,
      selectedLanguages,
      selectedIndustries,
      selectedSubcategories,
      selectedGenders,
      selectedRaces,
      selectedVeteranStatus
    })

    const filtered = allJobSeekers.filter((jobSeeker) => {
      // Debug logging for education
      if (selectedHighestDegree.length > 0) {
        console.log('Checking education for jobSeeker:', {
          id: jobSeeker.objectID,
          highest_education: jobSeeker.highest_education,
          selectedDegrees: selectedHighestDegree
        })
      }

      // Search filter
      if (searchTerm) {
        const searchTermLower = searchTerm.toLowerCase()
        const searchableFields = [
          jobSeeker.first_name,
          jobSeeker.last_name,
          jobSeeker.email,
          jobSeeker.current_title,
          jobSeeker.current_employer,
          jobSeeker.location,
          jobSeeker.current_level,
          jobSeeker.years_experience,
          ...(jobSeeker.education
            ?.map((edu) => [edu.university, edu.degree, edu.major])
            .flat() || []),
          ...(jobSeeker.top_strengths || []),
          ...(jobSeeker.languages || [])
        ]
          .filter(Boolean)
          .join(' ')
          .toLowerCase()

        if (!searchableFields.includes(searchTermLower)) {
          return false
        }
      }

      // Location filter
      if (selectedLocations.length > 0) {
        if (
          !jobSeeker.location ||
          !selectedLocations.includes(jobSeeker.location)
        ) {
          return false
        }
      }

      // Current title filter
      if (selectedCurrentTitles.length > 0) {
        if (
          !jobSeeker.current_title ||
          !selectedCurrentTitles.includes(jobSeeker.current_title)
        ) {
          return false
        }
      }

      // Current level filter
      if (selectedCurrentLevel.length > 0) {
        if (
          !jobSeeker.current_level ||
          !selectedCurrentLevel.includes(jobSeeker.current_level)
        ) {
          return false
        }
      }

      // Current employer filter
      if (selectedCurrentEmployers.length > 0) {
        if (
          !jobSeeker.current_employer ||
          !selectedCurrentEmployers.includes(jobSeeker.current_employer)
        ) {
          return false
        }
      }

      // Work experience filter
      if (selectedWorkExperience.length > 0) {
        if (!jobSeeker.years_experience) return false

        const experience = jobSeeker.years_experience
        const normalizedExperience =
          experience === 'None/Student' ? 'Student' : experience

        if (
          !selectedWorkExperience.includes(normalizedExperience) &&
          !(
            experience === 'None/Student' &&
            selectedWorkExperience.includes('None/Student')
          )
        ) {
          return false
        }
      }

      // Education filters
      if (
        selectedHighestDegree.length > 0 ||
        selectedUniversities.length > 0 ||
        selectedMajors.length > 0 ||
        selectedHBCU
      ) {
        // First check highest education independently
        if (selectedHighestDegree.length > 0) {
          if (
            !jobSeeker.highest_education ||
            !selectedHighestDegree.includes(jobSeeker.highest_education)
          ) {
            console.log('Failed highest education check:', {
              hasField: !!jobSeeker.highest_education,
              value: jobSeeker.highest_education,
              selected: selectedHighestDegree
            })
            return false
          }
        }

        // Then check other education criteria only if they exist
        if (
          selectedUniversities.length > 0 ||
          selectedMajors.length > 0 ||
          selectedHBCU
        ) {
          if (
            !jobSeeker.education ||
            !Array.isArray(jobSeeker.education) ||
            jobSeeker.education.length === 0
          ) {
            return false
          }

          // Check universities
          if (selectedUniversities.length > 0) {
            const hasMatchingUniversity = jobSeeker.education.some((edu) =>
              selectedUniversities.includes(edu.university)
            )
            if (!hasMatchingUniversity) return false
          }

          // Check majors
          if (selectedMajors.length > 0) {
            const hasMatchingMajor = jobSeeker.education.some((edu) =>
              selectedMajors.includes(edu.major)
            )
            if (!hasMatchingMajor) return false
          }

          // Check HBCU
          if (selectedHBCU && !jobSeeker.education.some((edu) => edu.is_hbcu)) {
            return false
          }
        }
      }

      // Strengths filter
      if (selectedStrengths.length > 0) {
        if (
          !jobSeeker.top_strengths ||
          !selectedStrengths.some((strength) =>
            jobSeeker.top_strengths.includes(strength)
          )
        ) {
          return false
        }
      }

      // Languages filter
      if (selectedLanguages.length > 0) {
        if (
          !jobSeeker.languages ||
          !selectedLanguages.some((lang) => jobSeeker.languages.includes(lang))
        ) {
          return false
        }
      }

      // Industry and subcategories filter
      if (selectedIndustries.length > 0 || selectedSubcategories.length > 0) {
        // Industry check
        if (selectedIndustries.length > 0) {
          const hasMatchingIndustry = selectedIndustries.some((industry) =>
            jobSeeker.industries?.includes(industry)
          )
          if (!hasMatchingIndustry) return false
        }

        // Subcategory check
        if (selectedSubcategories.length > 0) {
          const hasMatchingSubcategory = selectedSubcategories.some((subcat) =>
            jobSeeker.subcategories?.includes(subcat)
          )
          if (!hasMatchingSubcategory) return false
        }
      }

      // Demographics filters
      if (
        selectedGenders.length > 0 ||
        selectedRaces.length > 0 ||
        selectedVeteranStatus.length > 0
      ) {
        // Gender check
        if (selectedGenders.length > 0) {
          if (
            !jobSeeker.gender ||
            !selectedGenders.includes(jobSeeker.gender)
          ) {
            return false
          }
        }

        // Race check
        if (selectedRaces.length > 0) {
          if (!jobSeeker.race || !selectedRaces.includes(jobSeeker.race)) {
            return false
          }
        }

        // Veteran status check
        if (selectedVeteranStatus.length > 0) {
          if (
            !jobSeeker.veteran_status ||
            !selectedVeteranStatus.includes(jobSeeker.veteran_status)
          ) {
            return false
          }
        }
      }

      // If all filters pass, include the job seeker
      return true
    })

    console.log('Filtering complete:', {
      totalBefore: allJobSeekers.length,
      totalAfter: filtered.length,
      difference: allJobSeekers.length - filtered.length
    })

    setFilteredJobSeekers(filtered)
    setCurrentPage(1) // Reset to first page when filters change
    updateFavoritedCount(filtered)
  }, [
    allJobSeekers,
    searchTerm,
    selectedLocations,
    selectedCurrentTitles,
    selectedCurrentLevel,
    selectedCurrentEmployers,
    selectedWorkExperience,
    selectedHighestDegree,
    selectedUniversities,
    selectedMajors,
    selectedHBCU,
    selectedStrengths,
    selectedLanguages,
    selectedIndustries,
    selectedSubcategories,
    selectedGenders,
    selectedRaces,
    selectedVeteranStatus
  ])
  useEffect(() => {
    console.log('🔍 Updating filteredJobSeekers:', filteredJobSeekers)
  }, [filteredJobSeekers])

  useEffect(() => {
    if (allJobSeekers.length > 0) {
      filterJobSeekers()
    }
  }, [
    allJobSeekers,
    searchTerm,
    selectedLocations,
    selectedCurrentTitles,
    selectedCurrentLevel,
    selectedCurrentEmployers,
    selectedWorkExperience,
    selectedHighestDegree,
    selectedUniversities,
    selectedMajors,
    selectedHBCU,
    selectedStrengths,
    selectedLanguages,
    selectedAthleteStatus,
    selectedGenders,
    selectedRaces,
    selectedVeteranStatus,
    selectedIndustries,
    selectedSubcategories,
    filterJobSeekers
  ])

  // Utility functions
  const processJobSeekerData = (user, profile) => ({
    id: user.id,
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    email: user.email || '',
    created_at: user.created_at,
    has_profile: !!profile,
    pronouns: profile.pronouns ?? null,
    location:
      profile.city && profile.state
        ? `${profile.city}, ${profile.state}`
        : null,
    current_employer: profile.current_employer ?? null,
    current_title: profile.current_title ?? null,
    work_experience: profile.years_experience ?? null,
    willing_to_relocate:
      profile.open_to_work?.includes('willing_to_relocate') ?? false,
    startup_experience: profile.startup_experience ?? false,
    languages: profile.languages ?? [],
    strengths: profile.top_strengths ?? [],
    athlete_status: profile.athlete_status ?? null,
    favorited_companies: profile.favorited_companies ?? [],
    education:
      profile.education?.map((edu) => ({
        university: edu.institution || edu.university,
        degree: edu.degree_type || edu.degree,
        major: edu.field_of_study || edu.major,
        is_hbcu: edu.is_hbcu || false
      })) ?? [],
    rating: Math.random() * 100 // Replace with actual rating logic
  })

  const sortJobSeekers = (jobSeekers) => {
    return [...jobSeekers].sort((a, b) => {
      if (!a.has_profile && b.has_profile) return 1
      if (a.has_profile && !b.has_profile) return -1
      return sortOrder === 'asc' ? a.rating - b.rating : b.rating - a.rating
    })
  }

  const updateFavoritedCount = (filtered) => {
    setFavoritedCount(
      filtered.filter((js) =>
        js.favorites?.some((id) => companyEmployerIds.includes(id))
      ).length
    )
  }

  const handleFilterChange = (filterName, value, isArray = true) => {
    console.log(`Updating ${filterName} filter:`, { value, isArray })

    switch (filterName) {
      case 'locations':
        setSelectedLocations(value)
        break
      case 'currentTitles':
        setSelectedCurrentTitles(value)
        break
      case 'currentLevel':
        setSelectedCurrentLevel(value)
        break
      case 'currentEmployers':
        setSelectedCurrentEmployers(value)
        break
      case 'workExperience':
        setSelectedWorkExperience(value)
        break
      case 'highestDegree':
        setSelectedHighestDegree(value)
        break
      case 'universities':
        setSelectedUniversities(value)
        break
      case 'majors':
        setSelectedMajors(value)
        break
      case 'hbcu':
        setSelectedHBCU(value)
        break
      case 'strengths':
        setSelectedStrengths(value)
        break
      case 'languages':
        setSelectedLanguages(value)
        break
      case 'athleteStatus':
        setSelectedAthleteStatus(value)
        break
      case 'genders':
        setSelectedGenders(value)
        break
      case 'races':
        setSelectedRaces(value)
        break
      case 'veteranStatus':
        setSelectedVeteranStatus(value)
        break
      case 'industries':
        setSelectedIndustries(value)
        if (value.length === 0) {
          setSelectedSubcategories([])
        }
        break
      case 'subcategories':
        setSelectedSubcategories(value)
        break
      default:
        console.warn(`Unhandled filter type: ${filterName}`)
        return
    }
  }
  const resetStates = () => {
    setJobSeekers([])
    setFilteredJobSeekers([])
    setHasMore(false)
    setLastVisible(null)
  }
  const matchesSearch = (jobSeeker, term) => {
    const searchTerm = term.toLowerCase().trim()

    // Create a searchable string for education
    const educationString = jobSeeker.education
      ?.map(
        (edu) =>
          `${edu.university || ''} ${edu.degree || ''} ${edu.major || ''}`
      )
      .join(' ')
      .toLowerCase()

    // Create searchable string for work history
    const workHistoryString = jobSeeker.work_history
      ?.map((job) => `${job.title || ''} ${job.employer || ''}`)
      .join(' ')
      .toLowerCase()

    // Create an array of all searchable fields
    const searchableFields = [
      jobSeeker.first_name?.toLowerCase(),
      jobSeeker.last_name?.toLowerCase(),
      jobSeeker.email?.toLowerCase(),
      jobSeeker.current_title?.toLowerCase(),
      jobSeeker.current_employer?.toLowerCase(),
      jobSeeker.location?.toLowerCase(),
      jobSeeker.current_level?.toLowerCase(), // Added this line
      educationString,
      jobSeeker.top_strengths?.join(' ').toLowerCase(),
      jobSeeker.languages?.join(' ').toLowerCase(),
      workHistoryString,
      jobSeeker.years_experience?.toLowerCase()
    ].filter(Boolean)

    // Check if any field contains the search term
    return searchableFields.some((field) => field.includes(searchTerm))
  }
  const matchesLocations = (jobSeeker) =>
    !selectedLocations.length || selectedLocations.includes(jobSeeker.location)

  const matchesTitles = (jobSeeker) =>
    !selectedCurrentTitles.length ||
    selectedCurrentTitles.includes(jobSeeker.current_title)

  const matchesEmployers = (jobSeeker) =>
    !selectedCurrentEmployers.length ||
    selectedCurrentEmployers.includes(jobSeeker.current_employer)

  const matchesWorkExperience = (jobSeeker) => {
    if (!selectedWorkExperience.length) return true
    const experience =
      jobSeeker.years_experience === 'None/Student'
        ? 'Student'
        : jobSeeker.years_experience
    return selectedWorkExperience.includes(experience)
  }

  const matchesEducation = (jobSeeker) => {
    if (!jobSeeker.education) return false

    // Check highest degree
    if (selectedHighestDegree.length > 0) {
      const highestDegree =
        jobSeeker.education[jobSeeker.education.length - 1].degree
      if (!selectedHighestDegree.includes(highestDegree)) return false
    }

    // Check university
    if (selectedUniversities.length > 0) {
      const hasMatchingUniversity = jobSeeker.education.some((edu) =>
        selectedUniversities.includes(edu.university)
      )
      if (!hasMatchingUniversity) return false
    }

    // Check major
    if (selectedMajors.length > 0) {
      const hasMatchingMajor = jobSeeker.education.some((edu) =>
        selectedMajors.includes(edu.major)
      )
      if (!hasMatchingMajor) return false
    }

    // Check HBCU
    if (selectedHBCU && !jobSeeker.education.some((edu) => edu.is_hbcu)) {
      return false
    }

    return true
  }

  const matchesCurrentLevel = (jobSeeker) =>
    !selectedCurrentLevel.length ||
    selectedCurrentLevel.includes(jobSeeker.current_level)

  const matchesStrengths = (jobSeeker) => {
    if (!selectedStrengths.length) return true
    if (!jobSeeker.strengths) return false
    return selectedStrengths.some((strength) =>
      jobSeeker.strengths.includes(strength)
    )
  }

  const matchesRelocate = (jobSeeker) =>
    selectedWillingToRelocate === null ||
    jobSeeker.willing_to_relocate === selectedWillingToRelocate

  const matchesStartupExperience = (jobSeeker) => {
    if (!selectedStartupExperience.length) return true
    const hasStartupExp = jobSeeker.startup_experience ? 'Yes' : 'No'
    return selectedStartupExperience.includes(hasStartupExp)
  }

  const matchesLanguages = (jobSeeker) => {
    if (!selectedLanguages.length) return true
    if (!jobSeeker.languages) return false
    return selectedLanguages.some((lang) => jobSeeker.languages.includes(lang))
  }

  const matchesAthleteStatus = (jobSeeker) =>
    !selectedAthleteStatus.length ||
    selectedAthleteStatus.includes(jobSeeker.athlete_status)

  const matchesDemographics = (jobSeeker) => {
    // Gender check
    if (
      selectedGenders.length > 0 &&
      !selectedGenders.includes(jobSeeker.gender)
    ) {
      return false
    }

    // Race check
    if (selectedRaces.length > 0 && !selectedRaces.includes(jobSeeker.race)) {
      return false
    }

    // Veteran status check
    if (
      selectedVeteranStatus.length > 0 &&
      !selectedVeteranStatus.includes(jobSeeker.veteran_status)
    ) {
      return false
    }

    return true
  }

  const matchesIndustries = (jobSeeker) => {
    if (!selectedIndustries.length && !selectedSubcategories.length) return true

    const jobSeekerIndustries = jobSeeker.industries || []
    const jobSeekerSubcategories = jobSeeker.subcategories || []

    if (selectedIndustries.length > 0) {
      const hasMatchingIndustry = selectedIndustries.some((industry) =>
        jobSeekerIndustries.includes(industry)
      )
      if (!hasMatchingIndustry) return false
    }

    if (selectedSubcategories.length > 0) {
      const hasMatchingSubcategory = selectedSubcategories.some((subcategory) =>
        jobSeekerSubcategories.includes(subcategory)
      )
      if (!hasMatchingSubcategory) return false
    }

    return true
  }

  const toggleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === 'desc' ? 'asc' : 'desc'))
    setFilteredJobSeekers((prev) =>
      [...prev].sort((a, b) =>
        sortOrder === 'asc' ? b.rating - a.rating : a.rating - b.rating
      )
    )
  }

  const toggleFilter = (filterName) => {
    setOpenFilters((prev) => ({ ...prev, [filterName]: !prev[filterName] }))
  }

  const toggleDemographicFilter = (filterName) => {
    setOpenDemographicFilters((prev) => ({
      ...prev,
      [filterName]: !prev[filterName]
    }))
    setOpenFilters((prev) => ({ ...prev, demographics: true }))
  }

  const clearAllFilters = () => {
    setSelectedLocations([])
    setSelectedCurrentEmployers([])
    setSelectedCurrentTitles([])
    setSelectedWorkExperience([])
    setSelectedCurrentLevel([])
    setSelectedHighestDegree([])
    setSelectedMajors([])
    setSelectedUniversities([])
    setSelectedHBCU(false)
    setSelectedStrengths([])
    setSelectedWillingToRelocate(null)
    setSelectedGenders([])
    setSelectedRaces([])
    setSelectedStartupExperience([])
    setSelectedLanguages([])
    setSelectedAthleteStatus([])
    setSelectedVeteranStatus([])
    setSelectedIndustries([])
    setSelectedSubcategories([])
    setOpenFilters({})
    setOpenDemographicFilters({})
    setSearchTerm('')
  }

  const renderFilterOptions = (
    options,
    selected,
    filterName,
    searchTerm = ''
  ) => {
    const filteredOptions = searchTerm
      ? options.filter((option) =>
          option?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : options

    return filteredOptions.map((option) => {
      const isSelected = selected.includes(option)
      return (
        <CheckboxLabel key={option}>
          <Checkbox
            type="checkbox"
            checked={isSelected}
            onChange={() => {
              const newValue = isSelected
                ? selected.filter((item) => item !== option)
                : [...selected, option]
              handleFilterChange(filterName, newValue)
            }}
          />
          {option}
          {isSelected && (
            <ClearButton
              onClick={(e) => {
                e.stopPropagation()
                const newValue = selected.filter((item) => item !== option)
                handleFilterChange(filterName, newValue)
              }}
            >
              <X size={12} />
            </ClearButton>
          )}
        </CheckboxLabel>
      )
    })
  }
  const getFilteredSubcategories = () => {
    if (selectedIndustries.length === 0) {
      // If no industries selected, show all subcategories
      return Object.values(allSubcategories)
        .flat()
        .filter((subcategory) =>
          subcategory.toLowerCase().includes(subcategorySearch.toLowerCase())
        )
    } else {
      // Show subcategories only for selected industries
      return selectedIndustries.reduce((acc, industry) => {
        const filteredSubs = allSubcategories[industry].filter((subcategory) =>
          subcategory.toLowerCase().includes(subcategorySearch.toLowerCase())
        )
        return [...acc, { industry, subcategories: filteredSubs }]
      }, [])
    }
  }
  const paginatedJobSeekers = useMemo(() => {
    const startIndex = (currentPage - 1) * jobSeekersPerPage
    return filteredJobSeekers.slice(startIndex, startIndex + jobSeekersPerPage)
  }, [filteredJobSeekers, currentPage, jobSeekersPerPage])

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const renderPagination = () => {
    if (totalPages <= 1) return null

    return (
      <PaginationControls>
        <PaginationButton
          onClick={() => {
            setCurrentPage((prev) => Math.max(prev - 1, 1))
            scrollToTop()
          }}
          disabled={currentPage === 1}
        >
          Prev
        </PaginationButton>
        <PageIndicator>
          Page {currentPage} of {totalPages}
        </PageIndicator>
        <PaginationButton
          onClick={() => {
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            scrollToTop()
          }}
          disabled={currentPage === totalPages}
        >
          Next
        </PaginationButton>
      </PaginationControls>
    )
  }

  return (
    <PageWrapper>
      <NavWrapper>
        <EmployerNav />
      </NavWrapper>
      <MainContent>
        <Header>Browse Job Seekers</Header>

        <SearchBar>
          <SearchInput
            type="text"
            placeholder="Search job seekers (name, email, location, employer, title, education, skills...)"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button onClick={filterJobSeekers}>
            <Search size={18} />
            Search
          </Button>
        </SearchBar>
        {/* <FavoriteFilterWrapper>
          <FavoriteLabel>
            <Checkbox
              type="checkbox"
              checked={showFavoritedOnly}
              onChange={() => setShowFavoritedOnly(!showFavoritedOnly)}
            />
            <Heart
              size={18}
              color={showFavoritedOnly ? '#CAAAE1' : '#666'}
              fill={showFavoritedOnly ? '#CAAAE1' : 'none'}
            />
            Show only job seekers who favorited your company
          </FavoriteLabel>
        </FavoriteFilterWrapper> */}

        <ContentGrid>
          <FiltersColumn>
            <FilterSection>
              <FilterHeader>
                <h1>Filters</h1>
                <ClearButton onClick={clearAllFilters}>Clear All</ClearButton>
              </FilterHeader>

              {/* Location Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('location')}>
                  Location
                  {openFilters.location ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.location}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search locations"
                    value={locationSearch}
                    onChange={(e) => setLocationSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        allJobSeekers.map((p) => p.location).filter(Boolean)
                      )
                    ),
                    selectedLocations,
                    'locations',
                    locationSearch
                  )}
                </FilterContent>
              </FilterSection>

              {/* Current Title Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentTitle')}>
                  Current Title
                  {openFilters.currentTitle ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentTitle}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search current titles"
                    value={currentTitleSearch}
                    onChange={(e) => setCurrentTitleSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        allJobSeekers
                          .map((p) => p.current_title)
                          .filter(Boolean)
                      )
                    ),
                    selectedCurrentTitles,
                    'currentTitles',
                    currentTitleSearch
                  )}
                </FilterContent>
              </FilterSection>

              {/* Current Level Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentLevel')}>
                  Current Level
                  {openFilters.currentLevel ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentLevel}>
                  {renderFilterOptions(
                    [
                      'Internship',
                      'Entry Level',
                      'Manager',
                      'Director',
                      'Head / Lead',
                      'Vice President',
                      'Senior Vice President',
                      'C-Suite Executive'
                    ],
                    selectedCurrentLevel,
                    'currentLevel'
                  )}
                </FilterContent>
              </FilterSection>

              {/* Work Experience Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('workExperience')}>
                  Work Experience
                  {openFilters.workExperience ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.workExperience}>
                  {renderFilterOptions(
                    [
                      'None/Student',
                      '1-2 years',
                      '3-5 years',
                      '6-10 years',
                      '11-15 years',
                      '16+ years'
                    ],
                    selectedWorkExperience,
                    'workExperience'
                  )}
                </FilterContent>
              </FilterSection>

              {/* Current Employer Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('currentEmployer')}>
                  Employer
                  {openFilters.currentEmployer ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.currentEmployer}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search current employers"
                    value={currentEmployerSearch}
                    onChange={(e) => setCurrentEmployerSearch(e.target.value)}
                  />
                  {renderFilterOptions(
                    Array.from(
                      new Set(
                        allJobSeekers
                          .map((p) => p.current_employer)
                          .filter(Boolean)
                      )
                    ),
                    selectedCurrentEmployers,
                    'currentEmployers',
                    currentEmployerSearch
                  )}
                </FilterContent>
              </FilterSection>

              {/* Education Filters */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('education')}>
                  Education
                  {openFilters.education ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.education}>
                  {/* Highest Degree */}
                  <NestedFilterContent>
                    <FilterTitle onClick={() => toggleFilter('highestDegree')}>
                      Highest Level Of Education
                      {openFilters.highestDegree ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openFilters.highestDegree}>
                      {renderFilterOptions(
                        [
                          'Some high school or less',
                          'High school diploma or equivalent',
                          'Vocational certificate or credential',
                          'Some college',
                          'Associates degree',
                          'Bachelors degree',
                          'Masters degree',
                          'PhD',
                          'MD, OD or related',
                          'DDS, DMD or related',
                          'JD',
                          'Other'
                        ],
                        selectedHighestDegree,
                        'highestDegree'
                      )}
                    </FilterContent>
                  </NestedFilterContent>

                  {/* Universities */}
                  <NestedFilterContent>
                    <FilterTitle onClick={() => toggleFilter('universities')}>
                      College/University
                      {openFilters.universities ? (
                        <ChevronUp size={16} />
                      ) : (
                        <ChevronDown size={16} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openFilters.universities}>
                      <FilterSearchInput
                        type="text"
                        placeholder="Search universities"
                        value={universitySearch}
                        onChange={(e) => setUniversitySearch(e.target.value)}
                      />
                      {renderFilterOptions(
                        Array.from(
                          new Set(
                            allJobSeekers.flatMap(
                              (p) =>
                                p.education
                                  ?.map((edu) => edu.university)
                                  .filter(Boolean) || []
                            )
                          )
                        ),
                        selectedUniversities,
                        'universities',
                        universitySearch
                      )}
                    </FilterContent>
                  </NestedFilterContent>

                  {/* HBCU */}
                  <NestedFilterContent>
                    <CheckboxLabel>
                      <Checkbox
                        type="checkbox"
                        checked={selectedHBCU}
                        onChange={() =>
                          handleFilterChange('hbcu', !selectedHBCU, false)
                        }
                      />
                      HBCU
                    </CheckboxLabel>
                  </NestedFilterContent>
                </FilterContent>
              </FilterSection>
              {/* Industry Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('industry')}>
                  Industry
                  {openFilters.industry ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.industry}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search industries"
                    value={industrySearch}
                    onChange={(e) => setIndustrySearch(e.target.value)}
                  />
                  <div className="max-h-64 overflow-y-auto">
                    {industries
                      .filter((industry) =>
                        industry
                          .toLowerCase()
                          .includes(industrySearch.toLowerCase())
                      )
                      .map((industry) => (
                        <CheckboxLabel key={industry}>
                          <Checkbox
                            type="checkbox"
                            checked={selectedIndustries.includes(industry)}
                            onChange={() => {
                              const newValue = selectedIndustries.includes(
                                industry
                              )
                                ? selectedIndustries.filter(
                                    (i) => i !== industry
                                  )
                                : [...selectedIndustries, industry]
                              handleFilterChange('industries', newValue)
                              // Clear subcategories if no industries selected
                              if (newValue.length === 0) {
                                handleFilterChange('subcategories', [])
                              }
                            }}
                          />
                          {industry}
                        </CheckboxLabel>
                      ))}
                  </div>
                </FilterContent>
              </FilterSection>

              {/* Subcategory Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('subcategory')}>
                  Company Type
                  {openFilters.subcategory ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.subcategory}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search subcategories"
                    value={subcategorySearch}
                    onChange={(e) => setSubcategorySearch(e.target.value)}
                  />
                  <FilterGrid>
                    {getFilteredSubcategories().map((subcategory) => (
                      <FilterCheckboxLabel key={subcategory}>
                        <Checkbox
                          type="checkbox"
                          checked={selectedSubcategories.includes(subcategory)}
                          onChange={() => {
                            const newValue = selectedSubcategories.includes(
                              subcategory
                            )
                              ? selectedSubcategories.filter(
                                  (s) => s !== subcategory
                                )
                              : [...selectedSubcategories, subcategory]
                            handleFilterChange('subcategories', newValue)
                          }}
                        />
                        {subcategory}
                      </FilterCheckboxLabel>
                    ))}
                  </FilterGrid>
                </FilterContent>
              </FilterSection>

              {/* Strengths Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('strengths')}>
                  Strengths
                  {openFilters.strengths ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.strengths}>
                  <FilterSearchInput
                    type="text"
                    placeholder="Search strengths"
                    value={strengthSearch}
                    onChange={(e) => setStrengthSearch(e.target.value)}
                  />
                  <StrengthsGrid>
                    {strengths
                      .filter((strength) =>
                        strength
                          .toLowerCase()
                          .includes(strengthSearch.toLowerCase())
                      )
                      .map((strength) => (
                        <StrengthCheckboxLabel key={strength}>
                          <Checkbox
                            type="checkbox"
                            checked={selectedStrengths.includes(strength)}
                            onChange={() => {
                              const newValue = selectedStrengths.includes(
                                strength
                              )
                                ? selectedStrengths.filter(
                                    (s) => s !== strength
                                  )
                                : [...selectedStrengths, strength]
                              handleFilterChange('strengths', newValue)
                            }}
                          />
                          {strength}
                        </StrengthCheckboxLabel>
                      ))}
                  </StrengthsGrid>
                </FilterContent>
              </FilterSection>

              {/* Languages Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('languages')}>
                  Languages
                  {openFilters.languages ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.languages}>
                  {renderFilterOptions(
                    languageOptions,
                    selectedLanguages,
                    'languages'
                  )}
                </FilterContent>
              </FilterSection>

              {/* Athlete Status Filter */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('athleteStatus')}>
                  Athlete Status
                  {openFilters.athleteStatus ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.athleteStatus}>
                  {renderFilterOptions(
                    athleteStatusOptions,
                    selectedAthleteStatus,
                    'athleteStatus'
                  )}
                </FilterContent>
              </FilterSection>

              {/* Demographics Section */}
              <FilterSection>
                <FilterTitle onClick={() => toggleFilter('demographics')}>
                  Demographics
                  {openFilters.demographics ? (
                    <ChevronUp size={18} />
                  ) : (
                    <ChevronDown size={18} />
                  )}
                </FilterTitle>
                <FilterContent isOpen={openFilters.demographics}>
                  {/* Gender Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('gender')}
                    >
                      Gender
                      {openDemographicFilters.gender ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openDemographicFilters.gender}>
                      {renderFilterOptions(
                        genderOptions,
                        selectedGenders,
                        'genders'
                      )}
                    </FilterContent>
                  </NestedFilterContent>

                  {/* Race/Ethnicity Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('race')}
                    >
                      Race/Ethnicity
                      {openDemographicFilters.race ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent isOpen={openDemographicFilters.race}>
                      {renderFilterOptions(raceOptions, selectedRaces, 'races')}
                    </FilterContent>
                  </NestedFilterContent>

                  {/* Veteran Status Filter */}
                  <NestedFilterContent>
                    <FilterTitle
                      onClick={() => toggleDemographicFilter('veteranStatus')}
                    >
                      Veteran Status
                      {openDemographicFilters.veteranStatus ? (
                        <ChevronUp size={18} />
                      ) : (
                        <ChevronDown size={18} />
                      )}
                    </FilterTitle>
                    <FilterContent
                      isOpen={openDemographicFilters.veteranStatus}
                    >
                      {renderFilterOptions(
                        veteranStatusOptions,
                        selectedVeteranStatus,
                        'veteranStatus'
                      )}
                    </FilterContent>
                  </NestedFilterContent>
                </FilterContent>
              </FilterSection>
            </FilterSection>
          </FiltersColumn>
          <div>
            {/* <SortContainer>
              <SortButton onClick={toggleSort}>
                <ArrowUpDown size={18} />
                Sort by {showMatchPercentage ? 'Match' : 'Relevance'}{' '}
                {sortOrder === 'desc' ? 'Descending' : 'Ascending'}
              </SortButton>
            </SortContainer> */}

            {/* Update the header to show filtered results */}
            <JobSeekersColumn>
              <JobSeekersHeader>
                {!loading && filteredJobSeekers.length > 0 ? (
                  <>{filteredJobSeekers.length} job seekers</>
                ) : loading ? (
                  <div>Loading job seekers...</div>
                ) : (
                  <div>No job seekers found matching your criteria</div>
                )}
              </JobSeekersHeader>

              {!loading && filteredJobSeekers.length === 0 ? (
                <div>No job seekers found matching your criteria</div>
              ) : (
                <>
                  {paginatedJobSeekers.map((jobSeeker, index) => (
                    <JobSeekerCard
                      key={jobSeeker.objectID} // Change to objectID
                      id={index === 0 ? 'top-jobseeker-card' : ''}
                      onClick={() =>
                        navigate(
                          `/jobseeker-profile-view/${jobSeeker.objectID}`
                        )
                      }
                    >
                      {jobSeeker.profile_picture ? (
                        <ProfileImage
                          src={jobSeeker.profile_picture}
                          alt={`${jobSeeker.first_name} ${jobSeeker.last_name}`}
                          onError={(e) => {
                            e.target.onerror = null
                            e.target.src = '/default-avatar.png'
                          }}
                        />
                      ) : (
                        <ProfileImageFallback size={24}>
                          <User size={24} />
                        </ProfileImageFallback>
                      )}

                      <JobSeekerInfo>
                        {/* Name and Pronouns */}
                        <JobSeekerNameWrapper>
                          <JobSeekerName>
                            {`${jobSeeker.first_name || ''} ${
                              jobSeeker.last_name || ''
                            }`}
                          </JobSeekerName>
                          {jobSeeker.pronouns && (
                            <Pronouns>({jobSeeker.pronouns})</Pronouns>
                          )}
                        </JobSeekerNameWrapper>

                        {/* Work History (Most Recent Job) */}
                        <JobSeekerDetails>
                          {jobSeeker.work_history?.length > 0 ? (
                            (() => {
                              // Find current job (with null end_date)
                              const currentJob = jobSeeker.work_history.find(
                                (job) => !job.end_date
                              )

                              // If no current job, find most recent past job
                              if (!currentJob) {
                                // Sort past jobs by end date
                                const sortedPastJobs = jobSeeker.work_history
                                  .filter((job) => job.end_date)
                                  .sort(
                                    (a, b) =>
                                      new Date(b.end_date) -
                                      new Date(a.end_date)
                                  )

                                const mostRecentJob = sortedPastJobs[0]

                                return (
                                  <div key={mostRecentJob.title}>
                                    <strong>
                                      {mostRecentJob.title || 'Unknown Title'}
                                    </strong>{' '}
                                    at{' '}
                                    {mostRecentJob.employer ||
                                      'Unknown Employer'}
                                    {mostRecentJob.start_date && (
                                      <span>
                                        {' '}
                                        (
                                        {new Date(
                                          mostRecentJob.start_date
                                        ).toLocaleDateString()}{' '}
                                        -{' '}
                                        {new Date(
                                          mostRecentJob.end_date
                                        ).toLocaleDateString()}
                                        )
                                      </span>
                                    )}
                                  </div>
                                )
                              }

                              // Return current job if found
                              return (
                                <div key={currentJob.title}>
                                  <strong>
                                    {currentJob.title || 'Unknown Title'}
                                  </strong>{' '}
                                  at {currentJob.employer || 'Unknown Employer'}
                                  {currentJob.start_date && (
                                    <span>
                                      {' '}
                                      (
                                      {new Date(
                                        currentJob.start_date
                                      ).toLocaleDateString()}{' '}
                                      - Present)
                                    </span>
                                  )}
                                </div>
                              )
                            })()
                          ) : (
                            <span>No work history available.</span>
                          )}
                        </JobSeekerDetails>

                        {/* Experience, Level, Location */}
                        <JobSeekerDetails>
                          {[
                            jobSeeker.years_experience
                              ? `${
                                  jobSeeker.years_experience === 'None/Student'
                                    ? 'Student'
                                    : `${jobSeeker.years_experience} experience`
                                }`
                              : null,
                            jobSeeker.current_level,
                            jobSeeker.location
                          ]
                            .filter(Boolean)
                            .join(' • ')}
                        </JobSeekerDetails>

                        {/* Education */}
                        {jobSeeker.education &&
                          jobSeeker.education.length > 0 && (
                            <JobSeekerDetails>
                              {jobSeeker.education
                                .map(
                                  (edu) =>
                                    `${edu.degree || ''} ${
                                      edu.major ? `in ${edu.major}` : ''
                                    } ${
                                      edu.university
                                        ? `from ${edu.university}`
                                        : ''
                                    }`
                                )
                                .join(' • ')}
                            </JobSeekerDetails>
                          )}

                        {/* Strengths/Skills Tags */}
                        <TagList>
                          {jobSeeker.top_strengths?.length > 0 &&
                            jobSeeker.top_strengths
                              .slice(0, 3)
                              .map((strength, index) => (
                                <Tag key={index}>{strength}</Tag>
                              ))}
                        </TagList>
                      </JobSeekerInfo>
                    </JobSeekerCard>
                  ))}

                  {/* Pagination Controls */}
                  {renderPagination()}
                </>
              )}
            </JobSeekersColumn>
          </div>
        </ContentGrid>
      </MainContent>
      <Joyride
        steps={steps}
        run={run}
        callback={handleJoyrideCallback} // Add this line
        continuous={true}
        disableOverlayClose={true}
        scrollToFirstStep
        showSkipButton
        locale={{
          last: 'Got it'
        }}
        styles={{
          options: {
            zIndex: 10000,
            arrowColor: '#fff',
            backgroundColor: '#fff',
            primaryColor: '#C471ED'
          },
          buttonClose: {
            height: 12,
            width: 12
          },
          tooltip: {
            borderRadius: 8,
            maxWidth: '100%',
            padding: 15
          },
          spotlight: {
            borderRadius: 12
          },
          buttonNext: {
            borderColor: '#C471ED',
            borderStyle: 'solid',
            borderWidth: '2px',
            boxShadow: 'none',
            backgroundColor: '#C471ED',
            color: '#fff'
          },
          buttonClose: {
            display: 'none'
          }
        }}
      />
    </PageWrapper>
  )
}
