import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import JobSeekerNav from './JobSeekerNav'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Edit, Pencil } from 'lucide-react'
import JobSeekerPricing from '../JobSeekerPricing'
import { auth } from '../../firebase'
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword
} from 'firebase/auth'

import {
  getJobseekerProfile,
  updateJobseekerProfile
} from '../../models/JobSeekerProfile'
import { getUserByFirebaseUid } from '../../models/User'
import { db } from '../../firebase'
import { storage } from '../../firebase'

import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

const culturePreferenceOptions = [
  'Initiative & Self-Direction',
  'Collaboration',
  'Adaptability',
  'Integrity',
  'Innovation',
  'Growth Mindset',
  'Accountability',
  'Customer Focus'
].sort()

const workPreferenceOptions = ['Fully Remote', 'In-Office', 'Hybrid']

const pronounOptions = [
  'he/him',
  'she/her',
  'they/them',
  'prefer not to say',
  'other'
]

const raceOptions = [
  'Asian',
  'Black',
  'Hispanic/Latino',
  'Middle Eastern/Arab',
  'Native American',
  'Pacific Islander',
  'White',
  'Prefer not to say'
]

const veteranStatusOptions = [
  'Veteran',
  'Active Duty',
  'Reservist',
  'Not A Veteran',
  'Prefer not to say'
]

const sexualOrientationOptions = [
  'Heterosexual/Straight',
  'Gay',
  'Lesbian',
  'Bisexual',
  'Pansexual',
  'Asexual',
  'Queer',
  'Prefer not to say'
]
const genderOptions = [
  'Male',
  'Female',
  'Non-binary',
  'Transgender Male',
  'Transgender Female',
  'Prefer not to say'
]

const disabilityOptions = ['Yes', 'No', 'Prefer not to say']

const softColors = {
  background: '#f0f4f8',
  card: '#ffffff',
  primary: '#CAAAE1',
  secondary: '#f6e05e',
  text: '#2d3748',
  textLight: '#718096',
  border: '#e2e8f0',
  success: '#68d391',
  warning: '#f6ad55',
  danger: '#fc8181',
  info: '#63b3ed',
  icons: '#12C2E8',
  icontext: '#C859FF',
  yellow: '#f6e05e'
}
const PageWrapper = styled.div`
  display: flex;
  min-height: 100vh;
`

const NavbarWrapper = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 1000;
`

const PageContainer = styled.div`
  flex-grow: 1;
  background-color: #f8f9fa;
  overflow-y: auto;
`

const ContentContainer = styled.div`
  flex: 1;
  padding: 1rem 3rem;
  overflow-y: auto;

  @media (max-width: 768px) {
    padding: 0.75rem;
  }
`

const Card = styled.div`
  background-color: #ffffff;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 1.5rem;
  margin-top: -2rem;
`

const Select = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 8px;
  border: 1px solid ${softColors.border};
  background-color: ${softColors.card};

  color: ${softColors.text};
  transition: all 0.2s;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: ${softColors.primary};
    box-shadow: 0 0 0 2px ${softColors.primary}33;
  }
`
const WorkCoreValueBubble = styled.div`
  font-size: 12px;
  background-color: #caaae1;
  border-radius: 15px;
  padding: 6px 12px;
  margin: 5px;
  color: white;
  display: flex;
  align-items: center;
`
const RaceBubble = styled.div`
  font-size: 12px;
  background-color: #6b7280;
  border-radius: 15px;
  padding: 6px 12px;
  margin: 5px;
  color: white;
  display: inline-flex;
  align-items: center;
`

const StyledButton = styled.button`
  background-color: #caaae1;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #b799d4;
  }
`

const CurrentInfo = styled.p`
  font-size: 14px;
  color: #6b7280;
  margin: 6px 0px 12px 0px;
`
const InputLabel = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
`
const StyledInput = styled.input`
  width: 98%;
  padding: 8px;
  border: 1px solid #e2e8f0;

  border-radius: 4px;
  font-size: 14px;
  outline: none;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #caaae1;
    box-shadow: 0 0 0 3px rgba(202, 170, 225, 0.1);
  }
  @media (max-width: 768px) {
    max-width: calc(100% - 50px);
  }
`
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`
const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  color: #4a5568;
  padding-left: 10px;
`
const EditIconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;

  &:hover {
    color: #b799d4;
  }
`
const SectionTitleContainer = styled.div`
  display: flex;
`
// Checkbox and Radio Components
const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${softColors.background};
  cursor: pointer;
  transition: all 0.2s;
  margin-top: 0.1rem;
  font-size: 14px;
  color: #6b7280;

  &:hover {
    background-color: ${softColors.border};
  }

  @media (max-width: 768px) {
    font-size: 0.65rem;
    padding: 0.5rem;
  }
`
// Multi-select Components
const MultiSelect = styled.div`
  border: 1px solid ${softColors.border};
  border-radius: 0.5rem;
  padding: 1rem;
  max-height: 300px;
  overflow-y: auto;
  background-color: ${softColors.card};
  margin-bottom: 10px;
  @media (max-width: 768px) {
    padding: 0.25rem;
  }
`
const TabButton = styled.button`
  padding: 10px;
  background-color: ${({ active }) => (active ? '#CAAAE1' : 'transparent')};
  border: none;
  border-bottom: ${({ active }) =>
    active ? '2px solid #CAAAE1' : '1px solid #e5e7eb'};
  cursor: pointer;
  flex: 1;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  color: ${({ active }) => (active ? '#FFFFFF' : '#000000')};
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  box-shadow: ${({ active }) =>
    active
      ? '0 -1px 3px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.06)'
      : 'none'};

  @media (max-width: 768px) {
    padding: 6px;
    font-size: 12px;
  }
`

const Input = ({ label, ...props }) => (
  <div style={{ marginBottom: '15px' }}>
    <InputLabel>{label}</InputLabel>
    <StyledInput {...props} />
  </div>
)
function EditInputField({ label, value, onChange, placeholder }) {
  return (
    <div style={{ marginBottom: '15px' }}>
      <InputLabel>{label}</InputLabel>
      <InputWrapper>
        <StyledInput
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        <IconWrapper>
          <Pencil size={12} />
        </IconWrapper>
      </InputWrapper>
    </div>
  )
}

const Button = ({ children, ...props }) => (
  <StyledButton {...props}>{children}</StyledButton>
)

const Checkbox = ({ label, ...props }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <input
      type="checkbox"
      style={{
        marginRight: '10px',
        accentColor: '#CAAAE1'
      }}
      {...props}
    />
    <label style={{ fontSize: '14px' }}>{label}</label>
  </div>
)

export default function JobSeekerSettings() {
  const [profile, setProfile] = useState({})
  const [user, setUser] = useState(null)
  const [updateMessage, setUpdateMessage] = useState('')
  const [updateError, setUpdateError] = useState('')
  const location = useLocation()
  const [activeTab, setActiveTab] = useState('login')
  const [email, setEmail] = useState('john@email.com')
  const [newEmail, setNewEmail] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  //new fields for editing
  const [address, setAddress] = useState('')
  const [newAddress, setNewAddress] = useState('')
  const [newWorkPreferences, setNewWorkPreferences] = useState([])
  const [newWorkCoreValues, setnewWorkCoreValues] = useState([])
  const [newPronouns, setNewPronouns] = useState('')
  const [newSexualOrientation, setNewSexualOrientation] = useState('')
  const [newGenderIdentity, setNewGenderIdentity] = useState('')
  const [newVeteranStatus, setNewVeteranStatus] = useState('')
  const [newDisabilityStatus, setNewDisabilityStatus] = useState('')
  const [newRace, setNewRace] = useState([])
  const [personalUpdateMessage, setPersonalUpdateMessage] = useState('')
  const [demographicUpdateMessage, setDemographicUpdateMessage] = useState('')

  const [editingPersonal, setEditingPersonal] = useState(false)
  const [editingDemographic, setEditingDemographic] = useState(false)

  const [currentTab, setCurrentTab] = useState('monthly')
  const [selectedCard, setSelectedCard] = useState(null)

  const handlePersonalEditClick = () => {
    setEditingPersonal(true)
  }
  const handleDemographicEditClick = () => {
    setEditingDemographic(true)
  }
  const handleUpdateEmail = (e) => {
    e.preventDefault()
    //setEmail(newEmail);
    setNewEmail('')
  }

  const handleUpdatePassword = async (e) => {
    e.preventDefault()
    setUpdateMessage('')
    setUpdateError('')

    if (!oldPassword || !newPassword) {
      setUpdateError('Please fill in both password fields')
      return
    }

    if (newPassword.length < 8) {
      setUpdateError('New password must be at least 8 characters')
      return
    }

    try {
      const user = auth.currentUser
      if (!user || !user.email) {
        setUpdateError('Unable to verify user. Please try logging in again.')
        return
      }

      // Create credential with the current user's email
      const credential = EmailAuthProvider.credential(user.email, oldPassword)

      // Reauthenticate first
      await reauthenticateWithCredential(user, credential)

      // Then update password
      await updatePassword(user, newPassword)

      // Clear form and show success
      setOldPassword('')
      setNewPassword('')
      setUpdateMessage('Password updated successfully!')
    } catch (error) {
      console.error('Error updating password:', error)
      if (
        error.code === 'auth/wrong-password' ||
        error.code === 'auth/invalid-credential'
      ) {
        setUpdateError('Current password is incorrect')
      } else {
        setUpdateError('Error updating password. Please try again.')
      }
    }
  }

  // Field change handlers
  const handlePersonalFieldChange = (field, value) => {
    setProfile((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  const handleDemographicFieldChange = (field, value) => {
    setProfile((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  const handleCulturePreferenceChange = (preference) => {
    setProfile((prev) => {
      const currentPreferences = prev.culture_preferences || []
      const newPreferences = currentPreferences.includes(preference)
        ? currentPreferences.filter((p) => p !== preference)
        : [...currentPreferences, preference]

      return {
        ...prev,
        culture_preferences: newPreferences
      }
    })
  }

  const handleRaceChange = (race) => {
    setProfile((prev) => {
      const currentRaces = prev.race || []
      const newRaces = currentRaces.includes(race)
        ? currentRaces.filter((r) => r !== race)
        : [...currentRaces, race]

      return {
        ...prev,
        race: newRaces
      }
    })
  }

  // Form submission handlers
  const handleUpdatePersonalInfo = async (e) => {
    e.preventDefault()
    setPersonalUpdateMessage('')

    try {
      const userId = auth.currentUser.uid
      const { address, work_preference, culture_preferences } = profile

      await updateJobseekerProfile(userId, {
        address,
        work_preference,
        culture_preferences
      })

      setPersonalUpdateMessage('Personal information updated successfully!')
      setEditingPersonal(false)
    } catch (error) {
      console.error('Error updating personal information:', error)
      setPersonalUpdateMessage('Error updating information. Please try again.')
    }
  }

  const handleUpdateDemographics = async (e) => {
    e.preventDefault()
    setDemographicUpdateMessage('')

    try {
      const userId = auth.currentUser.uid
      const {
        pronouns,
        sexual_orientation,
        gender,
        veteran_status,
        disability_status,
        race
      } = profile

      await updateJobseekerProfile(userId, {
        pronouns,
        sexual_orientation,
        gender,
        veteran_status,
        disability_status,
        race
      })

      setDemographicUpdateMessage(
        'Demographic information updated successfully!'
      )
      setEditingDemographic(false)
    } catch (error) {
      console.error('Error updating demographic information:', error)
      setDemographicUpdateMessage(
        'Error updating information. Please try again.'
      )
    }
  }

  useEffect(() => {
    const query = new URLSearchParams(location.search)
    const tab = query.get('tab')
    if (tab) {
      setActiveTab(tab)
    }
  }, [location])

  useEffect(() => {
    const fetchProfileData = async () => {
      const currentUser = auth.currentUser
      if (currentUser) {
        try {
          const userData = await getUserByFirebaseUid(currentUser.uid)
          setUser(userData)
          setEmail(userData.email)

          const jobseekerProfileData = await getJobseekerProfile(
            currentUser.uid
          )

          // If they've unsubscribed or downgraded, ensure plan is set to free
          if (
            jobseekerProfileData.cancelDate ||
            jobseekerProfileData.cancellationType
          ) {
            await updateJobseekerProfile(currentUser.uid, {
              ...jobseekerProfileData,
              plan: 'free'
            })
            jobseekerProfileData.plan = 'free'
          }

          // Ensure all arrays are initialized even if data is missing
          const profile = {
            ...jobseekerProfileData
          }

          setProfile(profile)
        } catch (error) {
          console.error('Error fetching profile data:', error)
        }
      }
    }

    fetchProfileData()
  }, [])

  return (
    <PageWrapper>
      <NavbarWrapper>
        <JobSeekerNav />
      </NavbarWrapper>
      <PageContainer>
        <ContentContainer>
          <div style={{ fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ fontSize: '24px', marginBottom: '10px' }}>
              Account Settings
            </h1>
            <p
              style={{
                fontSize: '14px',
                color: '#6b7280',
                marginBottom: '20px'
              }}
            >
              Manage your job seeker account preferences
            </p>

            <div
              style={{
                display: 'flex',
                borderBottom: '1px solid #e5e7eb',
                marginBottom: '2rem'
              }}
            >
              <TabButton
                active={activeTab === 'login'}
                onClick={() => setActiveTab('login')}
              >
                Login Settings
              </TabButton>
              <TabButton
                active={activeTab === 'billing'}
                onClick={() => setActiveTab('billing')}
              >
                Billing
              </TabButton>

              <TabButton
                active={activeTab === 'update-info'}
                onClick={() => setActiveTab('update-info')}
              >
                Update Information
              </TabButton>
            </div>

            {activeTab === 'login' && (
              <Card>
                <h2 style={{ fontSize: '18px', marginBottom: '15px' }}>
                  Password Information
                </h2>

                <form
                  onSubmit={handleUpdatePassword}
                  style={{ marginTop: '20px' }}
                >
                  {updateError && (
                    <div
                      style={{
                        padding: '10px',
                        backgroundColor: '#fef2f2',
                        borderRadius: '4px',
                        marginBottom: '15px',
                        border: '1px solid #fee2e2'
                      }}
                    >
                      <p style={{ color: '#ef4444', fontSize: '14px' }}>
                        {updateError}
                      </p>
                    </div>
                  )}
                  {updateMessage && (
                    <div
                      style={{
                        padding: '10px',
                        backgroundColor: '#f0fdf4',
                        borderRadius: '4px',
                        marginBottom: '15px',
                        border: '1px solid #dcfce7'
                      }}
                    >
                      <p style={{ color: '#10b981', fontSize: '14px' }}>
                        {updateMessage}
                      </p>
                    </div>
                  )}
                  <Input
                    label="Current Password"
                    type="password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    placeholder="Enter current password"
                  />
                  <Input
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter new password"
                  />
                  <p
                    style={{
                      fontSize: '12px',
                      color: '#6b7280',
                      marginBottom: '10px'
                    }}
                  >
                    Minimum 8 characters
                  </p>
                  <Button type="submit">Change Password</Button>
                </form>
              </Card>
            )}

            {activeTab === 'billing' && (
              <div
                style={{
                  fontFamily: 'Arial, sans-serif'
                }}
              >
                <JobSeekerPricing />
              </div>
            )}

            {activeTab === 'notifications' && (
              <Card>
                <h2 style={{ fontSize: '18px', marginBottom: '15px' }}>
                  Notification Preferences
                </h2>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6b7280',
                    marginBottom: '20px'
                  }}
                >
                  Customize your preferred notification settings.
                </p>

                <Checkbox label="Applications" />
                <p
                  style={{
                    fontSize: '12px',
                    color: '#6b7280',
                    marginLeft: '25px',
                    marginBottom: '15px'
                  }}
                >
                  These are notifications for jobs that you have applied to
                </p>

                <Checkbox label="Job Matches" />
                <p
                  style={{
                    fontSize: '12px',
                    color: '#6b7280',
                    marginLeft: '25px',
                    marginBottom: '15px'
                  }}
                >
                  These are notifications for job openings that the AI has
                  matched you with{' '}
                </p>

                <Checkbox label="Recommendations" />
                <p
                  style={{
                    fontSize: '12px',
                    color: '#6b7280',
                    marginLeft: '25px',
                    marginBottom: '15px'
                  }}
                >
                  These are notifications for personalized recommendations from
                  Arena
                </p>

                <Button type="submit">Update Notifications</Button>
              </Card>
            )}
            {activeTab === 'update-info' && (
              <Card>
                <SectionTitleContainer>
                  <h2 style={{ fontSize: '18px', marginBottom: '0px' }}>
                    Personal Information
                  </h2>
                  {!editingPersonal && (
                    <EditIconButton onClick={handlePersonalEditClick}>
                      <Edit size={16} />
                    </EditIconButton>
                  )}
                </SectionTitleContainer>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6b7280',
                    marginBottom: '20px'
                  }}
                >
                  Update your personal information anytime.
                </p>

                <form onSubmit={handleUpdatePersonalInfo}>
                  {personalUpdateMessage && (
                    <div
                      style={{
                        padding: '10px',
                        backgroundColor: '#f0fdf4',
                        borderRadius: '4px',
                        marginBottom: '15px',
                        border: '1px solid #dcfce7'
                      }}
                    >
                      <p style={{ color: '#10b981', fontSize: '14px' }}>
                        {personalUpdateMessage}
                      </p>
                    </div>
                  )}

                  <InputLabel>Address</InputLabel>
                  {!editingPersonal ? (
                    <CurrentInfo>
                      {profile && profile.address
                        ? profile.address
                        : 'No address added'}
                    </CurrentInfo>
                  ) : (
                    <EditInputField
                      placeholder="Set address"
                      value={profile?.address || ''}
                      onChange={(e) =>
                        handlePersonalFieldChange('address', e.target.value)
                      }
                    />
                  )}

                  <InputLabel>Work Preferences</InputLabel>
                  {!editingPersonal ? (
                    <CurrentInfo>
                      {profile && profile.work_preference
                        ? profile.work_preference
                        : 'No work preference specified'}
                    </CurrentInfo>
                  ) : (
                    <Select
                      id="work_preference"
                      value={profile?.work_preference || ''}
                      onChange={(e) =>
                        handlePersonalFieldChange(
                          'work_preference',
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select your work preference</option>
                      {workPreferenceOptions.map((pref) => (
                        <option key={pref} value={pref}>
                          {pref}
                        </option>
                      ))}
                    </Select>
                  )}

                  <InputLabel>Work Core Values</InputLabel>
                  {!editingPersonal ? (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {profile?.culture_preferences?.map(
                        (preference, index) => (
                          <WorkCoreValueBubble key={index}>
                            {preference}
                          </WorkCoreValueBubble>
                        )
                      )}
                    </div>
                  ) : (
                    <MultiSelect>
                      <CheckboxGroup>
                        {culturePreferenceOptions.map((preference) => (
                          <CheckboxLabel key={preference}>
                            <Checkbox
                              checked={profile?.culture_preferences?.includes(
                                preference
                              )}
                              onChange={() =>
                                handleCulturePreferenceChange(preference)
                              }
                            />
                            <span>{preference}</span>
                          </CheckboxLabel>
                        ))}
                      </CheckboxGroup>
                    </MultiSelect>
                  )}

                  {editingPersonal && (
                    <Button type="submit">Update Personal Information</Button>
                  )}
                </form>

                <SectionTitleContainer>
                  <h2
                    style={{
                      fontSize: '18px',
                      marginBottom: '0px',
                      marginTop: '20px'
                    }}
                  >
                    Demographic Information
                  </h2>
                  {!editingDemographic && (
                    <EditIconButton onClick={handleDemographicEditClick}>
                      <Edit size={16} />
                    </EditIconButton>
                  )}
                </SectionTitleContainer>
                <p
                  style={{
                    fontSize: '14px',
                    color: '#6b7280',
                    marginBottom: '20px'
                  }}
                >
                  Update your demographic information anytime.
                </p>

                <form onSubmit={handleUpdateDemographics}>
                  {demographicUpdateMessage && (
                    <div
                      style={{
                        padding: '10px',
                        backgroundColor: '#f0fdf4',
                        borderRadius: '4px',
                        marginBottom: '15px',
                        border: '1px solid #dcfce7'
                      }}
                    >
                      <p style={{ color: '#10b981', fontSize: '14px' }}>
                        {demographicUpdateMessage}
                      </p>
                    </div>
                  )}

                  <InputLabel>Pronouns</InputLabel>
                  {!editingDemographic ? (
                    <CurrentInfo>
                      {profile && profile.pronouns
                        ? profile.pronouns
                        : 'Pronouns not specified'}
                    </CurrentInfo>
                  ) : (
                    <Select
                      id="pronouns"
                      value={profile?.pronouns || ''}
                      onChange={(e) =>
                        handleDemographicFieldChange('pronouns', e.target.value)
                      }
                    >
                      <option value="">Select your pronouns</option>
                      {pronounOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  )}

                  <InputLabel>Sexual Orientation</InputLabel>
                  {!editingDemographic ? (
                    <CurrentInfo>
                      {profile && profile.sexual_orientation
                        ? profile.sexual_orientation
                        : 'Sexual Orientation not specified'}
                    </CurrentInfo>
                  ) : (
                    <Select
                      id="sexual_orientation"
                      value={profile?.sexual_orientation || ''}
                      onChange={(e) =>
                        handleDemographicFieldChange(
                          'sexual_orientation',
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select sexual orientation</option>
                      {sexualOrientationOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  )}

                  <InputLabel>Gender Identity</InputLabel>
                  {!editingDemographic ? (
                    <CurrentInfo>
                      {profile && profile.gender
                        ? profile.gender
                        : 'Gender Identity not specified'}
                    </CurrentInfo>
                  ) : (
                    <Select
                      id="gender"
                      value={profile?.gender || ''}
                      onChange={(e) =>
                        handleDemographicFieldChange('gender', e.target.value)
                      }
                    >
                      <option value="">Select gender identity</option>
                      {genderOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  )}

                  <InputLabel>Veteran Status</InputLabel>
                  {!editingDemographic ? (
                    <CurrentInfo>
                      {profile && profile.veteran_status
                        ? profile.veteran_status
                        : 'Veteran Status not specified'}
                    </CurrentInfo>
                  ) : (
                    <Select
                      id="veteran"
                      value={profile?.veteran_status || ''}
                      onChange={(e) =>
                        handleDemographicFieldChange(
                          'veteran_status',
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select veteran status</option>
                      {veteranStatusOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  )}

                  <InputLabel>Disability Status</InputLabel>
                  {!editingDemographic ? (
                    <CurrentInfo>
                      {profile && profile.disability_status
                        ? profile.disability_status
                        : 'Disability status not specified'}
                    </CurrentInfo>
                  ) : (
                    <Select
                      id="disability"
                      value={profile?.disability_status || ''}
                      onChange={(e) =>
                        handleDemographicFieldChange(
                          'disability_status',
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select disability status</option>
                      {disabilityOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  )}

                  <InputLabel>Racial/Ethnic Background</InputLabel>
                  {!editingDemographic ? (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {profile?.race && profile.race.length > 0 ? (
                        profile.race.map((raceItem, index) => (
                          <RaceBubble key={index}>{raceItem}</RaceBubble>
                        ))
                      ) : (
                        <CurrentInfo>
                          Racial/Ethnic background not specified
                        </CurrentInfo>
                      )}
                    </div>
                  ) : (
                    <MultiSelect>
                      <CheckboxGroup>
                        {raceOptions.map((raceOption) => (
                          <CheckboxLabel key={raceOption}>
                            <Checkbox
                              checked={profile?.race?.includes(raceOption)}
                              onChange={() => handleRaceChange(raceOption)}
                            />
                            <span>{raceOption}</span>
                          </CheckboxLabel>
                        ))}
                      </CheckboxGroup>
                    </MultiSelect>
                  )}

                  {editingDemographic && (
                    <Button type="submit">
                      Update Demographic Information
                    </Button>
                  )}
                </form>
              </Card>
            )}
          </div>
        </ContentContainer>
      </PageContainer>
    </PageWrapper>
  )
}
