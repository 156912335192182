import React, { useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`

const Button = styled.button`
  background: #3b82f6;
  color: white;
  font-weight: 600;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background 0.2s;

  &:hover {
    background: #2563eb;
  }

  &:active {
    background: #1d4ed8;
  }

  &:disabled {
    background: #93c5fd;
    cursor: not-allowed;
  }
`

const Alert = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 0.5rem;
  border-left: 4px solid;
  background: ${(props) => (props.$isError ? '#fef2f2' : '#f0fdf4')};
  border-left-color: ${(props) => (props.$isError ? '#ef4444' : '#22c55e')};
`

const IconWrapper = styled.div`
  flex-shrink: 0;
  color: ${(props) => (props.$isError ? '#f87171' : '#4ade80')};
`

const AlertContent = styled.div`
  margin-left: 0.75rem;
`

const AlertTitle = styled.h3`
  font-size: 0.875rem;
  font-weight: 500;
  color: ${(props) => (props.$isError ? '#991b1b' : '#166534')};
`

const AlertMessage = styled.div`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: ${(props) => (props.$isError ? '#b91c1c' : '#15803d')};
`

const PopulateJobsButton = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState(null)
  const [error, setError] = useState(null)

  const handlePopulate = async () => {
    setIsLoading(true)
    setError(null)
    setResult(null)

    try {
      const response = await fetch('http://localhost:3001/fetch-jobs') // ✅ Changed to GET

      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`)
      }

      const data = await response.json()
      setResult(data)
    } catch (err) {
      setError(err.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Button onClick={handlePopulate} disabled={isLoading}>
        {isLoading ? 'Populating Jobs...' : 'Populate Jobs'}
      </Button>

      {error && (
        <Alert $isError>
          <IconWrapper $isError>
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                clipRule="evenodd"
              />
            </svg>
          </IconWrapper>
          <AlertContent>
            <AlertTitle $isError>Error</AlertTitle>
            <AlertMessage $isError>{error}</AlertMessage>
          </AlertContent>
        </Alert>
      )}

      {result && (
        <Alert>
          <IconWrapper>
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </IconWrapper>
          <AlertContent>
            <AlertTitle>Success</AlertTitle>
            <AlertMessage>
              {result.message} ({result.jobsCount} jobs imported)
            </AlertMessage>
          </AlertContent>
        </Alert>
      )}
    </Container>
  )
}

export default PopulateJobsButton
