import { db, auth } from '../firebase'
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  query,
  where,
  serverTimestamp,
  doc,
  arrayUnion,
  arrayRemove,
  increment
} from 'firebase/firestore'

const JOB_POSTINGS_COLLECTION = 'jobPostings'
const USERS_COLLECTION = 'users'
const JOB_APPLICATIONS_COLLECTION = 'jobApplications'

// JobPosting data structure
const createJobPostingObject = (postingData) => ({
  employer_id: postingData.employer_id,
  title: postingData.title,
  strengths: postingData.strengths || [],
  technical_skills: Array.isArray(postingData.technical_skills)
    ? postingData.technical_skills.filter((skill) => skill.trim() !== '') // Filter out empty strings
    : [],
  description: postingData.description?.trim() || '',
  responsibilities: postingData.responsibilities?.trim() || '',
  requirements: postingData.requirements?.trim() || '',
  recommended_skills: postingData.recommended_skills || [],
  nice_to_haves: postingData.nice_to_haves?.trim() || null,
  location: postingData.location || null,
  work_policy: postingData.work_policy || null,
  department: postingData.department || null,
  level: postingData.level || null,
  type: postingData.type || [],
  salary: postingData.salary
    ? {
        type: postingData.salary.type || 'yearly',
        range_start: postingData.salary.range_start || 0,
        range_end: postingData.salary.range_end || 0
      }
    : null,
  benefits: postingData.benefits || [],
  additional_compensation: postingData.additional_compensation || [],
  willing_to_sponsor: postingData.willing_to_sponsor || false,
  application_type: postingData.application_type || 'internal',
  external_application_url: postingData.external_application_url || null,
  number_of_applicants: 0,
  applicant_ids: [],
  status: 'active',
  date_posted: postingData.date_posted || null,
  employer_profile: {
    company_name: postingData.employer_name || 'Unknown Employer', // Add a fallback
    company_logo: postingData.company_logo || '/default-company-logo.png',
    company_website: ''
  },
  sourced: postingData.sourced || false,
  created_at: serverTimestamp(),
  updated_at: serverTimestamp()
})

// Create a job posting
export const createJobPosting = async (postingData) => {
  try {
    // Ensure the user is authenticated
    if (!auth.currentUser) {
      throw new Error('No authenticated user')
    }

    // Fetch the user document from Firestore
    const userRef = doc(db, USERS_COLLECTION, auth.currentUser.uid)
    const userSnap = await getDoc(userRef)

    if (!userSnap.exists()) {
      throw new Error('User document not found')
    }

    const userData = userSnap.data()
    const employerId = userData.employer_id || auth.currentUser.uid

    // Determine the employer name: prioritize the user object
    const employerName = userData.company_name || postingData.employer_name

    if (!employerName) {
      throw new Error('Employer name is required') // Validate before creating the job posting
    }

    // Create the job posting object
    const jobPostingObject = createJobPostingObject({
      ...postingData,
      employer_id: employerId,
      employer_name: employerName, // Pass the resolved employer name
      company_logo: userData.company_logo || postingData.company_logo // Optional: Use company logo from user profile if available
    })

    console.log('Job Posting Object:', jobPostingObject) // Debugging log

    // Save the job posting to Firestore
    const jobRef = await addDoc(
      collection(db, JOB_POSTINGS_COLLECTION),
      jobPostingObject
    )

    return jobRef
  } catch (error) {
    console.error('Error in createJobPosting:', error)
    throw error
  }
}

// Get job postings for an employer with application counts
export const getEmployerJobPostingsWithApplicants = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, USERS_COLLECTION, userId))
    if (!userDoc.exists()) {
      throw new Error('User document not found')
    }

    const userData = userDoc.data()
    const employerId = userData.employer_id || userId

    const jobsQuery = query(
      collection(db, JOB_POSTINGS_COLLECTION),
      where('employer_id', '==', employerId)
    )

    const jobsSnapshot = await getDocs(jobsQuery)

    const jobsWithApplicantDetails = await Promise.all(
      jobsSnapshot.docs.map(async (jobDoc) => {
        const jobData = jobDoc.data()

        // Query jobApplications collection to get applicant details for this job
        const applicationsQuery = query(
          collection(db, JOB_APPLICATIONS_COLLECTION),
          where('job_posting_id', '==', jobDoc.id)
        )
        const applicationsSnapshot = await getDocs(applicationsQuery)

        // Collect applicant data
        const applicants = applicationsSnapshot.docs.map((appDoc) => ({
          id: appDoc.id,
          ...appDoc.data()
        }))

        return {
          id: jobDoc.id,
          ...jobData,
          applicants,
          applicant_count: applicationsSnapshot.size
        }
      })
    )

    return jobsWithApplicantDetails
  } catch (error) {
    console.error('Error in getEmployerJobPostingsWithApplicants:', error)
    throw error
  }
}

// Get a single job posting
export const getJobPosting = async (jobId) => {
  const jobRef = doc(db, JOB_POSTINGS_COLLECTION, jobId)
  const jobSnap = await getDoc(jobRef)
  if (jobSnap.exists()) {
    return { id: jobSnap.id, ...jobSnap.data() }
  }
  return null
}

// Update a job posting
export const updateJobPosting = async (jobId, updateData) => {
  const jobRef = doc(db, JOB_POSTINGS_COLLECTION, jobId)
  await updateDoc(jobRef, {
    ...updateData,
    updated_at: serverTimestamp()
  })
}

// Delete a job posting
export const deleteJobPosting = async (jobId) => {
  try {
    const jobRef = doc(db, JOB_POSTINGS_COLLECTION, jobId)
    const jobSnap = await getDoc(jobRef)

    if (jobSnap.exists()) {
      const jobData = jobSnap.data()
      await deleteDoc(jobRef)

      if (jobData.employer_id) {
        const employerRef = doc(db, USERS_COLLECTION, jobData.employer_id)
        await updateDoc(employerRef, {
          number_of_open_jobs: increment(-1)
        })
      }
    }
  } catch (error) {
    console.error('Error deleting job posting:', error)
    throw error
  }
}

// Add an applicant to a job posting
export const addApplicantToJobPosting = async (postingId, applicantId) => {
  const postingRef = doc(db, JOB_POSTINGS_COLLECTION, postingId)
  await updateDoc(postingRef, {
    applicant_ids: arrayUnion(applicantId),
    number_of_applicants: increment(1),
    updated_at: serverTimestamp()
  })
}

// Get active job postings for an employer
export const getActiveEmployerJobPostings = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, USERS_COLLECTION, userId))
    if (!userDoc.exists()) {
      throw new Error('User document not found')
    }

    const userData = userDoc.data()
    const employerId = userData.employer_id || userId

    const jobsQuery = query(
      collection(db, JOB_POSTINGS_COLLECTION),
      where('employer_id', '==', employerId),
      where('status', '==', 'active')
    )

    const jobsSnapshot = await getDocs(jobsQuery)

    return jobsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data()
    }))
  } catch (error) {
    console.error('Error in getActiveEmployerJobPostings:', error)
    throw error
  }
}

export default {
  createJobPosting,
  getJobPosting,
  getEmployerJobPostingsWithApplicants,
  updateJobPosting,
  deleteJobPosting,
  addApplicantToJobPosting,
  getActiveEmployerJobPostings
}
