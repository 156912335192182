import React, { useState, useEffect } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import styled from 'styled-components'
import {
  Inbox,
  Search,
  Building,
  ChartArea,
  FileCheck,
  MessageSquare,
  Settings,
  LogOut,
  User,
  Star,
  QrCodeIcon,
  HelpCircle
} from 'lucide-react'
import { auth } from '../../firebase' // Adjust the path if needed
import { useNavigate, Link } from 'react-router-dom'
import { getEmployerProfile } from '../../models/EmployerProfile'

const ADMIN_EMAILS = [
  'olivia@arenatalent.com',
  'adriene@arenatalent.com',
  'parul@arenatalent.com'
]

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 16rem;
  background-color: #f3f4f6;
  border-right: 1px solid #e5e7eb;

  @media (max-width: 768px) {
    width: 3.5rem;
  }
`

const LogoContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;
`

const Logo = styled.img`
  width: 120px;
  height: 100px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
  }
`

const Nav = styled.nav`
  flex: 1;
`

const NavButton = styled(Link)`
  display: flex;
  width: 80%;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e5e7eb;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: auto;
    padding: 0rem;
  }
`

const ExternalLinkButton = styled.a`
  display: flex;
  width: 80%;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;
  &:hover {
    background-color: #e5e7eb;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    justify-content: center;
    width: auto;
    padding: 0rem;
  }
`

const IconWrapper = styled.span`
  margin-right: 0.75rem;

  @media (max-width: 768px) {
    padding: 0.75rem 0rem;
    margin-right: 0rem;
  }
`

const ButtonText = styled.span`
  @media (max-width: 768px) {
    display: none;
  }
`

const BottomSection = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  color: #dc2626;
  background: none;
  border: none;
  padding: 0rem 1rem;
  width: 80%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: #fee2e2;
    color: #b91c1c;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
`

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
`

const PopupContent = styled.div`
  background-color: white;
  padding: 2rem 1rem;
  padding-top: 0rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`

const QRCloseButton = styled.button`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  background-color: white;
  border-radius: 8px;
  border: none;
  color: #718096;
  cursor: pointer;

  &:hover {
    background-color: #f3f4f6;
  }
`

const CloseQRNavSection = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 0.5rem;
`

const HelpDropDown = styled.div`
  position: relative;
`

const HelpDropDownContent = styled.div`
  position: absolute;
  background-color: #f1f1f1;
  width: 80%;
  padding: 0.5rem 1rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;

  @media (max-width: 768px) {
    width: 200px;
    left: 100%;
    top: 0;
  }
`

const DropDownLink = styled.a`
  padding: 5px 0px;
  display: block;
  color: #4a90e2;
  font-weight: 600;
  font-size: 0.75em;
  text-decoration: none;
  transition: color 0.2s;
`

const LinkText = styled.span`
  &:hover {
    color: #63b3ed;
    text-decoration: underline;
  }
`

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const Tooltip = styled.div`
  position: absolute;
  left: calc(100% + 12px);
  top: 50%;
  transform: translateY(-50%);
  padding: 8px 16px;
  background-color: #18181b;
  color: white;
  border-radius: 8px;
  font-size: 0.875rem;
  white-space: nowrap;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: ${(props) => (props.show ? 'block' : 'none')};

  @media (max-width: 768px) {
    left: auto;
    right: calc(100% + 12px);
    font-size: 0.75rem;
  }
`

const DisabledNavButton = styled(NavButton)`
  color: #a1a1aa;

  &:hover {
    background-color: transparent;
    cursor: default;
  }
`
const NavButton2 = styled.a`
  display: flex;
  width: 80%;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
  color: inherit;
  text-decoration: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: #e5e7eb;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
`

function EmployerNav() {
  //   const [isQRPopupOpen, setIsQRPopupOpen] = useState(false)
  const [isHelpDropDownOpen, setIsHelpDropDownOpen] = useState(false)
  const [intakeCompleted, setIntakeCompleted] = useState(false)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)
  const navigate = useNavigate()
  const [isAdmin, setIsAdmin] = useState(false)

  const [showTooltipProfile, setShowTooltipProfile] = useState(false)
  const [showTooltipCandidates, setShowTooltipCandidates] = useState(false)
  const [showTooltipATS, setShowTooltipATS] = useState(false)
  const [showTooltipPipelines, setShowTooltipPipelines] = useState(false)
  const [showTooltipMessaging, setShowTooltipMessaging] = useState(false)

  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    const checkIntakeStatus = async () => {
      try {
        const user = auth.currentUser
        if (!user) return

        const profile = await getEmployerProfile(user.uid)
        setIntakeCompleted(profile?.intake_completed || false)
      } catch (error) {
        console.error('Error checking intake status:', error)
      }
    }

    checkIntakeStatus()
  }, [])
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && ADMIN_EMAILS.includes(user.email?.toLowerCase())) {
        setIsAdmin(true)
      } else {
        setIsAdmin(false)
      }
    })

    return () => unsubscribe()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  //   const handleQR = () => {
  //     setIsQRPopupOpen((prev) => !prev)
  //   }

  const handleHelpDropDown = () => {
    setIsHelpDropDownOpen((prev) => !prev)
  }

  const handleLogout = async () => {
    try {
      await auth.signOut()
      navigate('/login')
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  return (
    <NavContainer>
      <LogoContainer>
        <Logo src="/images/logo-black.png" alt="Company Logo" />
      </LogoContainer>
      <Nav>
        <NavButton to="/employer-dashboard">
          <IconWrapper>
            <Inbox size={20} />
          </IconWrapper>
          <ButtonText>Dashboard</ButtonText>
        </NavButton>

        {intakeCompleted ? (
          <NavButton to="/employer-profile" id="nav-profile-button">
            <IconWrapper>
              <User size={20} />
            </IconWrapper>
            <ButtonText>Profile</ButtonText>
          </NavButton>
        ) : (
          <TooltipWrapper
            onMouseEnter={() => setShowTooltipProfile(true)}
            onMouseLeave={() => setShowTooltipProfile(false)}
          >
            <DisabledNavButton as="div">
              <IconWrapper>
                <User size={20} />
              </IconWrapper>
              <ButtonText>Profile</ButtonText>
            </DisabledNavButton>
            <Tooltip show={showTooltipProfile}>
              Complete the intake form to access your profile
            </Tooltip>
          </TooltipWrapper>
        )}

        {intakeCompleted ? (
          <NavButton to="/candidate-sourcing" id="nav-sourcing-button">
            <IconWrapper>
              <Search size={20} />
            </IconWrapper>
            <ButtonText>Search Candidates</ButtonText>
          </NavButton>
        ) : (
          <TooltipWrapper
            onMouseEnter={() => setShowTooltipCandidates(true)}
            onMouseLeave={() => setShowTooltipCandidates(false)}
          >
            <DisabledNavButton as="div">
              <IconWrapper>
                <Search size={20} />
              </IconWrapper>
              <ButtonText>Search Candidates</ButtonText>
            </DisabledNavButton>
            <Tooltip show={showTooltipCandidates}>
              Complete the intake form to search candidates
            </Tooltip>
          </TooltipWrapper>
        )}

        {intakeCompleted ? (
          <NavButton to="/job-postings" id="nav-jobs-button">
            <IconWrapper>
              <FileCheck size={20} />
            </IconWrapper>
            <ButtonText>Jobs</ButtonText>
          </NavButton>
        ) : (
          <TooltipWrapper
            onMouseEnter={() => setShowTooltipATS(true)}
            onMouseLeave={() => setShowTooltipATS(false)}
          >
            <DisabledNavButton as="div">
              <IconWrapper>
                <FileCheck size={20} />
              </IconWrapper>
              <ButtonText>Jobs</ButtonText>
            </DisabledNavButton>
            <Tooltip show={showTooltipATS}>
              Complete the intake form to access the ATS
            </Tooltip>
          </TooltipWrapper>
        )}

        {intakeCompleted ? (
          <NavButton to="/pipelines" id="nav-pipelines-button">
            <IconWrapper>
              <ChartArea size={20} />
            </IconWrapper>
            <ButtonText>Pipelines</ButtonText>
          </NavButton>
        ) : (
          <TooltipWrapper
            onMouseEnter={() => setShowTooltipPipelines(true)}
            onMouseLeave={() => setShowTooltipPipelines(false)}
          >
            <DisabledNavButton as="div">
              <IconWrapper>
                <ChartArea size={20} />
              </IconWrapper>
              <ButtonText>Pipelines</ButtonText>
            </DisabledNavButton>
            <Tooltip show={showTooltipPipelines}>
              Complete the intake form to access pipelines
            </Tooltip>
          </TooltipWrapper>
        )}

        {intakeCompleted ? (
          <NavButton to="/messaging" id="nav-messaging-button">
            <IconWrapper>
              <MessageSquare size={20} />
            </IconWrapper>
            <ButtonText>Messaging</ButtonText>
          </NavButton>
        ) : (
          <TooltipWrapper
            onMouseEnter={() => setShowTooltipMessaging(true)}
            onMouseLeave={() => setShowTooltipMessaging(false)}
          >
            <DisabledNavButton as="div">
              <IconWrapper>
                <MessageSquare size={20} />
              </IconWrapper>
              <ButtonText>Messaging</ButtonText>
            </DisabledNavButton>
            <Tooltip show={showTooltipMessaging}>
              Complete the intake form to access messaging
            </Tooltip>
          </TooltipWrapper>
        )}
      </Nav>
      <BottomSection>
        <ExternalLinkButton
          href="https://meetings.hubspot.com/parul-khosla/upgrade?uuid=69174701-3fd7-4757-8185-e31ba2179763"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconWrapper>
            <Star size={20} />
          </IconWrapper>{' '}
          <ButtonText>Upgrade</ButtonText>
        </ExternalLinkButton>
        <HelpDropDown>
          <NavButton id='help-button' onClick={handleHelpDropDown}>
            <IconWrapper>
              <HelpCircle size={20} />
            </IconWrapper>
            <ButtonText>Need Help?</ButtonText>
          </NavButton>
          {isHelpDropDownOpen && (
            <HelpDropDownContent>
              <DropDownLink
                href="https://www.notion.so/arenatalent/Arena-Quickstart-for-Employers-10be576b478b80bd9cccf2f67671881a"
                target="_blank"
                rel="noopener noreferrer"
              >
                📘 <LinkText>Help & quick start guide</LinkText>
              </DropDownLink>
              <DropDownLink href="mailto:support@arenatalent.com">
                📩 <LinkText>Email us</LinkText>
              </DropDownLink>
            </HelpDropDownContent>
          )}
        </HelpDropDown>

        {isAdmin && (
          <NavButton to="/admin" style={{ color: '#8b5cf6' }}>
            <IconWrapper>
              <Settings size={20} />
            </IconWrapper>
            <ButtonText>Admin Dashboard</ButtonText>
          </NavButton>
        )}
        <LogoutButton onClick={handleLogout}>
          <IconWrapper>
            <LogOut size={20} />
          </IconWrapper>
          <ButtonText>Logout</ButtonText>
        </LogoutButton>
      </BottomSection>
    </NavContainer>
  )
}

export default EmployerNav
