import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { doc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f3f4f6;
`

const Card = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
`

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
`

const Text = styled.p`
  color: #4a5568;
  font-size: 1rem;
`

export default function CheckoutSuccess() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const updateUserProfile = async () => {
      try {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
          if (user) {
            const userId = user.uid
            const urlParams = new URLSearchParams(window.location.search)

            // Check if this is a cancellation/downgrade/unsubscribe
            const isDowngrade = urlParams.get('downgrade') === 'true'
            const isUnsubscribe = urlParams.get('unsubscribe') === 'true'

            if (isDowngrade || isUnsubscribe) {
              // Handle downgrade/unsubscribe to free plan
              await updateDoc(doc(db, 'users', userId), {
                plan_selected: true
              })

              await updateDoc(doc(db, `users/${userId}/profiles/jobseeker`), {
                plan_selected: true,
                plan: 'free', // Set plan to free for both downgrade and unsubscribe
                cancelDate: new Date().toISOString(),
                previousPlan: 'paid',
                cancellationType: isDowngrade ? 'downgrade' : 'unsubscribe',
                cancellationReason: urlParams.get('reason') || 'Not specified',
                stripeCustomerId: null, // Clear stripe customer ID
                stripeSessionId: null, // Clear stripe session ID
                subscriptionEndDate: new Date().toISOString()
              })

              console.log(
                `Plan ${
                  isDowngrade ? 'downgraded' : 'unsubscribed'
                } successfully!`
              )
              navigate('/jobseeker-dashboard')
            } else {
              // Handle new subscription
              const clientReferenceId = urlParams.get('client_reference_id')

              // Track Rewardful conversion if referral exists
              if (clientReferenceId && window.rewardful) {
                window.rewardful('convert', {
                  reference: clientReferenceId,
                  customer: userId
                })
              }

              // Get stripe session ID if available
              const sessionId = urlParams.get('session_id')

              // Update the user's plan details in Firestore
              await updateDoc(doc(db, 'users', userId), {
                plan_selected: true
              })

              await updateDoc(doc(db, `users/${userId}/profiles/jobseeker`), {
                plan_selected: true,
                plan: 'paid',
                plan_expire: Date.now() + 90 * 24 * 60 * 60 * 1000, // 90 days from now
                subscriptionStartDate: new Date().toISOString(),
                subscriptionType: urlParams.get('type') || '3month',
                stripeCustomerId: urlParams.get('customer') || null,
                stripeSessionId: sessionId || null,
                cancelDate: null, // Clear any previous cancel date
                previousPlan: null, // Clear previous plan info
                cancellationType: null, // Clear cancellation type
                cancellationReason: null // Clear cancellation reason
              })

              console.log('Plan updated successfully!')
              navigate('/jobseeker-dashboard')
            }
          } else {
            console.error('No authenticated user found')
          }
        })
        return () => unsubscribe()
      } catch (error) {
        console.error('Error updating profile:', error)
      }
    }

    updateUserProfile()
  }, [navigate, location])

  // Determine message based on URL params
  const urlParams = new URLSearchParams(window.location.search)
  const isDowngrade = urlParams.get('downgrade') === 'true'
  const isUnsubscribe = urlParams.get('unsubscribe') === 'true'

  return (
    <Container>
      <Card>
        <Title>
          {isDowngrade
            ? 'Processing your plan change...'
            : isUnsubscribe
            ? 'Processing your unsubscription...'
            : 'Processing your purchase...'}
        </Title>
        <Text>
          {isDowngrade || isUnsubscribe
            ? 'Please wait while we update your account to the free plan.'
            : 'Please wait while we set up your Pro account.'}
        </Text>
      </Card>
    </Container>
  )
}
